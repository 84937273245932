import React from 'react'
import { sortBy } from "lodash";
import { H3, P} from "@modul-connect/shared/components/atoms/text";
import View from '@modul-connect/shared/components/atoms/view';

export const ProductsDisplay = ({  
  style,
  vehicle
}) => {

  let products_sorted = vehicle.products ? vehicle.products.filter(x => {
    return x.productId
  }) : []

  sortBy(products_sorted, 'productId')

  let products_undefined = vehicle.products ? vehicle.products.filter(x => {
      return !x.productId
  }) : []

  return (
    <View extend={ style }>
      <View>
        <H3>Products ({ vehicle.products.length })</H3>
        { products_sorted.map((p, index) => {
            return (
              <P key={ p.productId + "_" + index }>{ p.productId } { p.productName }</P>
              )
        })}
      </View>

      { products_undefined && products_undefined.length > 0 && (
        <P strong>Undefined products: {products_undefined.length}</P>
      )}
    </View>
  )
}