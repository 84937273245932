import React from "react";
import View from "@modul-connect/shared/components/atoms/view";
import { P } from "@modul-connect/shared/components/atoms/text";
import Button from "@modul-connect/shared/components/atoms/button";
import theme from "@modul-connect/shared/theme";
import { firmware_products, hasAvailableFirmware } from "../../../../../utils/firmwares";


export const FirmwareDisplay = ({ 
  firmware_update_in_progress, 
  firmware,
  firmwares,
  onUpgradeClick,
  noUpgradeBtn
}) => {
  const upgradeStateUnknown = firmware_update_in_progress === null
  return (
    <View extend={ style }>
      <P key={ firmware.name }>{ 'Firmware ' + firmware.name.toUpperCase() }: { firmware.version }</P>
      {
        !noUpgradeBtn &&
        <Button
          extend={firmware_update_in_progress === true && {color: 'lightgrey'}}
          onClick={ onUpgradeClick }
          noBg
          small
          color={ theme.colors.primary }
          disabled={ !firmwares || upgradeStateUnknown || !hasAvailableFirmware(firmwares, firmware_products.mainbox, firmware.name) }
        >
          {upgradeStateUnknown || firmware_update_in_progress === false ? 
            'Upgrade' : 'Currently upgrading'}
        </Button>
      }
  </View>
  )
}

const style = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignContent: 'center',
  alignItems: 'center'
}