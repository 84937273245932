import React from "react";
import Widget from "@modul-connect/shared/components/atoms/widget";
import KPI from "@modul-connect/shared/components/atoms/kpi";
import theme from "@modul-connect/shared/theme"
import { BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

const DataRegisteredPerWeek = ({ dataRegistered_week, title }) => {
  return (
    <Widget
      show={ dataRegistered_week ? true : false }
      title={title}
      children={
        [
          {
            name: 'left',
            body: <KPI value={ dataRegistered_week ? dataRegistered_week[dataRegistered_week.length - 1].count : null } description={ 'this week'}  />
          },
          {
            name: 'right',
            body:
              <ResponsiveContainer width="99%" >
                <BarChart data={dataRegistered_week ? dataRegistered_week : []} margin={{ top: 20 }}>
                  <CartesianGrid stroke={ theme.colors.lightGrey } strokeDasharray="3" />
                  <XAxis dataKey="name"  tick={{fontSize: 14}} />
                  <YAxis tick={{fontSize: 14}} />
                  <Tooltip
                    labelFormatter={function(value) {
                      return `Week ${value}`;
                    }}
                  />
                  <Bar dataKey="count" name="Registered" fill={ theme.colors.primary } />
                </BarChart>
              </ResponsiveContainer>
          }
        ]
      }
    />
  )
}

export default DataRegisteredPerWeek
