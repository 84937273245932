import { combineReducers } from 'redux'
import AsyncStorage from '@react-native-community/async-storage';
import { persistReducer } from 'redux-persist'

import {
    saving,
    loading,
    loadingWorkshop,
    savingLoadingCustomer,
    savingCustomerFleet,
    savingTemplate,
    loadingConnectionStatus
} from './app'
import {
  customers,
  groupedCustomers,
  customer,
  user
  // userAdded,
  // userAdded,
  // customerSaving
} from './customers'
import {
  templates,
  template,
} from './templates'
import {
    workshops,
    workshop
} from './workshops'
import {
    is_logged_in,
    role
} from './user'
import {
    users,
    users_registered,
} from './users'
import {
    boxes_registered,
    vehicle,
    disconnected_boxes,
    disconnected_box_history,
    vehicles,
} from './vehicles'
import {
    themes
} from './themes'
import {
    firmware_update_progress,
    firmware_upgrade_request,
    debug_status,
    firmwares_report,
    firmwares,
    device_twin
} from './firmwares'

const rootPersistConfig = {
  key: 'root',
  storage: AsyncStorage,
  debug: true,
  whitelist: [
    'role'
  ]
}

const reducers = combineReducers({
    boxes_registered,
    is_logged_in,
    themes,
    vehicle,
    vehicles,
    users,
    users_registered,
    templates,
    template,
    customers,
    groupedCustomers,
    customer,
    user,
    saving,
    loading,
    loadingWorkshop,
    savingLoadingCustomer,
    savingCustomerFleet,
    savingTemplate,
    firmwares_report,
    firmwares,
    firmware_update_progress,
    firmware_upgrade_request,
    debug_status,
    role,
    device_twin,
    workshops,
    workshop,
    disconnected_boxes,
    disconnected_box_history,
    loadingConnectionStatus
})

export default persistReducer(rootPersistConfig, reducers)
