import { DateTime } from "luxon";

export const workshops = (state = {data: [], total: 0}, action) => {
  switch (action.type) {
    case 'WORKSHOPS_FETCHED':
      return {
        data: action.data,
        total: action.total
      }
    default:
      return state;
  }
}

export const workshop = (state = {}, action) => {
  switch (action.type) {
    case 'WORKSHOP_FETCHED':
      return {
        ...state,
        ...action.data
      }
    case 'FETCHING_WORKSHOP_FAILED':
      return {
        ...state,
        workshopId: action.data.workshopId
      }
    case 'WORKSHOP_USERS_FETCHED':
      return {
        ...state,
        users: action.data.users,
        users_workshopId: action.data.workshopId,
        numberOfUsers: action.data.number
      }
    case 'FETCHING_WORKSHOP_USERS_FAILED':
      return {
        ...state,
        users_workshopId: action.data.workshopId,
      }
    case 'FLUSH_WORKSHOP':
      return {}
    default:
      return state
  }
}