import Page from '@modul-connect/shared/components/atoms/page';
import { P } from '@modul-connect/shared/components/atoms/text';
import View from '@modul-connect/shared/components/atoms/view';
import TableWidget from '@modul-connect/shared/components/molecules/tableWidget';
import theme from '@modul-connect/shared/theme';
import React from 'react'
import { workshopColumns } from '../columns/table_columns';

export const WorkshopsSubpage = ({ 
  hide, 
  saving, 
  workshop, 
  handleClick, 
  fetchWorkshop,
  setToRemove,
  setShowRemove,
  setShowRenameWorkshop,
  setShowAddWorkshop,
  themes,
  workshopId,
  loading
}) => {
  const isSaving = saving?.addWorkshop === 'saving' || saving?.removeWorkshop === 'saving' || saving?.renameWorkshop === 'saving'
  const workshops_prepared = workshop?.tree?.children?.map(x => ({ id: x.uuid, name: x.name })) ?? []

  return (
    <View style={{ visibility: hide ? 'hidden' : 'visible' }}>
      <Page title='Workshops' linkTitle='Add workshop' onClickLink={() => setShowAddWorkshop(true)}>
        {
          isSaving && <P>Saving ...</P>
        }

        {saving?.addWorkshop === 'failed' && <P large style={{ color: theme.colors.red }}>Adding workshop failed. (Server error)</P>}
        {saving?.removeWorkshop === 'failed' && <P large style={{ color: theme.colors.red }}>Removing workshop failed. (Server error)</P>}
        {saving?.renameWorkshop === 'failed' && <P large style={{ color: theme.colors.red }}>Renaming workshop failed. (Server error)</P>}

        <TableWidget
          loadingStatus={ loading?.fetchWorkshop }
          data={workshops_prepared}
          totalItems={workshop?.tree?.totalChildren ?? 0}
          columns={workshopColumns(themes)}
          searchStr=''
          themes={themes}
          onClickRow={handleClick}
          onRemove={(_, id) => { setToRemove('workshop'); setShowRemove(id) }}
          onEdit={(_, id) => setShowRenameWorkshop(id)}
          removableIds={workshop?.tree?.children?.map(x => x.uuid) ?? []}
          editableIds={workshop?.tree?.children?.map(x => x.uuid) ?? []}
          onFetchData={(options) => {
            if (!isSaving){
              fetchWorkshop(workshopId, options)     
          }}}
        />
      </Page>
    </View>
  )
}