import { sec } from '@modul-connect/shared/auth0/auth0Helper';
import { call, takeEvery } from 'redux-saga/effects';
import { fetchUserRole } from '../../utils/requests';

export function* userSagas() {
    yield takeEvery('LOGGING_OUT', logOut)
    yield takeEvery('LOGGED_IN', fetchUserRole)
}

export function* logOut() {
  
  function timeout(delay) {
    return new Promise( res => setTimeout(res, delay) );
}
  yield timeout(5000)
  yield sec.logOut({returnTo : window.location.pathname.length > 3 
    ? window.location.href.replace(window.location.pathname, '')
    : window.location.href})
}