import moment from "moment"

export const boxes_registered = (state = null, action) => {
    switch (action.type) {
      case 'BOXES_REGISTERED_FETCHED':
        return action.data
      default:
        return state;
    }
}

export const vehicles = (state = [], action) => {
  switch (action.type) {
    case 'VEHICLES_FETCHED':
      return {
        data: action.data,
        total: action.total
      }
    default:
      return state;
  }
}

export const vehicle = (state = {}, action) => {
  switch (action.type) {
    case 'VEHICLE_FETCHED':
      console.warn(action.data)
      let vehicle = {
        ...state,
        ...action.data
      }
      vehicle.permission = moment(vehicle.allowedUntil) > moment.utc()

      return vehicle
    case 'VEHICLE_CONFIG_FETCHED':
      return {
        ...state,
        config: action.data
      }
    case 'VEHICLE_HISTORY_FETCHED':
      return {
        ...state,
        history: {
          data: action.data,
          total: action.total
        }
      }

    case 'VEHICLE_TEXT_RECORDS_FETCHED':
      return {
        ...state,
        records: {
          data: action.data,
          total: action.total
        }
      }
    case 'ALLOW_USER_FIRMWARE_UPGRADE':
      return {
        ...state,
        permission: !action.forbid,
        allowedUntil: moment.unix(action.untilDate),
      }
    case 'UPGRADE_PERMISSION_CHANGED':
      return {
        ...state,
        permission: action.data,
        allowedUntil: moment.unix(action.allowUntil)
      }
      case 'TEMPLATE_SAVED':
        return {
          ...state,
          appliedTemplate: action.data.templateName,
          templateBoxId: 'vehicle/' + action.data.boxId
        }
    default:
      return state;
  }
}

export const disconnected_boxes = (state = [], action) => {
  switch (action.type){
    case 'DISCONNECTED_BOXES_FETCHED':
      return {
        ...state,
        boxes: action.data
      }
    default: 
      return state
  }
}

export const disconnected_box_history = (state = [], action) => {
  switch (action.type){
    case 'DISCONNECTED_STATUS_HISTORY_FETCHED':
      return {
        ...state,
        history: action.data
      }
      default:
        return state
  }
}