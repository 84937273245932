export const event_history_columns = (themes) => [
  {
      id: 'timestamp',
      label: 'Time stamp',
      width: themes.device != 'mobile' && 120,
      hide: []
  },
  {
      id: 'name',
      label: 'Event',
      width: themes.device != 'mobile' && 120,
      hide: []
  },
  {
      id: 'info',
      label: 'Info',
      width: themes.device != 'mobile' && 120,
      hide: []
  }
]