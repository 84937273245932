export const logged_in = () => (
  {
    type: 'LOGGED_IN',
    logged_in: true
  }
)

export const logged_out = () => (
  {
    type: 'LOGGED_OUT',
    logged_in: false
  }
)