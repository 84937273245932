export const fetchWorkshops = options => (
  {
    type: 'FETCH_WORKSHOPS',
    rowsPerPage: options.rowsPerPage || 10,
    page: options.currentpage || 1,
    sort: options.sort,
    searchStr: options.searchStr
  }
)

export const fetchWorkshop = (id, options) => (
  {
    type: 'FETCH_WORKSHOP',
    id,
    rowsPerPage: options?.rowsPerPage || 10,
    page: options?.currentpage || 1,
    sort: options?.sort,
    searchStr: options?.searchStr
  }
)

export const addWorkshop = (name, parentId) => (
  {
    type: 'ADD_WORKSHOP',
    name,
    parentId
  }
)

export const removeWorkshop = id => (
  {
    type: 'REMOVE_WORKSHOP',
    id
  }
)

export const renameWorkshop = (id, newName) => (
  {
    type: 'RENAME_WORKSHOP',
    id,
    newName
  }
)

export const flushWorkshop = () => (
  {
    type: 'FLUSH_WORKSHOP'
  }
)