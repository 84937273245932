import React from "react"
import View from "@modul-connect/shared/components/atoms/view"
import { P, H3 } from "@modul-connect/shared/components/atoms/text"
import { dateStringToDate } from '@modul-connect/shared/utils/dateTimeUtils.ts'
import { FirmwareDisplay } from "../../firmwares/firmwareDisplay"
import moment from "moment"
import { getFirmwareTypeName } from "../../../../../../utils/firmwares"
import { connect } from "react-redux"
import { WarningIcon } from "@modul-connect/shared/components/atoms/icons/warningIcon"
import { environment as node_env } from '../../../../../../../../env.js'
import BatteryWarningLevel3 from '@mui/icons-material/Battery2Bar';
import BatteryWarningLevel2 from '@mui/icons-material/Battery3Bar';
import BatteryWarningLevel1 from '@mui/icons-material/Battery5Bar';
import BatteryWarningLevel0 from '@mui/icons-material/BatteryFull';


const batteryPercentageWarningLevel = 20

// TODO: update this to what it will be, when we fetch it from the server
export const batteryLevels = {
  below100: 0,
  below75: 1,
  below50: 2,
  below25: 3,
}

const environmentIsPOC = (!node_env || node_env === 'development')

const iconTextStyle = { display: 'flex', flexDirection: 'row', gap: 5, alignItems: "center" }

const RemotesDisplay = ({ 
  firmware_update_progress, 
  title,
  firmwares,
  remotes,
  style,
  firmware_product,
  canUpgrade,
  onUpgradeBtnClick
}) => {

  return (
    <View extend={{ 
      ...style,
      "& p": {
        margin: 0
      }
    }}>
      <H3>{ title + ' (' + (remotes?.length ? remotes.length : 0) + ')' }</H3>
      
      <View style={{ display: 'flex', flexDirection: 'column', gap: 40 }}>
        { 
          remotes?.length ? remotes.map((ds, index) => {
            const batteryLevel = ds?.batteryWarningLevel
            const doesBatteryLevelExist = ds.isActiveSensor  
            let ds_manufactureDate = ds?.manufacturerDate && ds?.manufacturerDate !== "000000000000" ? dateStringToDate(ds.manufacturerDate) : null
            return (
              <View key={ ds?.serialNumber } style={{ display: 'flex', flexDirection: 'column', gap: 15.25 }}>
                <P strong>Serial number: { ds?.serialNumber }</P>
                <P key={ ds?.assemblyNumber }>Assembly number: { ds?.assemblyNumber }</P>
                <P key={ ds?.revision }>Revision: { ds?.revision }</P>
                <P key={ ds?.serialNumber + index }>Manufactured: { ds_manufactureDate ? ds_manufactureDate.format('MMMM Do YYYY, h:mm:ss a') : '-' }</P>
                { ds?.output ?
                  <P key={ ds?.output }>Output: { ds?.output }</P> : null
                }
                { ds?.sensitivity ?
                  <P key={ ds?.sensitivity }>Sensitivity: { ds?.sensitivity }</P> : null
                }
                {
                  ds?.position ?
                  <P key={ ds?.position }>Motion Position: { ds?.position }</P> : null
                }
                {
                  ds?.external1 ?
                  <P key={ ds?.external1 }>Extenal 1: { ds?.external1 }</P> : null
                }
                {
                  ds?.positionExternal1 ?
                  <P key={ ds?.positionExternal1 }>Position external 1: { ds?.positionExternal1 }</P> : null
                }
                {
                  ds?.external1Status ?
                  <P key={ ds?.external1 +  ds?.external1Status }>External 1 status: { ds?.external1Status }</P> : null
                }
                {
                  ds?.external2 ?
                  <P key={ ds?.external2 }>Extenal 2: { ds?.external2 }</P> : null
                }
                {
                  ds?.positionExternal2 ?
                  <P key={ ds?.positionExternal2 }>Position external 2: { ds?.positionExternal2 }</P> : null
                }
                {
                  ds?.external2Status ?
                  <P key={ ds?.external2 + ds?.external2Status }>External 2 status: { ds?.external2Status }</P> : null
                }
                  {
                    ds?.firmwares && ds?.firmwares.map(fw => {
                      return (
                        <FirmwareDisplay
                          key={ fw.name }
                          firmware_update_in_progress={ firmware_update_progress ? (firmware_update_progress?.fw_upgrade_in_progress ?? false) : null }
                          firmwares={ firmwares }
                          firmware={ fw }
                          noUpgradeBtn={ !canUpgrade || fw.name === 'boot' }
                          onUpgradeClick={ () => {
                            onUpgradeBtnClick(firmware_product, getFirmwareTypeName(fw.name) ?? 'none')
                          }}/>  
                      )
                    })
                  }

                  <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5 }}>
                  {doesBatteryLevelExist  ? <P key={ ds?.serialNumber + '_battery' }>Battery Level:</P> : null}
                    {
                      !doesBatteryLevelExist ? null 
                      : batteryLevel === batteryLevels.below25 ? <View style={iconTextStyle}>  <P>{"Below 25 %"}</P> <BatteryWarningLevel3 color="error" /> </View>
                      : batteryLevel === batteryLevels.below50 ? <View style={iconTextStyle}>  <P>{"Below 50 %"}</P> <BatteryWarningLevel2 color="warning" /> </View>
                      : batteryLevel === batteryLevels.below75 ? <View style={iconTextStyle}>  <P>{"Below 75 %"}</P> <BatteryWarningLevel1 />  </View> 
                      : <View style={iconTextStyle}> <P>{"Above 75 %"}</P> <BatteryWarningLevel0 /> </View>
                    }
                  </View> 
                  <P>Registered: { moment(ds?.created).format('MMMM Do YYYY, h:mm:ss a') }</P>
              </View>
            )
          }) : null}
        </View>
    </View>
  )
}

const mapStateToProps = ({
  firmware_update_progress,
  firmwares
}) => ({
  firmware_update_progress,
  firmwares
})

export default connect(mapStateToProps)(RemotesDisplay);
