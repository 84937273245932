import { DateTime } from 'luxon'
import moment from 'moment'

const date_and_time = 'date-and-time'
const date_only = 'date-only'
const time_only = 'time-only'
const month_and_year = 'month-and-year'
const day_and_date = 'day-and-date'

export const dateTimeToString = (
  date: DateTime, 
  format: typeof date_and_time | typeof date_only | typeof time_only | typeof month_and_year | typeof day_and_date = date_and_time
  ) : string => {
  switch(format) {
    case date_and_time:
      return date?.toLocaleString(DateTime.DATETIME_SHORT)
    case date_only:
      return date?.toLocaleString()
    case time_only:
      return date?.toLocaleString(DateTime.TIME_SIMPLE)
    case month_and_year:
      return date.toLocaleString({ month: 'long', year: 'numeric' })
    case day_and_date:
      return date.toLocaleString({ weekday: 'long', day: 'numeric', month: 'long' })
    default:
      return '-'
  }
}

export const dateStringToDate = (str: string) : moment.Moment | undefined => (
  str && str.trim() != ''
  ? moment(new Date(
    Number.parseInt(str.substring(0,4)),
    (Number.parseInt(str.substring(4,6)) - 1),
    Number.parseInt(str.substring(6,8)),
    Number.parseInt(str.substring(8,10)),
    Number.parseInt(str.substring(10,12))))
  : undefined
)

export const dateTimeToEpoch = (dateTime: DateTime, rounding: "ceil" | "floor" = "ceil") => {
  if (rounding === 'floor') return Math.floor(dateTime.toUTC().toSeconds())
  return Math.ceil(dateTime.toUTC().toSeconds())
}