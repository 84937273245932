import React from 'react';
import View from '@modul-connect/shared/components/atoms/view';
import theme from '../../../theme';

const KPI = ({ value, unit, color, description, header }) => {
  return(
    <View extend={ getStyles(color).kpi }>
      <View>
        { header }
      </View>
      <View extend={ getStyles().value }>
        { unit ? value + " " + unit : value }
      </View>
      <View>
        { description } 
      </View>
    </View>
  )
};

const getStyles = (color) => { return {
  kpi: ({ theme: { layout, colors } }) => ({
    flex: 1,
    padding: '4px 8px',
    textAlign: 'center',
    maxWidth: '20%',
    color: color ?? theme.colors.darkGrey,

    '@media only screen and (max-width: 800px)': {
      flex: '1 1 auto',
      borderTop: '1px solid',
      borderBottom: '1px solid',  
      borderColor: theme.colors.midGrey,
    },

    display: 'flex',
    flexDirection: 'column',
    fontSize: 14,
    textAlign: 'center'
  }),
  value: ({ theme: { layout, colors } }) => ({
    fontSize: 32,
    fontWeight: 'normal', 
    paddingLeft: 2
  }),
}}

export default KPI;
