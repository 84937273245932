import React, {Component} from 'react';
import { useFela } from "react-fela";

const Image = ({
  children,
  className,
  mode,
  src,
  innerRef = null,
  as: As = "div",
  extend,
  ...props
}) => {

  const { css } = useFela(props);

    let modes = {
      'fill': 'cover',
      'fit': 'contain'
    };
    let size = modes[mode] || 'contain';

    let defaultStyle = {
      backgroundColor: 'none',
      backgroundImage: `url("${src}")`,
      backgroundSize: size,
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat'
    };

    return (
      <As
        {...props}
        ref={innerRef}
        className={css(defaultStyle, extend) + (className ? " " + className : "")}
      >
        {children}
      </As>
    )

    // <div {...props} style={{...defaults, ...style, ...important}} />
}

export default Image;