import React from 'react'
import { Radio, RadioGroup } from "@mui/material";
import FormControlLabel from '@mui/material/FormControlLabel';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import theme from "@modul-connect/shared/theme"

export const RadioButtonSelector = ({
  onChange,
  options,
  value,
  disabled
}) => {
  return (
    <RadioGroup
      aria-label="groupBy"
      name="groupBy"
      value={value}
      onChange={(event) => onChange(event.target.value)}
      sx={{
        display: 'flex',
        flexDirection: 'row',
      }} >
      {
        Object?.keys(options)?.map(option => {
          return (
            <ThemeProvider key={option} theme={radio_theme} >
              <FormControlLabel
                disabled={disabled}
                key={options[option]}
                value={options[option]}
                control={
                  <Radio color='default' />
                }
                label={options[option]} />
            </ThemeProvider>
          )
        })
      }
    </RadioGroup>
  )
}

const radio_theme = createTheme({
  palette: {
    primary: {
      main: '#3A8643',
    },
  },
  typography: {
    fontSize: 12,
    fontWeight: 200,
    body1: {
      color: theme.colors.grey
    },
  }
})