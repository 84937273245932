
export const saving = (state = { ajaxError: false }, action) => {
  switch (action.type) {
    case 'ADD_USER':
      return {
        ...state,
        addUser: "saving"
      }
    case 'USER_ALREADY_EXISTS':
      return {
        ...state,
        addUser: "conflict"
      }
    case 'INCORRECT_EMAIL':
      return {
        ...state,
        addUser: "incorrect_email"
      }
    case 'ADDING_USER_FAILED':
      return {
        ...state,
        addUser: "failed"
      }
    case 'USER_ADDED':
      return {
        ...state,
        addUser: null
      }
    case 'REMOVE_USER':
      return {
        ...state,
        removeUser: 'saving'
      }
    case 'REMOVING_USER_FAILED':
      return {
        ...state,
        removeUser: 'failed'
      }
    case 'USER_REMOVED':
      return {
        ...state,
        removeUser: null
      }
    case 'UPDATE_USER_PRIVILEGE':
    case 'MOVE_USER':
      return {
        ...state,
        moveUser: 'saving'
      }
    case 'USER_PRIVILEGE_UPDATED':
    case 'USER_MOVED':
      return {
        ...state,
        moveUser: null
      }
    case 'USER_PRIVILEGE_UPDATE_FAILED':
    case 'MOVING_USER_FAILED':
      return {
        ...state,
        moveUser: 'failed'
      }

    case 'ADD_WORKSHOP':
      return {
        ...state,
        addWorkshop: 'saving'
      }
    case 'WORKSHOP_ADDED':
      return {
        ...state,
        addWorkshop: null
      }
    case 'ADDING_WORKSHOP_FAILED':
      return {
        ...state,
        addWorkshop: "failed"
      }
    case 'REMOVE_WORKSHOP':
      return {
        ...state,
        removeWorkshop: 'saving'
      }
    case 'WORKSHOP_REMOVED':
      return {
        ...state,
        removeWorkshop: null
      }
    case 'REMOVING_WORKSHOP_FAILED':
      return {
        ...state,
        removeWorkshop: "failed"
      }
    case 'RENAME_WORKSHOP':
      return {
        ...state,
        renameWorkshop: 'saving'
      }
    case 'WORKSHOP_RENAMED':
      return {
        ...state,
        renameWorkshop: null
      }
    case 'RENAMING_WORKSHOP_FAILED':
      return {
        ...state,
        renameWorkshop: "failed"
      }
    case 'FLUSH_WORKSHOP':
      return {
        ...state,
        addWorkshop: null,
        removeWorkshop: null,
        renameWorkshop: null,
        addUser: null,
        removeUser: null
      }
    case 'AJAX_ERROR':
      return {
        ...state,
        ajaxError: true
      }
    case 'DISMISS_AJAX_ERROR':
      return {
        ...state,
        ajaxError: false
      }
    case 'MODIFY_SERVICES':
      return {
        ...state,
        modifyServices: 'saving'
      }
    case 'MODIFYING_SERVICES_SUCCESSFUL':
    case 'MODIFYING_SERVICES_FAILED':
      return {
        ...state,
        modifyServices: null
      }
    case 'ADD_COMPANY_DETAILS':
      return {
        ...state,
        addCompanyDetails: 'saving'
      }
    case 'COMPANY_DETAILS_ADDED':
    case 'ADDING_COMPANY_DETAILS_FAILED':
      return {
        ...state,
        addCompanyDetails: null
      }
    case 'ADD_CUSTOMER_ADDRESS':
      return {
        ...state,
        savingCompanyAddress: 'saving'
      }
    case 'CUSTOMER_ADDRESS_ADDED':
    case 'ADDING_CUSTOMER_ADDRESS_FAILED':
      return {
        ...state,
        savingCompanyAddress: null
      }
    case 'ADD_BILLING_ADDRESS':
      return {
        ...state,
        savingBillingAddress: 'saving'
      }
    case 'BILLING_ADDRESS_ADDED':
    case 'ADDING_BILLING_ADDRESS_FAILED':
      return {
        ...state,
        savingBillingAddress: null
      }
    default:
      return state;
  }
}

export const savingTemplate = (state = {}, action) => {
  switch(action.type) {
    case 'SAVE_CONFIG_AS_TEMPLATE':
      return {
        ...state,
        status: 'saving',
        vehicleId: action.vehicleId,
        templateName: action.templateName
      }
    case 'TEMPLATE_SAVED':
      return {
        ...state,
        status: null,
        vehicleId: action.data.boxId,
        templateName: action.data.templateName
      }
    case 'SAVING_TEMPLATE_FAILED':
      return {
        ...state,
        status: 'failed',
        vehicleId: action.data.boxId,
        templateName: action.data.templateName
      }
      default: return state
  }
}

export const savingCustomerFleet = (state = {
  removeVehicleFromCustomer: null,
  addVehicleToCustomer: null
}, action) => {
  switch (action.type) {
    case 'REMOVE_VEHICLE_FROM_CUSTOMER':
      return {
        ...state,
        removeVehicleFromCustomer: "saving"
      }
    case 'REMOVING_VEHICLE_FROM_CUSTOMER_FAILED':
      return {
        ...state,
        removeVehicleFromCustomer: "failed"
      }
    case 'VEHICLE_REMOVED_FROM_CUSTOMER':
      return {
        ...state,
        removeVehicleFromCustomer: null
      }
    case 'ADD_VEHICLE_TO_CUSTOMER':
      return {
        ...state,
        addVehicleToCustomer: "saving"
      }
    case 'ADDING_VEHICLE_TO_CUSTOMER_FAILED':
      return {
        ...state,
        addVehicleToCustomer: "failed"
      }
    case 'VEHICLE_ADDED_TO_CUSTOMER':
      return {
        ...state,
        addVehicleToCustomer: null
      }
    default:
      return state
  }
}

export const savingLoadingCustomer = (state = {
  addCustomer: null,
  removeCustomer: null,
  editCustomer: null,
  fetchCustomers: null,
  counter_fetchCustomers: 0,
}, action) => {
  switch (action.type) {
    case 'ADD_CUSTOMER':
      return {
        ...state,
        addCustomer: "saving"
      }
    case 'ADDING_CUSTOMER_FAILED':
      return {
        ...state,
        addCustomer: "failed"
      }
    case 'CUSTOMER_ADDED':
      return {
        ...state,
        addCustomer: null
      }
    case 'REMOVE_CUSTOMER':
      return {
        ...state,
        removeCustomer: "saving"
      }
    case 'REMOVING_CUSTOMER_FAILED':
      return {
        ...state,
        removeCustomer: "failed"
      }
    case 'CUSTOMER_REMOVED':
      return {
        ...state,
        removeCustomer: null
      }
    case 'EDIT_CUSTOMER':
      return {
        ...state,
        editCustomer: "saving"
      }
    case 'EDITING_CUSTOMER_FAILED':
      return {
        ...state,
        editCustomer: "failed"
      }
    case 'CUSTOMER_EDITED':
      return {
        ...state,
        editCustomer: null
      }
    case 'FETCH_CUSTOMERS':
      return {
        ...state,
        fetchCustomers: "loading",

        addCustomer: null,
        editCustomer: null,
        removeCustomer: null
      }
    case 'FETCHING_CUSTOMERS_FAILED':
      return {
        ...state,
        fetchCustomers: "failed",
      }
    case 'CUSTOMERS_FETCHED':
      return {
        ...state,
        fetchCustomers: null,
      }
    default:
      return state
  }
}


export const loading = (state = {
  fetchWorkshops: null,
  counter_fetchWorkshops: 0,
  fetchUsers: null,
  counter_fetchUsers: 0,
  fetchVehicles: null,
  counter_fetchVehicles: 0,
  fetchFirmwaresReport: null,
  counter_fetchFirmwaresReport: 0,
  fetchCustomersByCountry: null,
  fetchDisconnectedBoxes: null
}, action) => {
  switch (action.type) {
    case 'FETCH_CUSTOMERS_BY_REGION':
      return {
        ...state,
        fetchCustomersByCountry: "loading",
      }
    case 'CUSTOMERS_BY_REGION_FETCHED':
      return {
        ...state,
        fetchCustomersByCountry: null,
      }
    case 'FETCHING_CUSTOMERS_BY_REGION_FAILED':
      return {
        ...state,
        fetchCustomersByCountry: "failed",
      }
    case 'FETCH_WORKSHOPS':
      return {
        ...state,
        fetchWorkshops: "loading",
        counter_fetchWorkshops: state.counter_fetchWorkshops + 1
      }
    case 'FETCHING_WORKSHOPS_FAILED':
      return {
        ...state,
        fetchWorkshops: state.counter_fetchWorkshops > 1 ? state.fetchWorkshops : "failed",
        counter_fetchWorkshops: state.counter_fetchWorkshops - 1
      }
    case 'WORKSHOPS_FETCHED':
      return {
        ...state,
        fetchWorkshops: state.counter_fetchWorkshops > 1 ? state.fetchWorkshops : null,
        counter_fetchWorkshops: state.counter_fetchWorkshops - 1
      }
    case 'FETCH_USERS':
    case 'FETCH_CUSTOMER_USERS':
      return {
        ...state,
        fetchUsers: "loading",
        counter_fetchUsers: state.counter_fetchUsers + 1
      }
    case 'FETCHING_USERS_FAILED':
    case 'FETCHING_CUSTOMER_USERS_FAILED':
      return {
        ...state,
        fetchUsers: state.counter_fetchUsers > 1 ? state.fetchUsers : "failed",
        counter_fetchUsers: state.counter_fetchUsers - 1
      }
    case 'USERS_FETCHED':
    case 'CUSTOMER_USERS_FETCHED':
      return {
        ...state,
        fetchUsers: state.counter_fetchUsers > 1 ? state.fetchUsers : null,
        counter_fetchUsers: state.counter_fetchUsers - 1
      }
    case 'FETCH_VEHICLES_BY_FIRMWARE':
    case 'FETCH_VEHICLES':
    case 'FETCH_CUSTOMER_VEHICLES':
    case 'FETCH_TEMPLATE_VEHICLES':
      return {
        ...state,
        fetchVehicles: "loading",
        counter_fetchVehicles: state.counter_fetchVehicles + 1
      }
    case 'FETCHING_VEHICLES_FAILED':
    case 'FETCHING_TEMPLATE_VEHICLES_FAILED':
    case 'FETCHING_CUSTOMER_VEHICLES_FAILED':
      return {
        ...state,
        fetchVehicles: state.counter_fetchVehicles > 1 ? state.fetchVehicles : "failed",
        counter_fetchVehicles: state.counter_fetchVehicles - 1
      }
    case 'VEHICLES_FETCHED':
    case 'CUSTOMER_VEHICLES_FETCHED':
    case 'TEMPLATE_VEHICLES_FETCHED':
      return {
        ...state,
        fetchVehicles: state.counter_fetchVehicles > 1 ? state.fetchVehicles : null,
        counter_fetchVehicles: state.counter_fetchVehicles - 1
      }
    case 'FETCH_FIRMWARES_REPORT':
      return {
        ...state,
        fetchFirmwaresReport: 'loading',
        counter_fetchFirmwaresReport: state.counter_fetchFirmwaresReport + 1
      }
    case 'FETCHING_FIRMWARES_REPORT_FAILED':
      return {
        ...state,
        fetchFirmwaresReport: state.counter_fetchFirmwaresReport > 1 ? state.fetchFirmwaresReport : 'failed',
        counter_fetchFirmwaresReport: state.counter_fetchFirmwaresReport - 1
      }
    case 'FIRMWARES_REPORT_FETCHED':
      return {
        ...state,
        fetchFirmwaresReport: state.counter_fetchFirmwaresReport > 1 ? state.fetchFirmwaresReport : null,
        counter_fetchFirmwaresReport: state.counter_fetchFirmwaresReport - 1
      }
    case 'FETCH_USER':
      const fetchUserWithNew = {...state.fetchUser} ?? {}
      fetchUserWithNew[action.email] = 'loading'
      return {
        ...state,
        fetchUser: fetchUserWithNew
      }
    case 'FETCHED_USER':
      const fetchUserWithout = {...state.fetchUser} ?? {}
      fetchUserWithout[action.email] = null
      return {
        ...state,
        fetchUser: fetchUserWithout
      }
    case 'FETCHING_USER_FAILED':
      const fetchUserWithFailed = {...state.fetchUser} ?? {}
      fetchUserWithFailed[action.email] = 'failed'
      return {
        ...state,
        fetchUser: fetchUserWithFailed
      }
    default:
      return state;
  }
}

// separate reducer to avoid rerender loops
export const loadingWorkshop = (state = {
  fetchWorkshop: null, counter: 0,
  fetchWorkshopUsers: null, counter_fetchWorkshopUsers: 0
}, action) => {
  switch (action.type) {
    case 'FETCH_WORKSHOP':
      return {
        ...state,
        fetchWorkshop: "loading",
        counter: state.counter + 1
      }
    case 'FETCHING_WORKSHOP_FAILED':
      return {
        ...state,
        fetchWorkshop: state.counter > 1 ? state.fetchWorkshop : "failed",
        counter: state.counter - 1
      }
    case 'WORKSHOP_FETCHED':
      return {
        ...state,
        fetchWorkshop: state.counter > 1 ? state.fetchWorkshop : null,
        counter: state.counter - 1
      }
    case 'FETCH_WORKSHOP_USERS':
      return {
        ...state,
        fetchWorkshopUsers: 'loading',
        counter_fetchWorkshopUsers: state.counter_fetchWorkshopUsers + 1
      }
    case 'WORKSHOP_USERS_FETCHED':
      return {
        ...state,
        fetchWorkshopUsers: state.counter_fetchWorkshopUsers > 1 ? state.fetchWorkshopUsers : null,
        counter_fetchWorkshopUsers: state.counter_fetchWorkshopUsers - 1
      }
    case 'FETCHING_WORKSHOP_USERS_FAILED':
      return {
        ...state,
        fetchWorkshopUsers: state.counter_fetchWorkshopUsers > 1 ? state.fetchWorkshopUsers : 'failed',
        counter_fetchWorkshopUsers: state.counter_fetchWorkshopUsers - 1
      }
    case 'FLUSH_WORKSHOP':
      return {
        ...state,
        fetchWorkshopUsers: null,
        counter_fetchWorkshopUsers: 0,
        fetchWorkshop: null,
        counter: 0
      }
    default:
      return state
  }
}

export const loadingConnectionStatus = (state = {fetchDisconnectedBoxesHistory: null}, action) => {
  switch(action.type) {
    case 'FETCH_DISCONNECTED_BOXES':
      return {
        ...state,
        fetchDisconnectedBoxes: 'loading'
      }
    case 'DISCONNECTED_BOXES_FETCHED':
      return {
        ...state,
        fetchDisconnectedBoxes: null
      }
    case 'FETCHING_DISCONNECTED_BOXES_FAILED':
      return {
        ...state,
        fetchDisconnectedBoxes: 'failed'
      }
    case 'FETCH_DISCONNECTED_STATUS_HISTORY':
        return {
          ...state,
          fetchDisconnectedBoxesHistory: 'loading'
        }
    case 'DISCONNECTED_STATUS_HISTORY_FETCHED':
      return {
        ...state,
        fetchDisconnectedBoxesHistory: null
      }
    case 'FETCHING_DISCONNECTED_STATUS_HISTORY_FAILED':
      return {
        ...state,
        fetchDisconnectedBoxesHistory: 'failed'
      }
    default:
      return state
  }
}

