import React from "react";
import Widget from "@modul-connect/shared/components/atoms/widget";
import KPI from "@modul-connect/shared/components/atoms/kpi";
import theme from "@modul-connect/shared/theme"
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

const DataRegisteredTotal = ({ dataRegistered_week, title }) => {

  return (
    <Widget
      show={ dataRegistered_week ? true : false }
      title={title}
      children={
        [
          {
            name: 'left',
            body: <KPI value={ dataRegistered_week ? dataRegistered_week[dataRegistered_week.length - 1].aggregated : null } description={ 'in total'}  />
          },
          {
            name: 'right',
            body:
              <ResponsiveContainer width="99%">
                <LineChart data={dataRegistered_week ? dataRegistered_week : []} margin={{ top: 20 }}>
                  <CartesianGrid stroke={ theme.colors.lightGrey } strokeDasharray="3" />
                  <XAxis dataKey="name" tick={{fontSize: 14}} />
                  <YAxis tick={{fontSize: 14}} />
                  <Tooltip
                    formatter={(value, name, props) => [value, `${name} (${props.payload.data.current_year})`]}
                    labelFormatter={value => `Week ${value}`}
                  />
                  <Line type="monotone" name="Total Registered" dataKey="aggregated" stroke={ theme.colors.primary } strokeWidth={2} activeDot={{r: 6}} />
                </LineChart>
              </ResponsiveContainer>
          }
        ]
      }
    />
  )
}

export default DataRegisteredTotal
