import React from 'react';
import Page from "@modul-connect/shared/components/atoms/page";
import Input from '@modul-connect/shared/components/atoms/input';
import View from '../../atoms/view';

const SearchWidget = ({
  children,
  extend,
  onChange,
  placeholder,
  title,
  linkTitle,
  onClickLink,
  initialValue
}) => {

  return (
    <Page title={ title } linkTitle={ linkTitle } onClickLink={ onClickLink }>
      <View extend={ styles.row }>
        <Input 
          extend={ [extend && extend, styles.input] } 
          type="text" 
          placeholder={ placeholder } 
          defaultValue={ initialValue }
          onChange={event => onChange(event)}/>
        { children }
      </View>
    </Page>
  )
}

const styles = {
  input: {
    width: 250,
    marginTop: 30,
    marginBottom: 15,
    backgroundColor: 'white',
    marginLeft: 25,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignSelf: 'center',
  }
}

export default SearchWidget;