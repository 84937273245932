const vehicle_columns = [
  {
    id: 'vin',
    label: 'Vin',
    minWidth: 80,
    maxWidth: 170,
    hide: ['tablet', 'mobile']
  },
  {
    id: 'licenseNumber',
    label: 'License number',
    minWidth: 80,
    maxWidth: 170,
    hide: ['tablet', 'mobile']
  },
  {
    id: 'serialId',
    label: 'Ser. ID',
    maxWidth: 170,
    minWidth: 100,
    hide: []
  },
  {
    id: 'telematicsUnit',
    label: 'Telematics',
    minWidth: 130,
    hide: ['mobile', 'tablet']
  },
  {
    id: "brand_model",
    label: 'Model',
    minWidth: 100,
    align: 'left',
    format: value => value.toFixed(2),
    hide: []
  },
  {
    id: 'country',
    label: 'Country',
    minWidth: 110,
    hide: ['tablet', 'mobile']
  },
  {
    id: 'workshop',
    label: 'Workshop',
    minWidth: 130,
    align: 'left',
    format: value => value.toLocaleString(),
    hide: ['mobile']
  },
  {
    id: "order_number",
    label: 'Ord. Nr',
    minWidth: 110,
    align: 'left',
    format: value => value.toLocaleString(),
    hide: ['tablet', 'mobile']
  },
  {
    id: "template",
    label: 'Template',
    minWidth: 110,
    align: 'left',
    format: value => value.toLocaleString(),
    hide: ['tablet', 'mobile']
  },
  {
    id: "created_date",
    label: 'Registered',
    minWidth: 130,
    align: 'left',
    format: value => value.toLocaleString(),
    hide: ['mobile']
  },
  {
    id: "connected",
    label: 'Connected',
    align: 'left',
  },
];

export default vehicle_columns;