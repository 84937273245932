import { adalApiFetch } from '@modul-connect/shared/adalConfig/adalConfig';
import {
  put,
  call,
  fork
} from 'redux-saga/effects'
import { getToken } from '@modul-connect/shared/adalConfig/adalConfig';
import { backofficeQueryURL, backofficeCommandURL, techstoreServerUrl } from '@modul-connect/shared/config';
import { sec } from "@modul-connect/shared/auth0/auth0Helper"

let failedFetchTokenCounter = 0

export function* get(url, serverUrl, optionalToken) {
  if (serverUrl) {
    return yield ajax(serverUrl + url, null, optionalToken)
  }
  return yield ajax(backofficeQueryURL() + url)
}

export function* post(url, data, server) {
  let serverUrl

  if(server === 'tech'){
    serverUrl = techstoreServerUrl()
  }
  else if(server === 'query') {
    serverUrl = backofficeQueryURL()
  } else {
    serverUrl = backofficeCommandURL()
  }

  console.log('POST:', data)
  return yield ajax(serverUrl + url, {
    ...data,
    method: 'POST',
  })
}

export function* patch(url, server) {
  let serverUrl

  if(server === 'query') {
    serverUrl = backofficeQueryURL()
  } else {
    serverUrl = backofficeCommandURL()
  }

  return yield ajax(serverUrl + url, {
    method: 'PATCH',
  })
}

function* ajax(url, config, optionalToken) {
  try {
    //let token = getToken()

    const accessToken = yield sec.getAccessTokenSilently()
    const request_config = {
      ...config,
      crossDomain: true,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${optionalToken && optionalToken || accessToken}`
      }
    }

    console.log('Request: ' + url, 'config', config)

    let response =  yield fetch(url, request_config) 
    console.log('AJAX: ', response)

    if(failedFetchTokenCounter) {
      failedFetchTokenCounter = 0
    }

    let data
    switch (response.status) {
      case 200:
      case 201:
      case 202:
        try {
          data = yield call([response, response.json]);
        }
        catch (e) {
          data = undefined
        }
        return {
          status: 'ok',
          data
        }
      case 204:
        // Sentry.withScope((scope) => {
        //   scope.setExtra("request_config", request_config);
        //   Sentry.captureMessage('Request failed (204)', 'warning');
        // })

        return {
          status: 'no_content',
        }
      case 304:
        return {
          status: 'not_modified'
        }
      case 400:
        return {
          status: 'bad_request'
        }
      case 401:
        console.warn(`User not authorized ${url}`)
        yield put({ type: 'LOGGING_OUT' })

        return null
      case 404:
        if(config && config.method === 'POST') {
          yield put({ type: 'AJAX_ERROR' })
        }
        return null
      case 409:
        return {
          status: 'conflict'
        }
      default:
        if(config && config.method === 'POST') {
          yield put({ type: 'AJAX_ERROR' })
        }
        return null
    }
  } catch (error) {
    switch(error.msg) {
      case "Token Renewal Failed":
        if(failedFetchTokenCounter < 2) {
          console.log('Token Renewal Failed; new try to refresh token (#' + failedFetchTokenCounter + ')')
          yield fork(ajax(url, config))
          failedFetchTokenCounter++
        } else {
          yield put({ type: 'LOGGING_OUT' })
          console.log('Token Renewal Failed; logging out')
          failedFetchTokenCounter = 0
        }

        return
      case "login_required":
        console.log('User not authorized, login required.')
        yield put({ type: 'LOGGING_OUT' })
        failedFetchTokenCounter = 0
        return
      default:
        console.warn('Ajax request ', url + ' FAILED', error)
        return
    }
  }
}

export function* upload(url, config, token) {
    const request_config = {
      ...config,
      crossDomain: true,
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }
    console.log('Request: ' + url)

    let response =  yield fetch(url, request_config)

    console.warn('uploadRespnse', response)

    let data
    switch (response.status) {
      case 200:
        data = yield call([response, response.json]);
        return {
          status: 'ok',
          data
        }
      case 201:
      case 202:
        return {
          status: 'ok'
        }
      case 204:
        // Sentry.withScope((scope) => {
        //   scope.setExtra("request_config", request_config);
        //   Sentry.captureMessage('Request failed (204)', 'warning');
        // })

        return {
          status: 'no_content',
        }
      case 400:
        return {
          status: 'bad_request'
        }
      case 401:
        console.warn('User not authorized')
        yield put({ type: 'LOGGING_OUT' })

        return null
      case 404:
        if(config && config.method === 'POST') {
          yield put({ type: 'AJAX_ERROR' })
        }
        return {
          status: 'not_found'
        }
      case 409:
        return {
          status: 'conflict'
        }
      default:
        if(config && config.method === 'POST') {
          yield put({ type: 'AJAX_ERROR' })
        }
        return null
    }

}

export function* postAllowUserFirmwareUpgrade({untilDate, mainboxId, forbid}) {
  const allowUserFirmwareUpgradeCommand = (mainboxId, untilDate) => {
    let command = {
      id:  'vehicle/' + mainboxId,
      allowUntil: untilDate
    }

    return command
  }

  const command = allowUserFirmwareUpgradeCommand(mainboxId, untilDate)

  let result = yield call(post, '/cmd/box/allowuserupgrade', { body: JSON.stringify(command) })

  if (result && result.status) {
    yield put({type:'UPGRADE_PERMISSION_CHANGED', data: !forbid, allowUntil: untilDate})
  }
}