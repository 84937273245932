import React, { useEffect } from "react";
import { Modal } from "@mui/material";
import Fade from "@mui/material/Fade";
import View from "@modul-connect/shared/components/atoms/view";
import { P, H1 } from "@modul-connect/shared/components/atoms/text";
import modal from "@modul-connect/shared/components/atoms/modal";
import { connect } from "react-redux";
import Checkbox from "@mui/material/Checkbox";
import { fetchCustomer } from "../../../../../utils/requests";
import Button from "@modul-connect/shared/components/atoms/button";
import { modifyServices } from "../../../../../state/actions/customers";
import { ServiceType } from "@modul-connect/shared/utils/services"

const ModalAddService = ({
  openModal,
  onClose,
  customer,
  disabled,
  isMobile,
  modifyServices,
}) => {
  const [drivingLogs, setDrivingLogs] = React.useState(null);
  const [drivingLogsWithStartWeight, setDrivingLogsWithStartWeight] = React.useState(null);
  const [weightReports, setWeightReports] = React.useState(null);
  const [batteryReports, setBatteryReports] = React.useState(null);
  const [tollReports, setTollReports] = React.useState(null);
  const [climateService, setClimateService] = React.useState(null);
  const [mapAccess, setMapAccess] = React.useState(null);
  const [assetTracking, setAssetTracking] = React.useState(null);
  const [lpsReports, setLpsReports] = React.useState(null);
  const [weightReportsNoGEO, setWeightReportsNoGEO] = React.useState(null);
  const [mapAccesssNoGEO, setMapAccessNoGEO] = React.useState(null);
  const [maintenanceAccess, setMaintenanceAccess] = React.useState(null);
  const [securitySystemReports, setSecuritySystemReports] = React.useState(null);
  const [CriticalAlerts, setCriticalAlerts] = React.useState(null);
  const [LockReports, setLockReports] = React.useState(null);

  useEffect(() => {
    if (openModal) {
      setDrivingLogs(customer.services?.includes(ServiceType.DrivingLogs));
      setDrivingLogsWithStartWeight(customer.services?.includes(ServiceType.DrivingLogsWithStartWeight));
      setWeightReports(customer.services?.includes(ServiceType.WeightReports));
      setBatteryReports(customer.services?.includes(ServiceType.BatteryWarnings));
      setTollReports(customer.services?.includes(ServiceType.DrivingLogs));
      setClimateService(customer.services?.includes(ServiceType.ClimateReports));
      setMapAccess(customer.services?.includes(ServiceType.mapAccess));
      setAssetTracking(customer.services?.includes(ServiceType.AssetTrackers));
      setLpsReports(customer.services?.includes(ServiceType.LpsService));
      setWeightReportsNoGEO(customer.services?.includes(ServiceType.WeightReportsNoGeo));
      setMapAccessNoGEO(customer.services?.includes(ServiceType.mapAccessNoGeo));
      setMaintenanceAccess(customer.services?.includes(ServiceType.MaintenanceAccess));
      setSecuritySystemReports(customer.services?.includes(ServiceType.SecuritySystemReports));
      setCriticalAlerts(customer.services?.includes(ServiceType.CriticalAlerts));
      setLockReports(customer.services?.includes(ServiceType.LockReports));
    }
  }, [openModal]);

  const onSave = (previousServices) => {
    const changedServices = [];

    const allServices = [
      { value: drivingLogs, name: "Driving Logs" },
      { value: drivingLogsWithStartWeight, name: "Driving Logs including start weight"},
      { value: weightReports, name: "Weight Reports" },
      { value: batteryReports, name: "Battery Warnings" },
      { value: tollReports, name: "Toll Passages" },
      { value: climateService, name: "Climate Reports" },
      { value: mapAccess, name: "Map Access" },
      { value: assetTracking, name: "Asset Tracking" },
      { value: lpsReports, name: "Lps Reports" },
      { value: weightReportsNoGEO, name: "Weight Reports without Geo location" },
      { value: mapAccesssNoGEO, name: "Map Access without Geo location" },
      { value: maintenanceAccess, name: "Maintenance Access" },      
      { value: securitySystemReports, name: "Security System Reports" },      
      { value: CriticalAlerts, name: "Critical Alerts" },      
      { value: LockReports, name: "Lock Reports" },      
    ];

    allServices.forEach((service) => {
      if (
        service.value !== null &&
        ((service.value &&
          !customer.services?.find((s) => s === service.name)) ||
          (!service.value &&
            customer.services?.find((s) => s === service.name)))
      ) {
        changedServices.push(service);
      }
    });
    const data = {
      services: changedServices.map((s) => s.name),
      customerId: customer.customerId,
      previousServices: previousServices,
    };
    modifyServices(data);
    onClose();
  };

  const CheckboxItem = ({ label, checked, onChange }) => {
    return (
      <View
        extend={[
          {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            height: "30px",
            "&:hover": {
              cursor: "pointer",
            },
          },
        ]}
        onClick={() => onChange(!checked)}
      >
        <View style={{}}>
          <Checkbox
            size="small"
            checked={checked}
            onChange={(event) => onChange(event.target.checked)}
            style={{ color: "green" }}
          />
        </View>
        <View style={{}}>
          <P strong={checked}>{label}</P>
        </View>
      </View>
    );
  };

  const previousState = customer.services ?  [...customer.services] : [];
  return (
    <Modal
      open={openModal}
      onClose={(event, reason) => {
        if (reason === "backdropClick") {
          return;
        }
        onClose();
      }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Fade in={openModal}>
        <div>
          <View extend={modal.container}>
            <View extend={modal.header}>
              <H1 text={"Manage services"} />
            </View>
            <View extend={modal.content}>
              <CheckboxItem
                label={"Driving logs"}
                checked={drivingLogs}
                onChange={() => {
                  setDrivingLogs(!drivingLogs);
                  setTollReports(!drivingLogs);
                }}
                isMobile={isMobile}
              />
              <CheckboxItem
                label={"Driving Logs including start weight"}
                checked={drivingLogsWithStartWeight}
                onChange={() => {
                  setDrivingLogsWithStartWeight(!drivingLogsWithStartWeight);
                }}
                isMobile={isMobile}
              />
              <CheckboxItem
                label={"Weight reports"}
                checked={weightReports}
                onChange={() => setWeightReports(!weightReports)}
                isMobile={isMobile}
              />
              <CheckboxItem
                label={"Battery warnings"}
                checked={batteryReports}
                onChange={() => setBatteryReports(!batteryReports)}
                isMobile={isMobile}
              />
              <CheckboxItem
                label={"Climate Reports"}
                checked={climateService}
                onChange={() => setClimateService(!climateService)}
                isMobile={isMobile}
              />
              <CheckboxItem
                label={"Map Access"}
                checked={mapAccess}
                onChange={() => setMapAccess(!mapAccess)}
                isMobile={isMobile}
              />
              <CheckboxItem
                label={"Asset Tracking"}
                checked={assetTracking}
                onChange={() => setAssetTracking(!assetTracking)}
                isMobile={isMobile}
              />
              <CheckboxItem
                label={"Lps Reports"}
                checked={lpsReports}
                onChange={() => setLpsReports(!lpsReports)}
                isMobile={isMobile}
              />
              <CheckboxItem
                label={"Maintenance Access"}
                checked={maintenanceAccess}
                onChange={() => setMaintenanceAccess(!maintenanceAccess)}
                isMobile={isMobile}
              />
              <CheckboxItem
                label={"Security System Reports"}
                checked={securitySystemReports}
                onChange={() => setSecuritySystemReports(!securitySystemReports)}
                isMobile={isMobile}
              />
              <CheckboxItem
                label={"Weight Reports without geo location"}
                checked={weightReportsNoGEO}
                onChange={() => setWeightReportsNoGEO(!weightReportsNoGEO)}
                isMobile={isMobile}
              />
              <CheckboxItem
                label={"Map access without geo location"}
                checked={mapAccesssNoGEO}
                onChange={() => setMapAccessNoGEO(!mapAccesssNoGEO)}
                isMobile={isMobile}
              />
               <CheckboxItem
                label={'Critical alert'}
                checked={CriticalAlerts}
                onChange={() => setCriticalAlerts(!CriticalAlerts)}
                isMobile={isMobile}
              />
               <CheckboxItem
                label={'Lock reports'}
                checked={LockReports}
                onChange={() => setLockReports(!LockReports)}
                isMobile={isMobile}
              />
            </View>
            <View extend={modal.footer}>
              <Button noBg disabled={disabled} cancel onClick={() => onClose()}>
                Cancel
              </Button>
              <Button
                noBg
                disabled={disabled}
                noPadding
                onClick={() => onSave(previousState)}
              >
                Submit
              </Button>
            </View>
          </View>
        </div>
      </Fade>
    </Modal>
  );
};

const mapStateToProps = ({ customer, themes }) => ({
  customer,
  themes,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCustomer: (id) => dispatch(fetchCustomer(id)),
  modifyServices: (data) =>
    dispatch(
      modifyServices(data.customerId, data.services, data.previousServices)
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalAddService);
