export const fetchTemplates = (options) => (
  {
    type: 'FETCH_TEMPLATES',
    rowsPerPage: options.rowsPerPage || 10,
    page: options.currentpage || 1,
    sort: options.sort,
    searchStr: options.searchStr
  }
)

export const fetchTemplate = ({id, boxId, name}) => {
  return(
  {
    type: 'FETCH_TEMPLATE',
    id,
    boxId,
    name
  }
)
}

export const saveConfigAsTemplate = (vehicleId, templateName) => {
  return(
    {
      type: 'SAVE_CONFIG_AS_TEMPLATE',
      vehicleId,
      templateName
    }
  )
}

export const applyTemplateToBox = (appliedTemplate, boxId) => {
  return(
    {
      type: 'APPLIED_TEMPLATE_TO_BOX',
      boxId,
      appliedTemplate,
    }
  )
}


