import React, { PureComponent } from 'react';
import { PieChart, Pie, Sector, Cell, Tooltip, Label, ResponsiveContainer } from 'recharts';
import theme from '../../../theme';
import chroma from "chroma-js";

export default class PaddedPieChart extends PureComponent {
  get data() { return this.props.data ?? [] }
  get title() { return this.props.title ?? "" }
  get onSelect() { return this.props.onSelect ?? ( () => {} ) }
  get onDeselect() { return this.props.onDeselect ?? ( () => {} ) }
  get deselectOn() { return this.props.deselectOn ?? ( () => {} ) }


  constructor() {
    super()
    this.state = { hoverIndex: null, activeIndex: null}
    this.onClick = this.onClick.bind(this);
  }

  onClick (index) {
    const wasSelected = this.state.activeIndex == index
    this.setState({
      activeIndex: wasSelected ? null : index
    });
    if (wasSelected)
      this.onDeselect()
    else
      this.onSelect(index, this.data[index])
  }
  
  componentDidUpdate() {
    if (this.deselectOn) {
      this.setState({ activeIndex: null })
    }
  }
  

  render() {
    return (
      <ResponsiveContainer minWidth={this.props.minWidth ?? 275} minHeight={this.props.minHeight ?? 275} height={this.props.height ?? '100%'} width={this.props.height ?? '100%'}>
        <PieChart onMouseEnter={this.onPieEnter}>
          <Pie
            activeIndex={this.state.activeIndex}
            activeShape={ (props) => this.renderActiveShape(props, this.data) }
            data={ this.data }
            labelLine={ (props) => renderCustomizedLabelLine(props, this.state.activeIndex) }
            label={ (props) => renderCustomizedLabel(
              props, 
              this.onClick, 
              this.state.activeIndex, 
              this.state,
              (index) => { this.setState({ hoverIndex: index })})}
            innerRadius={40}
            outerRadius={65}
            paddingAngle={this.data?.length > 1 ? 6 : 0}
            blendStroke
            dataKey="value"
            onClick={(_, index) => {
              this.onClick(index)
            }}
            isAnimationActive={ false } // to avoid rechart's issue of labels sometimes not being rendered
          >
          <Label value={this.title} offset={0} position="center" />

            {this.data.map((entry, index) => (
              <Cell 
                key={`cell-${index}`} 
                onMouseOver={ () => this.setState({ hoverIndex: index }) }
                onMouseLeave={ () => this.setState({ hoverIndex: null }) }
                fill={ 'rgb(' +
                  chroma.scale([
                    theme.colors.primary, 
                    chroma(theme.colors.primary).brighten(2).desaturate(5)
                  ])
                  .gamma(1.5)
                  (mapRange(index, 0, this.data.length - 1, 0.0, 1.1)).rgb().join(',') +')'
                }/>
            ))}
          </Pie>
          <Tooltip
            formatter={ (value, name, props) => {
              return [value, 'Version ' + name]
            }} />
        </PieChart>
      </ResponsiveContainer>
    );
  }

  renderActiveShape(props, data) {
    const { cx, cy } = props;
    const { innerRadius, outerRadius } = props;
    const { startAngle, endAngle, midAngle } = props;

    let sx = cx
    let sy = cy

    if (data?.length > 1) {
      const RADIAN = Math.PI / 180;
      const sin = Math.sin(-RADIAN * midAngle);
      const cos = Math.cos(-RADIAN * midAngle);
      sx = cx + (outerRadius - 55) * cos;
      sy = cy + (outerRadius - 55) * sin;
    }
  
    return (
      <g>
        <Sector
          cx={sx}
          cy={sy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={props.fill}
        />
 {        <Sector
          cx={sx}
          cy={sy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 2}
          outerRadius={outerRadius + 3}
          fill={ theme.colors.primary }
        />}
      </g>
    );
  };
}

const mapRange = (value, x1, y1, x2, y2) => {
  if (x1 == y1) return x2
 return (value - x1) * (y2 - x2) / (y1 - x1) + x2;
}

const renderCustomizedLabel = (
  { cx, cy, midAngle, startAngle, endAngle, innerRadius, outerRadius, name, index }, 
  onClick, 
  activeIndex, 
  state,
  setHoverIndex) => {
  const pos = getLabelPosition(cx, cy, innerRadius, outerRadius, startAngle, midAngle, endAngle)

  return (
    <text 
      x={ pos.x } 
      y={ pos.y } 
      fill={ index === 0 ? theme.colors.green : theme.colors.primary } 
      textAnchor={ pos.x > cx ? 'start' : 'end' } 
      dominantBaseline="central" 
      fontSize={ 
        13 + 
        ((index === 0) ? 5 : 0) + 
        ((index === activeIndex) ? 4 : 0)}
      fontWeight={ index === 0 ? "900" : "normal" }
      textDecoration={ index === activeIndex || index === state.hoverIndex ? "underline" : null }
      onClick={ () => onClick(index) }
      onMouseOver={() => {setHoverIndex(index) }}
      onMouseLeave={() => {setHoverIndex(null) }}
      >
      { name }
    </text>
  );
};

const getLabelPosition = (cx, cy, innerRadius, outerRadius, startAngle, midAngle, endAngle, delta) => {
  const RADIAN = Math.PI / 180
  const radius = innerRadius + (outerRadius - innerRadius)
  delta = delta ?? getDelta(endAngle - startAngle)

  return { 
    x: cx + (radius + delta) * Math.cos(-midAngle * RADIAN) * 1.1, 
    y: cy + (radius + (delta * delta * 0.8)) * Math.sin(-midAngle * RADIAN)
  }
}

const getDelta = (diffAngle) => {
  return ((360-diffAngle)/30)-1
}

const renderCustomizedLabelLine = (props, activeIndex) => {
  let { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, index } = props
  const delta = getDelta(endAngle - startAngle)
  let path=''
  for(let i = 0; i < delta; i++) {
    if (index == activeIndex) return
    let pos = getLabelPosition(cx, cy, innerRadius, outerRadius, startAngle, midAngle, endAngle, i)

    path += `${pos.x},${pos.y} `
  }
  return (
    <polyline points={path} stroke={ theme.colors.primary } fill="none" />
  );
}
