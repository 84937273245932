export const fetchCustomers = (options) => (
  {
    type: 'FETCH_CUSTOMERS',
    rowsPerPage: options?.rowsPerPage || 10,
    page: options?.currentpage || 1,
    sort: options?.sort,
    searchStr: options?.searchStr,
    countryCode: options?.tag,
  }
)
export const fetchCustomersByRegion = (options) => (
  {
    type: 'FETCH_CUSTOMERS_BY_REGION',
    rowsPerPage: options.rowsPerPage || 10,
    page: options.currentpage || 1,
    sort: options.sort,
    searchStr: options.searchStr
  }
)
export const fetchCustomer = id => (
  {
    type: 'FETCH_CUSTOMER',
    id
  }
)

export const addCustomer = data => (
  {
    type: 'ADD_CUSTOMER',
    region: data?.region,
    customerName: data.customerName,
    customerId: data.customerId
  }
)

export const removeCustomer = customerId => (
  {
    type: 'REMOVE_CUSTOMER',
    customerId: customerId
  }
)

export const editCustomer = data => (
  {
    type: 'EDIT_CUSTOMER',
    region: data?.region,
    customerName: data.customerName,
    customerId: data.customerId
  }
)

export const addUser = data => (
  {
    type: 'ADD_USER',
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    organisation: data.organisation,
    privilege: data.privilege
  }
)

export const moveUser = data => (
  {
    type: 'MOVE_USER',
    email: data.email,
    organisation: data.organisation,
    privilege: data.privilege
  }
)

export const updateUserPrivilege = data => (
  {
    type: 'UPDATE_USER_PRIVILEGE',
    email: data.email,
    organisation: data.organisation,
    privilege: data.privilege
  }
)

export const removeUser = data => (
  {
    type: 'REMOVE_USER',
    email: data.email,
    tag: data.tag
  }
)

export const sendInvite = email => (
  {
    type: 'SEND_INVITE',
    email
  }
)

export const addVehicleToCustomer = (data) => (
  {
    type: 'ADD_VEHICLE_TO_CUSTOMER',
    vehicleId: data.vehicleId,
    tag: data.tag
  }
)

export const removeVehicleFromCustomer = (data) => (
  {
    type: 'REMOVE_VEHICLE_FROM_CUSTOMER',
    vehicleId: data.vehicleId,
    tags: data.tags
  }
)

export const addCompanyDetails = (customerId, companyName, vatNumber, organisationNumber) => (
  {
    type: 'ADD_COMPANY_DETAILS',
    customerId,
    companyName,
    vatNumber,
    organisationNumber
  }
)

export const addCustomerAddress = (customerId, address) => (
  {
    type: 'ADD_CUSTOMER_ADDRESS',
    customerId,
    address
  }
)

export const addBillingAddress = (customerId, address) => (
  {
    type: 'ADD_BILLING_ADDRESS',
    customerId,
    address
  }
)

export const fetchUser = email => (
  {
    type: 'FETCH_USER',
    email
  }
)
  
export const modifyServices = (customerId, services, presviousServices) => (
    {
      type: 'MODIFY_SERVICES',
      services,
      customerId, 
      presviousServices
    }
  )

export const fetchUserRoles = () => (
  {
    type: 'FETCH_USER_ROLES'
  }
)