import React from 'react';
import View from '@modul-connect/shared/components/atoms/view';
import theme from '../../../theme';

const styles = {
  h1: ({ theme: { colors, layout } }) => ({
    fontSize: "20px",
    fontWeight: 300,
    //fontFamily: 'FuturaBook',
    lineHeight: "27px",
    color: "#4B4B4B",
    padding: "0px",
    margin: 0,
  }),
  statusTitle: ({ theme: { colors, layout } }) => ({
    fontSize: 16,
    fontWeight: 400,
    fontFamily: "FuturaLight",
    lineHeight: "19px",
    color: colors.midDarkGrey,
    margin: 0,
    letterSpacing: '0.04em',
    paddingBottom: 10,
  }),
  statusValue: ({ theme: { colors, layout } }) => ({
    fontSize: 18,
    fontWeight: 500,
    fontFamily: "Futura",
    lineHeight: "24px",
    color: "#4D4D4D",
    margin: 0,
    paddingTop: 10,
  }),
  paperTitle: ({ theme: { colors, layout } }) => ({
    fontSize: "24px",
    fontFamily: "FuturaDemi", // TODO
    lineHeight: "24px",
    color: "#4B4B4B",
    padding: "0px",
    margin: 0,
  }),
  reportTitle: ({ theme: { colors, layout } }) => ({
    fontSize: "24px",
    fontWeight: 400,
    fontFamily: "Futura",
    lineHeight: "31.88px",
    color: "#000000",
    letterSpacing: 1,
    paddingLeft: "0px",
  }),
  SubTitle: ({ theme: { colors, layout } }) => ({
    fontSize: "16px",
    fontWeight: 50,
    fontFamily: "Futura",
    lineHeight: "20px",
    color: theme.colors.midDarkGrey,
    letterSpacing: "0.04em",
    paddingTop: "8px",
    paddingBottom: "6px",
    fontStyle: "normal",
  }),
  CardTitle: ({ theme: { colors, layout } }) => ({
    fontFamily: "FuturaBook",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "32px",
    lineHeight: '39px',
    color: theme.colors.black,
  }),
  DatePickerTitle: ({ theme: { colors, layout } }) => ({
    fontSize: "24px",
    fontFamily: "FuturaLight",
    color: colors.midDarkGrey,
  }),
  noBorder: ({ theme: { colors, layout } }) => ({
    borderBottom: "0 !important",
    paddingBottom: "10%",
  }),
  h3: ({ theme: { colors, layout } }) => ({
    fontSize: "14px",
    color: colors.midDarkGrey,
    fontWeight: 400,
    padding: "0px",
  }),
  h4: ({ theme: { colors, layout } }) => ({
    fontSize: "14px",
    color: colors.text,
    fontWeight: 400,
    padding: "0px",
    margin: 0,
  }),
  h5: ({ theme: { colors, layout } }) => ({
    fontSize: "10px",
    textTransform: "uppercase",
    color: colors.grey,
    padding: "0px",
    margin: 0,
  }),
  p: ({ theme: { colors, layout, fontSize } }) => ({
    // fontSize: fontSize ? fontSize :'14px',
  }),
  p_divider: ({ theme: { colors, layout } }) => ({
    fontSize: "14px",
    marginTop: 10,
    lineHeight: 0,
    color: "#E9E9E9",
    textAlign: "left",
  }),
  p_small: ({ theme: { colors, layout } }) => ({
    fontSize: "12px",
  }),
  p_xlarge: ({ theme: { colors, layout } }) => ({
    fontSize: "18px",
  }),
  light_p: ({ theme: { colors, layout } }) => ({
    color: colors.white,
  }),
  highlight_p: ({ theme: { colors, layout } }) => ({
    color: colors.error,
  }),
  link: ({ theme: { colors, layout } }) => ({
    transition: "color 200ms ease-in-out",
    "&:hover": {
      color: colors.primary,
    },
  }),
  titleWrapper: ({ theme: { layout, colors } }) => ({
    display: "flex",
    flexDirection: "row",
    "> div": {
      marginLeft: 4,
      marginTop: 2,
      display: "flex",
      flexDirection: "row",
      color: colors.primary,
      cursor: "pointer",
      "> p": {
        color: colors.primary,
        fontSize: 12,
      },
    },
  }),
  light: ({ theme: { colors, layout } }) => ({
    color: colors.white,
  }),
  a: ({ theme: { colors, layout, color } }) => ({
    fontSize: "14px",
    cursor: "pointer",
  }),
  large_text: {
    fontSize: "16px",
  },
  small_text: {
    fontSize: "11px",
  },
  noPadding: {
    padding: "0 !important",
    margin: "0 !important",
  },
  strong: {
    fontWeight: 800,
  },
};

export const PaperTitle = ({
  text,
  noPadding
}) => (
  <View as="h1" extend={[styles.paperTitle, noPadding && styles.noPadding]}>{text}</View>
)

export const StatusTitle = ({
  text,
}) => (
  <View as='h1' extend={[styles.statusTitle]}>{text}</View>
)
export const StatusValue = ({
  text,
}) => (
  <View as='h1' extend={[styles.statusValue]}>{text}</View>
)

export const H1 = ({
  text,
  noPadding
}) => (
  <View as="h1" extend={[styles.h1, noPadding && styles.noPadding]}>{text}</View>
)

export const ReportTitle = ({
  extend,
  children,
  noPadding
}) => (
  <View extend={[styles.reportTitle, extend, noPadding && styles.noPadding]}>{children}</View>
)

export const SubTitle = ({
  extend,
  children,
  title,
  noBorder,
  noPadding
}) => (
  <View extend={[styles.SubTitle, extend, noBorder && styles.noBorder, noPadding && styles.noPadding]}>
    <View style={{ flex: 1 }}>{title}</View>
    {children}
  </View>
)

export const CardTitle = ({extend, noPadding, children}) => {
  return (
    <View extend={[styles.CardTitle, extend, noPadding && styles.noPadding]}>{children}</View>
  )
}

export const DatePickerTitle = ({
  extend,
  children,
  title1,
  title2,
  noBorder,
  noPadding
}) => (
  <View extend={[styles.DatePickerTitle, extend, noBorder && styles.noBorder, noPadding && styles.noPadding]}>
    <View style={{ flex: 1 }}>{title1}<br/> {title2}</View>
    {children}
  </View>
)

export const H3 = ({
  children,
  noPadding,
  light,
  linkTitle,
  linkIcon,
  onClickLink,
  color,
  extend,
}) => (
  <View as="h3" extend={[
    styles.h3,
    noPadding && styles.noPadding,
    light && styles.light,
    linkTitle && styles.titleWrapper,
    color && { color: color },
    extend
  ]}>
    {children}
    {linkTitle && (
      <View onClick={() => onClickLink()}>
        {linkIcon}
        <P strong noPadding>{linkTitle.toUpperCase()}</P>
      </View>
    )}
  </View>
)

export const H4 = ({
  children,
  extend
}) => (
  <View as="h4" extend={[styles.h4, extend]}>
    {children}
  </View>
)

export const H5 = ({
  noPadding,
  children,
  extend
}) => (
  <View as="h5" extend={[styles.h5, noPadding && styles.noPadding, extend]}>
    {children}
  </View>
)

export const P = ({
  children,
  extend,
  large,
  light,
  strong,
  style,
  link,
  noPadding,
  highlight,
  divider,
  small,
}) => (
  <View as="p" extend={[small && styles.p_small, divider && styles.p_divider, styles.p, light && styles.light_p, large && styles.large_text, link && styles.link, highlight && styles.highlight_p, strong && styles.strong, noPadding && styles.noPadding, style, extend]} children={children} />
)

export const A = ({
  text,
  children,
  large,
  small,
  extend,
  ...props
}) => (
  <View as="a" {...props} extend={[styles.a, extend, large && styles.large_text, small && styles.small_text]}>{text || children}</View>
)

export const Span = ({
  extend,
  children,
  fontFamily,
  ...props
}) => (
  <View as="span" {...props} extend={[extend, fontFamily]} >{children}</View>
)