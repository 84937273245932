import React from "react";
import { createRoot } from 'react-dom/client'
import { createRenderer } from "fela";
import { RendererProvider, ThemeProvider } from "react-fela";
import { auth0Domain, auth0ClientId, auth0_params, auth0_params_backoffice, auth0ClientId_backoffice } from "@modul-connect/shared/config";
import { Auth0Provider } from '@auth0/auth0-react';
import webPreset from "fela-preset-web";
import { Provider } from 'react-redux'
import configureStore from './state/configureStore'
import theme from "@modul-connect/shared/theme";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import baseStyles from "@modul-connect/shared/baseStyles";
import { PersistGate } from 'redux-persist/integration/react'

// import { SwitchesProvider } from '@modul-connect/shared/hooks';
// import { BreakpointsProvider } from '@modul-connect/shared/hooks';

const renderer = createRenderer({
  plugins: [
    ...webPreset
    // other plugins
  ]
})

renderer.renderStatic(baseStyles)


// const store = configureStore()
export const reduxStore = configureStore()
const Root = () => {
  const {
    store,
    persistor
  } = reduxStore

  return (
    <RendererProvider renderer={renderer}>
      <ThemeProvider theme={theme}>
        { /* <BreakpointsProvider> */}
        <Provider store={store}>
          {/* <SwitchesProvider> */}
          <Auth0Provider
            domain={auth0Domain()}
            clientId={auth0ClientId_backoffice()}
            redirectUri={auth0_params_backoffice.redirectUri}
            audience={auth0_params.audience}
          >
            <PersistGate loading={null} persistor={persistor}>
              <App />
            </PersistGate>
            {/* </SwitchesProvider> */}
          </Auth0Provider>
        </Provider>
        {/* </BreakpointsProvider> */}
      </ThemeProvider>
    </RendererProvider>
  )
}
const root = createRoot(document.getElementById("root"))
root.render(<Root/>);



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
