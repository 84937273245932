import React from "react";
import View from "../../atoms/view";

const MainContainer = ({ children, extend, themes, setActive = true }) => {
  const [state, setState] = React.useState(setActive);

  return (
    <View style={{ overflowY: 'scroll', height: '100%', paddingBottom: '150px' }} extend={!state ? styles.sidebar_inactive : [styles.sidebar_active,
        themes.device==="desktop" ? styles.sidebar_desktop :
        (themes.device==="tablet" ? styles.sidebar_tablet :
        (themes.device==="mobile" && styles.sidebar_mobile)), extend]
    }>
      {children}
    </View>
  );
};

const styles = {
  sidebar_active: ({ theme: { colors } }) => ({
    backgroundColor: colors.lightGrey,
    position: 'absolute',
    left: 0,
    // bottom: 0,
    zIndex: 5,
    // boxShadow: '2px 2px 5px 3px rgba(0,0,0,.05)',
    borderRight: '1px solid rgb(240, 240, 240)',
    paddingBottom: '150px !important'
  }),
  sidebar_inactive: {
    display: "none"
  },
  sidebar_desktop: ({ theme: { layout } }) => ({
    top: layout.navBarHeight
  }),
  sidebar_tablet: ({ theme: { layout } }) => ({
    top: layout.navBarHeight_tablet
  }),
  sidebar_mobile: ({ theme: { layout } }) => ({
    top: layout.navBarHeight_mobile
  })
};

export default MainContainer;
