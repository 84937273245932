import React, { useEffect, useState } from "react";
import { connect } from 'react-redux'
import Page from "@modul-connect/shared/components/atoms/page";
import View from "@modul-connect/shared/components/atoms/view";
import ModalAddUser from "./modals/modalAddUser";
import ModalAddVehicle from "./modals/modalAddVehicle";
import ModalAddService from "./modals/modalAddService";
import ModalRemoveItem from "@modul-connect/shared/components/molecules/modalRemoveItem";
import ModalManageCompanyDetails from "./modals/modalManageCompanyDetails";
import { H3, P, A } from "@modul-connect/shared/components/atoms/text";
import {
  fetchCustomer,
  addUser,
  addVehicleToCustomer,
  removeVehicleFromCustomer,
  removeUser,
  addCompanyDetails,
  addCustomerAddress,
  addBillingAddress,
  sendInvite,
} from "../../../../state/actions/customers"
import { fetchCustomerVehicles } from "../../../../state/actions/vehicles"
import { fetchCustomerUsers } from "../../../../state/actions/users"
import { useLoadingTimer } from '@modul-connect/shared/hooks';
import moment from "moment"
import { useNavigate, useParams } from "react-router-dom";
import './customer.css';
import TableWidget from '@modul-connect/shared/components/molecules/tableWidget';
import vehicle_columns from "../../../reports/vehicles/vehicle_columns"
import ModalAddress from "./modals/modalAddress";
import { MdEdit } from "react-icons/md";
import theme from '@modul-connect/shared/theme'
import Checkbox from '@mui/material/Checkbox';
import { ServiceType } from "@modul-connect/shared/utils/services"
import { enableUserRoles } from "../../../../utils/global";

const Customer = ({
  customer,
  customerId,
  fetchCustomer,
  addUser,
  removeUser,
  addVehicleToCustomer,
  removeVehicleFromCustomer,
  fetchCustomerVehicles,
  saving,
  themes,
  fetchCustomerUsers,
  setSelectedCustomerId,
  addCompanyDetails,
  addCustomerAddress,
  addBillingAddress,
  loading,
  savingCustomerFleet,
  sendInvite,
  role,
}) => {
  let navigate = useNavigate()

  const [showUserModal, setShowUserModal] = React.useState(false)
  const [userToRemove, setUserToRemove] = React.useState(null)
  const [vehicleToRemove, setVehicleToRemove] = React.useState(null)
  const [showVehicleModal, setShowVehicleModal] = React.useState(false)
  const [showCompanyModal, setShowCompanyModal] = React.useState(false)
  const [showServiceModal, setShowServiceModal] = React.useState(false)
  const [showAddressModal, setShowAddressModal] = React.useState('')

  const [customersUsers, updateCustomersUsers] = useState([])

  const [showLoading, setShowLoading] = useState(false)
  useLoadingTimer(setShowLoading)

  const customer_vehicles_prepared = customer.vehicles ? customer.vehicles.map(item => {
    return {
      ...item,
      id: item ? item.mainbox_id : null,
      vin: item.vehicle ? item.vehicle.vin : null,
      serialId: item.vehicle ? item.mainbox_id.split('_').pop() : null,
      telematicsUnit: item?.telematics_id ? 'yes' : 'no',
      year: item.vehicle ? item.vehicle.year : null,
      brand_model: item.vehicle?.brandName ?
        item.vehicle.brandName + " " + (item.vehicle.modelName && item.vehicle.modelName !== 'unknown' ? item.vehicle.modelName : '')
        : null,
      order_number: item.vehicle ? item.vehicle.orderNumber : null,
      created_date: moment(item.created).calendar(),
      customerId: item.vehicle ? item.vehicle.customerId : null
    }
  }) : []

  const params = useParams()
  const handleClickUser = (_, id) => {
    navigate(`/manage/${params.option}/${params.id}/${params.subId}/${id}`)
  }

  useEffect(() => {
    if (customer.customerId !== customerId) {
      fetchCustomer(customerId)
    }
  }, [customerId])

  useEffect(() => {
    if (saving && saving.ajaxError) {
      setShowUserModal(false)
      setShowVehicleModal(false)
    }

    if (saving && !saving.addUser) {
      setShowUserModal(false)
    }
  }, [saving])

  Array.prototype.uniqueByEmail = function () {
    var a = this.concat();
    for (var i = 0; i < a.length; ++i) {
      for (var j = i + 1; j < a.length; ++j) {
        if (a[i].email === a[j].email)
          a.splice(j--, 1);
      }
    }

    return a;
  };

  const customer_name = customer ? customer.name : null  

  useEffect(() => {
    updateCustomersUsers(
      customer.users ?
        (customer.added_users?.length ? customer.users.concat(customer.added_users ?? []).uniqueByEmail() : customer.users)
          .map(item => {
            return {
              ...item,
              id: item.email,
              name: item.firstName ? item.lastName ? item.firstName + ' ' + item.lastName : item.firstName : null,
              role: item.role ?? 'No role assigned',
              created_date: moment(item.created).calendar(),
              verified: item.isVerified ? 'verified' : 'not verified',
              invite_sent: false
            }
          }) : [])
  }, [customer]
  )

  const resend_invite = (row) => {
    sendInvite(row.email);
    updateCustomersUsers(c => [...c.map(e => {
      if (e.email === row.email) {
        e.invite_sent = true
      };
      return e
    })
    ])
  }

  const onRemoveUser = (event, row_id) => {
    setUserToRemove(row_id)
  }

  const onRemoveVehicle = (event, row_id) => {
    setVehicleToRemove(row_id)
  }

  const isSavingUserTable = saving?.addUser === 'saving' || saving?.removeUser === 'saving'
  const isSavingVehicleTable = savingCustomerFleet?.addVehicleToCustomer === 'saving' || savingCustomerFleet?.removeVehicleFromCustomer === 'saving'

  if (customerId === customer?.customerId && customer_name) {

    return (
      <View>
        <Page
          linkBack={navigate}
          onClickLinkBack={() => setSelectedCustomerId(null)}
          title={customer_name}>
          {/* </Page> */}

          {/* <Page title={ 'Customer information' } linkTitle={ (customer.company ? 'Change' : 'Add') + ' company details' } onClickLink={ () => setShowCompanyModal(true)}> */}
          <View extend={styles.content}>
            {/* { customer.company && */}
            <View extend={styles.padding_style}>
              <H3
                linkTitle={saving.addCompanyDetails === 'saving' ? null : 'Edit'}
                linkIcon={<MdEdit />}
                onClickLink={() => setShowCompanyModal(true)}
              >
                General information
              </H3>
              {saving.addCompanyDetails === 'saving' ? <P>Saving...</P> : null}
              <P>Customer Account: {customer.company?.companyName || customer_name}</P>
              <P>VAT: {customer.company?.vatNumber}</P>
              <P>Country Code: {customer?.countryCode}</P>
              {/* <P>Organisation nr: {customer.company?.organisationNumber}</P> */}
            </View>
            {/* } */}
            {/* <View title={ 'Address' } linkTitle={ (customer.company?.organisationAddress ? 'Change' : 'Add') + ' company address' } onClickLink={ () => setShowAddressModal('organisationAddress')}> */}
            {/* { customer.company?.organisationAddress && */}
            <View extend={styles.padding_style}>
              <H3
                linkTitle={saving.savingCompanyAddress === 'saving' ? null : customer.company?.organisationAddress ? 'Edit' : 'Add'}
                linkIcon={<MdEdit />}
                onClickLink={() => setShowAddressModal('organisationAddress')}
              >
                Address
              </H3>
              {saving.savingCompanyAddress === 'saving' ? <P>Saving...</P> : null}
              <P>Contact: {customer.company?.organisationAddress?.receiver}</P>
              <P>Address: {customer.company?.organisationAddress?.streetAddress} {customer.company?.organisationAddress?.extraAddress && customer.company.organisationAddress.extraAddress}</P>
              <P>Post Code: {customer.company?.organisationAddress?.postalCode}</P>
              <P>City: {customer.company?.organisationAddress?.city}</P>
              {/* {customer.company?.organisationAddress?.division && <P>Division: {customer.company.organisationAddress.division}</P>} */}
              <P>Country: {customer.company?.organisationAddress?.country}</P>
              <P>Email: {customer.company?.organisationAddress?.contactEmail}</P>
              {/* {customer.company?.organisationAddress?.contactPhone && <P>{customer.company.organisationAddress.contactPhone}</P>} */}
            </View>
            {/* } */}
            {/* </View> */}

            {/* <View title={ 'Billing Address' } linkTitle={ (customer.company?.billingAddress ? 'Change' : 'Add') + ' billing address' } onClickLink={ () => setShowAddressModal('billingAddress')}> */}
            {/* { customer.company?.organisationAddress == customer.company?.billingAddress || !customer.company?.billingAddress ? */}
            {/* <P>Same as customer address</P>
                    : */}
            <View extend={styles.padding_style}>
              <H3
                linkTitle={saving.savingBillingAddress === 'saving' ? null : customer.company?.billingAddress ? 'Edit' : 'Add'}
                linkIcon={<MdEdit />}
                onClickLink={() => setShowAddressModal('billingAddress')}
              >
                Billing address
              </H3>
              {saving.savingBillingAddress === 'saving' ? <P>Saving...</P> : null}
              <P>Invoice Account: {customer.company?.billingAddress?.receiver}</P>
              <P>Address: {customer.company?.billingAddress?.streetAddress} {customer.company?.billingAddress?.extraAddress && customer.company.billingAddress.extraAddress}</P>
              <P>Post Code: {customer.company?.billingAddress?.postalCode}</P>
              <P>City: {customer.company?.billingAddress?.city}</P>
              {/* {customer.company?.billingAddress?.division && <P>{customer.company.billingAddress.division}</P>} */}
              <P>Country: {customer.company?.billingAddress?.country}</P>
              <P>Email: {customer.company?.billingAddress?.contactEmail}</P>
              {/* {customer.company?.billingAddress?.contactPhone && <P>{customer.company.billingAddress.contactPhone}</P>} */}
            </View>
            {/* } */}
          </View>
        </Page>

        <Page
          title={'Services'}
          linkTitle={(saving.modifyServices !== 'saving') ? 'Edit ' : null}
          linkIcon={(role === 1 || role === 2) ? <MdEdit /> : null}
          onClickLink={() => { setShowServiceModal(true) }}
        >
          <View>
            {saving.modifyServices === 'saving' ? <P>Saving...</P> : null}
            <CheckboxItem
              label={'Driving logs'}
              checked={customer.services?.includes(ServiceType.DrivingLogs)}
            />
            <CheckboxItem
              label={'Driving Logs including start weight'}
              checked={customer.services?.includes(ServiceType.DrivingLogsWithStartWeight)}
            />
            <CheckboxItem
              label={'Weight reports'}
              checked={customer.services?.includes(ServiceType.WeightReports)}
            />
            <CheckboxItem
              label={'Battery warnings'}
              checked={customer.services?.includes(ServiceType.BatteryWarnings)}
            />
            <CheckboxItem
              label={'Climate Reports'}
              checked={customer.services?.includes(ServiceType.ClimateReports)}
            />
            <CheckboxItem
              label={'Map Access'}
              checked={customer.services?.includes(ServiceType.mapAccess)}
            />
            <CheckboxItem
              label={'Asset Tracking'}
              checked={customer.services?.includes(ServiceType.AssetTrackers)}
            />
            <CheckboxItem
              label={'Lps Reports'}
              checked={customer.services?.includes(ServiceType.LpsService)}
            />
            <CheckboxItem
              label={'Maintenance Access'}
              checked={customer.services?.includes(ServiceType.MaintenanceAccess)}
            />
            <CheckboxItem
              label={'Security System Reports'}
              checked={customer.services?.includes(ServiceType.SecuritySystemReports)}
            />
            <CheckboxItem
              label={'Weight Reports without geo location'}
              checked={customer.services?.includes(ServiceType.WeightReportsNoGeo)}
            />
             <CheckboxItem
              label={'Map Access without geo location'}
              checked={customer.services?.includes(ServiceType.mapAccessNoGeo)}
            />
             <CheckboxItem
              label={'Critical Alerts'}
              checked={customer.services?.includes(ServiceType.CriticalAlerts)}
            />
             <CheckboxItem
              label={'Lock Reports'}
              checked={customer.services?.includes(ServiceType.LockReports)}
            />
          </View>
        </Page>

        <Page title={'Users'} linkTitle={'Add user'} onClickLink={() => {
          setShowUserModal(true)
        }} >
          <View extend={styles.padding}>
            <TableWidget
              isSaving={isSavingUserTable}
              data={customersUsers}
              onFetchData={(options) => {
                if (loading.fetchUsers !== 'loading' && !isSavingUserTable)
                  fetchCustomerUsers(options)
              }}
              totalItems={customer?.users_total ?
                customer.users_total + (customer.added_users?.length ?? 0) : 0}
              columns={users_columns(themes, resend_invite)}
              themes={themes}
              extend={{ marginTop: 20 }}
              // onClickRow={ onRemoveUser }
              onClickRow={enableUserRoles ? handleClickUser : null}
              onRemove={onRemoveUser}
              tag={customerId}
            />
          </View>
        </Page>

        <ModalAddUser
          openModal={showUserModal}
          onClose={() => setShowUserModal(false)}
          customer={customer}
          addUser={addUser}
          disabled={saving && saving.addUser === 'saving'}
          userAlreadyExist={saving && saving.addUser === 'conflict'}
          incorrectEmail={saving && saving.addUser === 'incorrect_email'}
        />

        <ModalRemoveItem
          openModal={userToRemove ? true : false}
          onClose={() => setUserToRemove(null)}
          onSubmit={() => {
            removeUser({ email: userToRemove, tag: customer.customerId })
            setUserToRemove(null)
          }}
          header={' Are you sure you want to remove the user?'}
          content={'The user will no longer be able to log in and use the service.'}
        />

        <Page title={'Vehicles'} linkTitle={'Add vehicle'} onClickLink={() => {
          setShowVehicleModal(true)
        }}>
          {savingCustomerFleet?.addVehicleToCustomer === 'failed' && <P large style={{ color: theme.colors.red }}>Adding vehicle failed. (Server error)</P>}
          {savingCustomerFleet?.removeVehicleFromCustomer === 'failed' && <P large style={{ color: theme.colors.red }}>Removing vehicle failed. (Server error)</P>}

          <TableWidget
            isSaving={isSavingVehicleTable}
            data={customer_vehicles_prepared}
            onFetchData={(options) => {
              if (loading.fetchVehicles !== 'loading' && !isSavingVehicleTable)
                fetchCustomerVehicles(options)
            }}
            totalItems={customer && customer.vehicles_total ? customer.vehicles_total : 0}
            columns={vehicle_columns}
            themes={themes}
            extend={{ marginTop: 20 }}
            customerId={customerId}
            onClickRow={(event, id) => {
              navigate('/reports/general_vehicles/' + id);
            }}
            tag={customerId}
            onRemove={onRemoveVehicle}
            sidescroll
          />
        </Page>

        <ModalAddVehicle
          openModal={showVehicleModal}
          onClose={() => setShowVehicleModal(false)}
          customer={customer}
          addVehicle={addVehicleToCustomer}
        />

        <ModalRemoveItem
          openModal={vehicleToRemove ? true : false}
          onClose={() => setVehicleToRemove(null)}
          onSubmit={() => {
            removeVehicleFromCustomer({ vehicleId: vehicleToRemove, tags: customer.vehicles.find(x => x.mainbox_id === vehicleToRemove).organisationTags })
            setVehicleToRemove(null)
          }}
          header={' Are you sure?'}
          content={'The vehicle will no longer be attached to this customer. The users of this customer will not be able to see the vehicle or its history.'}
        />

        <ModalAddService
          openModal={showServiceModal}
          onClose={() => setShowServiceModal(false)}
          customer={customer}
        />

        <ModalManageCompanyDetails
          openModal={showCompanyModal}
          onClose={() => setShowCompanyModal(false)}
          onSubmit={addCompanyDetails}
          customer={customer}
        />

        <ModalAddress
          title={'Customer Address'}
          openModal={showAddressModal === 'organisationAddress' ? true : false}
          onClose={() => setShowAddressModal('')}
          onSubmit={addCustomerAddress}
          customer={customer}
          address={customer.company?.[showAddressModal]}
        />

        <ModalAddress
          title={'Billing Address'}
          openModal={showAddressModal === 'billingAddress' ? true : false}
          onClose={() => setShowAddressModal('')}
          onSubmit={addBillingAddress}
          customer={customer}
          address={customer.company?.[showAddressModal]}
        />
      </View>
    )
  } else {
    return (
      <Page>
        {
          showLoading && <H3 children={"Loading..."} />
        }
      </Page>
    )
  }
};

const styles = {
  content: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  padding_style: {
    // marginBottom: '40px',
    marginRight: 80,
    '& div': {
      // marginBottom: 40
    },
    width: 350
  },
  padding: {
    marginBottom: '40px'
  },
  modalTable: {
    border: '3px solid red'
  },
};

const mapStateToProps = ({
  customer,
  vehicles,
  vehicle,
  themes,
  saving,
  loading,
  savingCustomerFleet,
  role,
}) => ({
  customer,
  vehicles,
  vehicle,
  themes,
  saving,
  loading,
  savingCustomerFleet,
  role,
})

const mapDispatchToProps = dispatch => ({
  fetchCustomer: id => dispatch(fetchCustomer(id)),
  addUser: data => dispatch(addUser(data)),
  addVehicleToCustomer: (data) => dispatch(addVehicleToCustomer(data)),
  removeVehicleFromCustomer: (data) => dispatch(removeVehicleFromCustomer(data)),
  removeUser: data => dispatch(removeUser(data)),
  fetchCustomerUsers: (customerId, options) => dispatch(fetchCustomerUsers(customerId, options)),
  fetchCustomerVehicles: (options) => dispatch(fetchCustomerVehicles(options)),
  addCompanyDetails: (customerId, companyName, vatNumber, organisationNumber) => dispatch(addCompanyDetails(customerId, companyName, vatNumber, organisationNumber)),
  addCustomerAddress: (customerId, address) => dispatch(addCustomerAddress(customerId, address)),
  addBillingAddress: (customerId, address) => dispatch(addBillingAddress(customerId, address)),
  sendInvite: email => dispatch(sendInvite(email))
})


const users_columns = enableUserRoles ? (themes, sendInvite) => [
  {
    id: 'name',
    label: 'Name',
    width: themes.device !== 'mobile' && 70,
    hide: []
  },
  {
    id: 'email',
    label: 'Email',
    width: themes.device !== 'mobile' && 70,
    hide: []
  },
  {
    id: 'role',
    label: 'Role',
    width: themes.device !== 'mobile' && 70,
    hide: []
  },
  {
    id: 'verified',
    label: 'Verified Email',
    width: themes.device !== 'mobile' && 70,
    hide: [],
    isTag: true,
    tagColor: value => value === 'verified' ? 'primary' : 'warning',
    showButton: value => value === 'not verified',
    buttonTitle: 'Resend email',
    onClickButton: (row) => (sendInvite(row)),
    buttonDisabled: (row) => row.invite_sent
  },
  {
    id: 'created_date',
    label: 'Registered',
    width: themes.device !== 'mobile' && 70,
    hide: ['mobile']
  }
] : (themes, sendInvite) => [
  {
    id: 'name',
    label: 'Name',
    width: themes.device !== 'mobile' && 70,
    hide: []
  },
  {
    id: 'email',
    label: 'Email',
    width: themes.device !== 'mobile' && 70,
    hide: []
  },
  {
    id: 'verified',
    label: 'Verified Email',
    width: themes.device !== 'mobile' && 70,
    hide: [],
    isTag: true,
    tagColor: value => value === 'verified' ? 'primary' : 'warning',
    showButton: value => value === 'not verified',
    buttonTitle: 'Resend email',
    onClickButton: (row) => (sendInvite(row)),
    buttonDisabled: (row) => row.invite_sent
  },
  {
    id: 'created_date',
    label: 'Registered',
    width: themes.device !== 'mobile' && 70,
    hide: ['mobile']
  }
]

const CheckboxItem = ({ label, checked }) => {
  return (
    <View extend={[{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      height: '30px',
    }]}>
      <View style={{}}>
        <Checkbox
          size="small"
          checked={checked}
          disabled={true}
        />
      </View>
      <View style={{}}>
        <P strong={checked}>{label}</P>
      </View>
    </View>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Customer);