import React from 'react'
import View from "@modul-connect/shared/components/atoms/view"
import { P, H3 } from "@modul-connect/shared/components/atoms/text"
import moment from "moment"
import { Fragment } from 'react'
import { dateStringToDate } from '@modul-connect/shared/utils/dateTimeUtils.ts'


export const VehicleInfo = ({
  vehicle,
  padding_style
}) => {
  return (
    <Fragment>
      <View extend={ padding_style }>
        <H3>General information</H3>
          <P>VIN: { vehicle.vehicle && vehicle.vehicle.vin ? vehicle.vehicle.vin : '-' }</P>
          <P>Country: { vehicle.country || '-'  }</P>
          <P>Workshop: { vehicle.workshop || '-'  }</P>
          <P>Order Number: { vehicle.vehicle && vehicle.vehicle.orderNumber ? vehicle.vehicle.orderNumber : '-'  }</P>
          <P>License Number: { vehicle.vehicle && vehicle.vehicle.licenseNumber ? vehicle.vehicle.licenseNumber : '-'  }</P>
          <P>Registered: { vehicle.manufactureData ? moment(vehicle.manufactureData.created).format('MMMM Do YYYY, h:mm:ss a') : '-' }</P>
      </View>
      
      <View extend={ padding_style }>
        <H3>Modul-Connect box details</H3>
        <P>Serial number: { vehicle.manufactureData ? vehicle.manufactureData.serialNumber : '-' }</P>
        <P>Assembly number: { vehicle.manufactureData ? vehicle.manufactureData.assemblyNumber : '-' }</P>
        <P>Revision: { vehicle.manufactureData ? vehicle.manufactureData.revision : '-' }</P>
        <P>Manufactured: { vehicle.manufactureData && vehicle.manufactureData.manufacturerDate ? 
          dateStringToDate(vehicle.manufactureData.manufacturerDate) ?
            dateStringToDate(vehicle.manufactureData.manufacturerDate).format('MMMM Do YYYY, h:mm:ss a') : '-'
            : '-' }</P>
                      
      </View>
      { 
        vehicle.telematicsData &&
        <View extend={ padding_style }>
          <H3>Telematics Unit</H3>
          <P>Telematics Id: { vehicle.telematicsData.telematicsId ? 
            vehicle.telematicsData.telematicsId : '-' }</P>
          <P>Serial number: { vehicle.telematicsData.serialNumber ? 
            vehicle.telematicsData.serialNumber : '-' }</P>
          <P>Assembly number: { vehicle.telematicsData.assemblyNumber ? 
            vehicle.telematicsData.assemblyNumber : '-' }</P>
          <P>Revision: { vehicle.telematicsData.revision ? 
            vehicle.telematicsData.revision : '-' }</P>
          <P>Manufactured: { vehicle.telematicsData.manufacturerDate ? 
            dateStringToDate(vehicle.telematicsData.manufacturerDate) ?
              dateStringToDate(vehicle.telematicsData.manufacturerDate).format('MMMM Do YYYY, h:mm:ss a') : '-'
              : '-' }</P>
          <P>Registered: { vehicle.telematicsData.created ? 
            moment(vehicle.telematicsData.created).format('MMMM Do YYYY, h:mm:ss a') : '-' }</P>
        </View>
      }
    </Fragment>
  )
}