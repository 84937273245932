import { all } from 'redux-saga/effects'
import { requestsHandler } from './requests'
import { themesHandler } from './themes'
import { userSagas } from './user'
import { vehicleSagas } from './vehicles'
import { customerSagas } from './customers'
import { workhsopSagas } from './workshops'

export function* rootSaga() {
  yield all([
    customerSagas(),
    vehicleSagas(),
    requestsHandler(),
    themesHandler(),
    userSagas(),
    workhsopSagas()
  ])
}