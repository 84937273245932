import React from 'react';
import { Modal } from "@mui/material";
import Fade from '@mui/material/Fade';

import View from '@modul-connect/shared/components/atoms/view'
import { H1, P } from '@modul-connect/shared/components/atoms/text'
import TextField from '@modul-connect/shared/components/molecules/textField'
import Button from '@modul-connect/shared/components/atoms/button';
import modal from '@modul-connect/shared/components/atoms/modal'

const ModalManageCompanyDetails = ({
    openModal,
    onClose,
    onSubmit,
    customer
}) => {
    const [companyName, setCompanyName] = React.useState(customer.company?.companyName ?? customer.name)
    const [vat, setVat] = React.useState(customer.company?.vatNumber ?? '')
    const [organisationNumber, setOrganisationNumber] = React.useState(customer.company?.organisationNumber ?? '')
    const [submitPressed, setSubmitPressed] = React.useState(false)

    React.useEffect(() => {
      if (openModal) {
        setVat(customer.company?.vatNumber)
        setCompanyName(customer.company?.companyName ?? customer.name)
        setOrganisationNumber(customer.company?.organisationNumber ?? '')
        setSubmitPressed(false)
      }
    }, [openModal])

    const onSave = () => {
        setSubmitPressed(true)

        if (!companyName || !vat) return

        onSubmit(customer.customerId, companyName === '' ? null : companyName, vat === '' ? null : vat, organisationNumber === '' ? null : organisationNumber)
        onClose()
    }

    return (
        <Modal
            open={ openModal }
            onClose={ onClose }
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <Fade in={ openModal }>
                <div >
                    <View extend={ modal.container }>
                        <View extend={ modal.header }>
                            <H1 text={"Add Customer Details"}/>
                        </View>
                        <View extend={ modal.content }>
                            <TextField
                                name="Customer Account"
                                placeholder= { customer.company?.companyName ?? customer.name }
                                error={submitPressed && !companyName}
                                onChange={e => setCompanyName(e.target.value)}
                                value={companyName}
                            />
                            <TextField
                                name="VAT"
                                placeholder={ customer.company?.vatNumber ?? 'e.g. SE999999999901' }
                                error={submitPressed && !vat}
                                onChange={e => setVat(e.target.value)}
                                value={vat}
                            />
                        </View>
                        <View extend={ modal.footer }>
                            <Button noBg cancel onClick={ () => onClose() }>
                                Cancel
                            </Button>
                            <Button noBg noPadding 
                              disabled={ 
                                customer.company && companyName === customer.company.companyName && 
                                vat === customer.company.vatNumber
                              } onClick={ onSave }>
                                Submit
                            </Button>
                        </View>
                    </View>
                </div>
            </Fade>
        </Modal>
    )
}

export default ModalManageCompanyDetails;