export const workshopColumns = (themes) => [
  {
    id: 'name',
    label: 'Workshop',
    width: themes.device !== 'mobile' && 70,
    hide: []
  }
]

export const userColumns = (themes, sendInvite) => [
  {
    id: 'name',
    label: 'Name',
    width: themes.device !== 'mobile' && 70,
    hide: []
  },
  {
    id: 'email',
    label: 'Email',
    width: themes.device !== 'mobile' && 70,
    hide: []
  },
  {
    id: 'verified',
    label: 'Verified Email',
    width: themes.device !== 'mobile' && 70,
    hide: [],
    isTag: true,
    tagColor: value => value === 'verified' ? 'primary' : 'warning',
    showButton: value => value === 'not verified',
    buttonTitle: 'Resend invite',
    onClickButton: row => sendInvite(row.email),
    buttonDisabled: row => row.invite_sent
  },
  {
    id: 'registered',
    label: 'Registered',
    width: themes.device !== 'mobile' && 70,
    hide: ['mobile']
  }
]