import { Modal } from '@mui/material'
import Fade from '@mui/material/Fade'
import Button from '@modul-connect/shared/components/atoms/button'
import modal from '@modul-connect/shared/components/atoms/modal'
import { H1 } from '@modul-connect/shared/components/atoms/text'
import View from '@modul-connect/shared/components/atoms/view'
import TextField from '@modul-connect/shared/components/molecules/textField'
import TreeWidget from "@modul-connect/shared/components/molecules/treeWidget"
import React, { useEffect, useState } from 'react'
import { isEmail } from '@modul-connect/shared/utils/text'

const Roles = {
  standard: 'standard',
  admin: 'admin',
  super: 'super'
}

const ModalAddUser = ({
    openModal,
    onClose,
    workshopId,
    addUser,
    disabled
}) => {
    const [firstName, setFirstName] = useState(undefined)
    const [lastName, setLastName] = useState(undefined)
    const [email, setEmail] = useState(undefined)
    const [errorFirstName, setErrorFirstName] = useState(false)
    const [errorLastName, setErrorLastName] = useState(false)
    const [errorEmail, setErrorEmail] = useState(false)
    const [selectedRole, setSelectedRole] = useState(Roles.standard)

    useEffect(() => {
        if(openModal) {
            setFirstName(undefined)
            setLastName(undefined)
            setEmail(undefined)
            setErrorFirstName(false)
            setErrorLastName(false)
            setErrorEmail(false)
            setSelectedRole(Roles.standard)
        }
    }, [openModal])

    const isName = name => name?.length

    const onSave = () => {

        if(!isName(firstName)) {
            setErrorFirstName(true)
            return
        }

        if(!isName(lastName)) {
            setErrorLastName(true)
            return
        }

        if(!isEmail(email)) {
            setErrorEmail(true)
            return
        }

        const data = {
            firstName: firstName.trim(),
            lastName: lastName.trim(),
            name: firstName.trim() + ' ' + lastName.trim(),
            email: email.trim(),
            organisation: workshopId,
            privilege: selectedRole
        }

        addUser(data)
    }

    return (
        <Modal
            open={ openModal }
            onClose={ onClose }            
            aria-labelledby='simple-modal-title'
            aria-describedby='simple-modal-description'
        >
            <Fade in={ openModal }>
                <div >
                    <View extend={ modal.container }>
                        <View extend={ modal.header }>
                            <H1 text={'Add technician'}/>
                        </View>
                        <View extend={ modal.content }>
                            <TextField
                                type='email'
                                name='Email'
                                disabled={ disabled }
                                onBlur={ e => setErrorEmail(!isEmail(e.target.value)) }
                                placeholder='e.g. sherlock@holmes.co.uk'
                                error={ errorEmail }
                                errorText={ '' }
                                onChange={ event => setEmail(event.target.value) }
                            />
                            <TextField
                                name='First Name'
                                placeholder='e.g. Sherlock'
                                disabled={ disabled }
                                onBlur={ e => setErrorFirstName(!isName(e.target.value)) }
                                error={ errorFirstName }
                                onChange={ e => setFirstName(e.target.value) }
                            />
                            <TextField
                                name='Last Name'
                                placeholder='e.g. Holmes'
                                disabled={ disabled }
                                onBlur={ e => setErrorLastName(!isName(e.target.value)) }
                                error={ errorLastName }
                                onChange={ e => setLastName(e.target.value) }
                            />
                            <TreeWidget 
                              title={ 'Role'}
                              hideRoot
                              tree={{
                                  uuid: 1, name: 'Role', 
                                  children: [
                                      {uuid: Roles.standard, name: 'Standard (cannot access backoffice)'},
                                      {uuid: Roles.admin, name: 'Admin (can access their workshop in backoffice)'},
                                      {uuid: Roles.super, name: 'Super (can access everything in backoffice)'}
                                  ]
                              }}
                              onSelect={ (uuid, _) => uuid !== 1 && setSelectedRole(uuid) }
                              selected={ selectedRole }
                            />
                        </View>
                        <View extend={ modal.footer }>
                            <Button noBg disabled={ disabled } cancel onClick={ () => onClose() }>
                                Cancel
                            </Button>
                            <Button noBg disabled={ disabled } noPadding onClick={ onSave }>
                                Submit
                            </Button>
                        </View>
                    </View>
                </div>
            </Fade>
        </Modal>
    )
}

export default ModalAddUser