import React from 'react';
import { Modal } from "@mui/material";
import Fade from '@mui/material/Fade';

import View from '@modul-connect/shared/components/atoms/view'
import { H1, P } from '@modul-connect/shared/components/atoms/text'
import Button from '@modul-connect/shared/components/atoms/button';
import modal from '@modul-connect/shared/components/atoms/modal'


const RemoveModal = ({
    openModal,
    onClose,
    onSubmit,
    type,
    name,
    disabled
}) => {
    return (
        <Modal
            open={ openModal }
            onClose={ onClose }            
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <Fade in={ openModal }>
                <div >
                    <View extend={ modal.container }>
                        <View extend={ modal.header }>
                            <H1 text={ `Remove ${type}` } />
                        </View>
                        <View extend={ modal.content }>
                            <P>{ `Are you sure you want to remove ${name}?` }</P>
                        </View>
                        <View extend={ modal.footer }>
                            <Button noBg disabled={ disabled } cancel onClick={ () => onClose() }>
                                Cancel
                            </Button>
                            <Button noBg issue disabled={ disabled } noPadding onClick={ onSubmit }>
                                Remove
                            </Button>
                        </View>
                    </View>
                </div>
            </Fade>
        </Modal>
    )
}

export default RemoveModal;