import React, { useEffect } from 'react';
import View from '@modul-connect/shared/components/atoms/view';
import Prism from "prismjs";
import "./prism.css";

export const Code  = ({
  syntax,
  large,
  extend,
  ...props
}) => {
  useEffect(() => {
    Prism.highlightAll();
  }, [syntax])

  return (
    <View as="pre" {...props} extend={ [styles.code, extend ] }>
      { syntax ?
        <code className="language-javascript">
          {`${ syntax }`}
        </code>
      : <code>&nbsp;</code> }
    </View>
  )
}

const styles = {
  code: ({ theme: { colors, layout } }) => ({
    margin: 0,
    // padding: `${2 * layout.grid}px`,
    backgroundColor: `${colors.white} !important`,
    fontSize: '14px !important',
    borderRadius: layout.borderRadius
  })
}