import Page from '@modul-connect/shared/components/atoms/page';
import { P } from '@modul-connect/shared/components/atoms/text';
import View from '@modul-connect/shared/components/atoms/view';
import TableWidget from '@modul-connect/shared/components/molecules/tableWidget';
import React from 'react'
import { userColumns } from '../columns/table_columns';
import theme from '@modul-connect/shared/theme';

export const TechniciansSubpage = ({
  hide,
  workshop,
  setToRemove,
  setShowRemove,
  themes,
  workshopId,
  setShowAddUser,
  styles,
  handleClickUser,
  fetchWorkshopUsers,
  users_prepared,
  saving,
  loading,
  sendInvite
}) => {

  const isSaving = saving?.addUser === 'saving' || saving?.removeUser === 'saving'

  return (
    <View style={{ visibility: hide ? 'hidden' : 'visible' }}>
      <Page
        title={'Technicians'}
        linkTitle={'Add technician'}
        onClickLink={() => setShowAddUser(true)}
      >
        <View extend={styles.padding}>
          {isSaving && <P>Saving ... </P>}
          {saving?.addUser === 'failed' && <P large style={{ color: theme.colors.red }}>Adding technician failed. (Server error)</P>}
          {saving?.removeUser === 'failed' && <P large style={{ color: theme.colors.red }}>Removing technician failed. (Server error)</P>}

          <TableWidget
            loadingStatus={ loading?.fetchWorkshopUsers }
            data={users_prepared}
            totalItems={workshop?.numberOfUsers || 0}
            columns={userColumns(themes, sendInvite)}
            searchStr={''}
            themes={themes}
            onClickRow={handleClickUser}
            onRemove={(_, id) => { setToRemove('user'); setShowRemove(id) }}
            removableIds={users_prepared?.map(x => x.id) ?? []}
            onFetchData={(options) => {
              if (!isSaving) fetchWorkshopUsers({ ...options, tag: workshopId })}}
          />
        </View>
      </Page>
    </View>
  )
}