import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { fetchDisconnectedBoxes } from "../../../state/actions/vehicles";
import TableWidget from "@modul-connect/shared/components/molecules/tableWidget";
import Page from "@modul-connect/shared/components/atoms/page";
import { DateTime } from "luxon";
import { dateTimeToString } from "@modul-connect/shared/utils/dateTimeUtils";
import { columns } from "./connection_status_columns";
import { useNavigate, useParams, Route } from "react-router-dom";
import Vehicle from "../vehicles/vehicle";

const ConnectionStatus = ({
  disconnected_boxes,
  fetchDisconnectedBoxes,
  loadingConnectionStatus,
  themes,
}) => {
  const [selectedVehicleId, setSelectedVehicleId] = useState(null);

  const navigate = useNavigate();
  const { id } = useParams();

  const boxes_prepared = disconnected_boxes?.boxes
    ? disconnected_boxes.boxes.map((item) => {
        return {
          ...item,
          id: item?.boxId,
          telematics_id: item?.telematicsId,
          time_stamp: dateTimeToString(
            DateTime.fromISO(item?.lastSeen),
            "date-and-time"
          ),
          status: item?.online,
          vin: item?.vin,
        };
      }).sort((a, b) => a?.lastSeen > b?.lastSeen ? -1 : 1)
    : [];

  useEffect(() => {
    fetchDisconnectedBoxes();
  }, []);

  const handleClick = (event, id) => {
    navigate("/reports/general_vehicles/" + id);
    setSelectedVehicleId(id);
  };

  React.useEffect(() => {
    if (id) {
      setSelectedVehicleId(id);
    }
    return () => {
      setSelectedVehicleId(null);
    };
  }, [id]);

  return (
    <Page title={"Disconnected Vehicles"}>
      <TableWidget
        loadingStatus={loadingConnectionStatus.fetchDisconnectedBoxes}
        data={boxes_prepared}
        totalItems={boxes_prepared?.length}
        columns={columns}
        onFetchData={fetchDisconnectedBoxes}
        onClickRow={handleClick}
        themes={themes}
        rowsOptions={[100]}
      />
    </Page>
  );
};

const mapStateToProps = ({ disconnected_boxes, loadingConnectionStatus, themes }) => ({
  disconnected_boxes,
  loadingConnectionStatus,
  themes,
});

const mapDispatchToProps = (dispatch) => ({
  fetchDisconnectedBoxes: () => dispatch(fetchDisconnectedBoxes()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConnectionStatus);
