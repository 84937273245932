import React, { useState, useEffect} from "react";
import { connect } from 'react-redux'
import Page from "@modul-connect/shared/components/atoms/page";
import View from "@modul-connect/shared/components/atoms/view";
import { A, H3, P } from "@modul-connect/shared/components/atoms/text";
import { Code } from "@modul-connect/shared/components/atoms/code";
import { 
    fetchVehicle, 
    fetchVehicleConfig, 
    fetchVehicleHistory,
    allowUserFirmwareUpgrade, 
    fetchUpgradePermission,
    fetchVehicleTextRecords,
  fetchDisconnectedHistory} from "../../../../state/actions/vehicles"
import { disableDebugStatus, enableDebugStatus, fetchDebugStatus, fetchDeviceTwin, fetchFirmwares } from "../../../../state/actions/firmwares"
import { useLoadingTimer } from '@modul-connect/shared/hooks';
import { useNavigate } from "react-router-dom";
import { event_history_columns } from "./eventHistory/event_history_columns"
import { getEventDescription } from "./eventHistory/eventHistoryUtils"
import TableWidget from "@modul-connect/shared/components/molecules/tableWidget/tableWidget";
import ModalRemoteUpgrade from "./modals/modalRemoteUpgrade";
import { fetchIsUpgrading } from "../../../../state/actions/firmwares";
import { firmware_products } from "../../../../utils/firmwares"
import RemotesDisplay from "./subsections/accessories/remotesDisplay";
import { VehicleInfo } from "./subsections/vehicleInfo/vehicleInfo";
import { MainboxFirmwareInfo } from "./subsections/vehicleInfo/mainboxFirmwareInfo";
import { ProductsDisplay } from "./subsections/accessories/productsDisplay";
import { vehicle_records_columns } from "./eventHistory/vehicle_records_columns";
import { MainboxDebugInfo } from "./subsections/vehicleInfo/mainboxDebugInfo";
import {applyTemplateToBox} from '../../../../state/actions/templates'
import CreateTemplateSection from "./subsections/template/createTemplate";
import theme from "@modul-connect/shared/theme";
import connectionColumns from "./ConnectionHistoryColumns";
import { DateTime } from "luxon";
import { dateTimeToString } from "@modul-connect/shared/utils/dateTimeUtils";
import HistoricalConnectionStatus from "./subsections/HistoricalConnectionStatus";


let mainbox_config_json


const Vehicle = ({
    vehicleId,
    setSelectedVehicleId,
    vehicle, 
    fetchVehicle, 
    fetchVehicleConfig, 
    fetchVehicleHistory,
    fetchVehicleTextRecords,
    fetchUpgradePermission, 
    allowUserFirmwareUpgrade,
    fetchIsUpgrading,
    fetchDebugStatus,
    enableDebug,
    disableDebug,
    firmware_update_progress,
    debug_status,
    firmwares,
    fetchFirmwares,
    fetchDeviceTwin,
    device_twin,
    fetchDisconnectedHistory,
    themes}) => {
    const [showLoading, setShowLoading] = useState(false)
    useLoadingTimer(setShowLoading)


    const [upgradeModal, setOpenUpgradeModal] = useState(false)
    const [upgradeProduct, setUpgradeProduct] = useState('none')
    const [upgradeType, setUpgradeType] = useState('none')
    const [enableButton, setEnableButton] = useState(true)


    let navigate = useNavigate()

    if(typeof(vehicle.permission) == 'undefined') {
        vehicle.permission = false
    }

    useEffect(() => {
        fetchVehicle(vehicleId)
        fetchVehicleConfig(vehicleId)
        fetchVehicleHistory({ rowsPerPage: 10, currentpage: 1 }, vehicleId)
        fetchVehicleTextRecords({ rowsPerPage: 10, currentpage: 1 }, vehicleId)
        fetchUpgradePermission(vehicleId)
        fetchFirmwares()
    }, [vehicleId])

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
      

    useEffect(() => {
      if (vehicle.telematicsData)
        fetchDeviceTwin(vehicle.telematicsData.telematicsId)
    }, [fetchDeviceTwin, vehicle])


    const vehicle_name = vehicle && vehicle.vehicle && vehicle.vehicle.brandName ?
        vehicle.vehicle.brandName + (vehicle.vehicle.modelName ? " " + vehicle.vehicle.modelName : "") + (vehicle.vehicle.year ? ", " + vehicle.vehicle.year : "") :
        'Vehicle unknown'

    useEffect(() => {
        if(!vehicle.config) {
            return
        }
//        mainbox_config_json = JSON.stringify(vehicle.config ?? [], null, 4)

        const prettyPrintedItems = vehicle.config?.map(item => JSON.stringify(item, null, 2))
        mainbox_config_json = `[${prettyPrintedItems.join(',')}]`

        if (vehicle?.telematicsData) {
            fetchIsUpgrading(vehicle.telematicsData.telematicsId)
            fetchDebugStatus(vehicle.telematicsData.telematicsId) 
        }
    }, [fetchIsUpgrading, vehicle])


    let prepared_vehicle_history = null
    if (vehicle && vehicle.history && vehicle.history.data) {
        let tableKey = 0
        prepared_vehicle_history = vehicle.history.data.map(event => getEventDescription(event))
        prepared_vehicle_history.forEach(entry => {
            tableKey++
            entry.id = tableKey
        })
    }

    let prepared_vehicle_records = null
    if (vehicle && vehicle.records && vehicle.records.data) {
        let tableKey = 0
        prepared_vehicle_records = vehicle.records.data
        prepared_vehicle_records.forEach(entry => {
            tableKey++
            entry.id = tableKey
        })
    }  

    const onUpgradeBtnClick = (product, type) => {
      if (firmware_update_progress === null || firmware_update_progress.fw_upgrade_in_progress === false) {
        setUpgradeProduct(product)
        setUpgradeType(type)
        setOpenUpgradeModal(true)
      }
    }

    if(vehicle.mainbox_id && vehicle.mainbox_id === vehicleId) {
        return(
            <View>
                <Page 
                    title={ vehicle_name }
                    linkBack={ navigate } 
                    onClickLinkBack={ () => setSelectedVehicleId(null)}>
                    <View extend={ styles.content }>
                        <VehicleInfo vehicle={ vehicle } padding_style={ styles.padding } />
                        <MainboxFirmwareInfo 
                          fetchIsUpgrading={() => fetchIsUpgrading(vehicle.telematicsData.telematicsId)}
                          onUpgradeBtnClick={ onUpgradeBtnClick }
                          firmware_update_progress={ firmware_update_progress }
                          vehicle={ vehicle }
                          firmwares={ firmwares }
                          allowUserFirmwareUpgrade={ allowUserFirmwareUpgrade }
                          padding_style={ styles.padding }
                        />
                        { vehicle.telematicsData &&
                          <MainboxDebugInfo
                            padding_style={ styles.padding }
                            debug_status={ debug_status }
                            enableDebug={ () => enableDebug(vehicle.telematicsData.telematicsId) }
                            disableDebug={ () => disableDebug(vehicle.telematicsData.telematicsId) }
                          />
                        }
                    </View>
                </Page>

                <Page title={'Connection history'}>
                  <HistoricalConnectionStatus />
                </Page>
                
                  <Page title={ 'Template'} >
                    {                    
                      vehicle?.appliedTemplate ? 
                        <View extend={ [styles.content, { flexDirection: 'column' }] }>
                          <H3>Currently applied template</H3>                 
                          <P>{vehicle.appliedTemplate}</P>
                          <A
                            onClick={() => {
                              const id = `${vehicle.templateBoxId.replace('vehicle/', '')}~${vehicle.appliedTemplate}`
                              navigate(`/reports/others_templates/${id}`);
                            }}>
                            Other vehicles with this template
                          </A>
                        </View>
                      : <CreateTemplateSection vehicleId={vehicleId}/>
                      }
                  </Page>        

                <Page title={ 'Accessories '} >
                    <View extend={ styles.content }>
                        <ProductsDisplay 
                          style={ styles.padding }
                          vehicle={ vehicle }
                        />

                        <RemotesDisplay 
                          title={ "Remote Controls" } 
                          remotes={ vehicle.remoteControls } 
                          style={ styles.padding }
                          firmware_product={ firmware_products.remoteControl }
                          canUpgrade={ vehicle?.telematicsData }
                          onUpgradeBtnClick={ onUpgradeBtnClick }/>

                        <RemotesDisplay 
                          title={ "Display Switches" } 
                          remotes={ vehicle.displaySwitches } 
                          style={ styles.padding }
                          firmware_product={ firmware_products.displayswitch }
                          canUpgrade={ vehicle?.telematicsData }
                          onUpgradeBtnClick={ onUpgradeBtnClick }/>

                        <RemotesDisplay 
                          title={ "Backlit Switches" } 
                          remotes={ vehicle.backlitSwitches } 
                          style={ styles.padding }
                          firmware_product={ firmware_products.backlightSwitch }
                          canUpgrade={ vehicle?.telematicsData }
                          onUpgradeBtnClick={ onUpgradeBtnClick }/>

                        <RemotesDisplay 
                          title={ "PIR Sensors" } 
                          remotes={ vehicle.pirs } 
                          style={ styles.padding }
                          firmware_product={ firmware_products.pir }
                          canUpgrade={ vehicle?.telematicsData }
                          onUpgradeBtnClick={ onUpgradeBtnClick }/>

                        <RemotesDisplay 
                          title={ "Climate Sensors" } 
                          remotes={ vehicle.climateSensors } 
                          style={ styles.padding }
                          firmware_product={ firmware_products.climatesensor }
                          canUpgrade={ vehicle?.telematicsData }
                          onUpgradeBtnClick={ onUpgradeBtnClick }/>

                        <RemotesDisplay 
                          title={ "Magnetic Sensors" } 
                          remotes={ vehicle.magneticSensors } 
                          style={ styles.padding }
                          firmware_product={ firmware_products.magneticsensor }
                          canUpgrade={ vehicle?.telematicsData }
                          onUpgradeBtnClick={ onUpgradeBtnClick }/>

                    </View>
                </Page>
                <Page title={ 'Configuration details' }>
                    <H3>Mainbox detailed configuration</H3>
                    <Code syntax={ mainbox_config_json } />
                </Page>
                { vehicle.telematicsData &&
                  <Page title={ 'Iot device twin' }>
                    <H3>Reported device twin on iot-hub</H3>
                    <Code syntax={ JSON.stringify(JSON.parse(device_twin), null, 2) } className='language-json' />
                  </Page>
                }
                <Page title={ 'History' }>
                    <H3>Event history of the mainbox</H3>
                    {
                        prepared_vehicle_history && prepared_vehicle_history.length > 0 ?
                        <TableWidget
                            data={ prepared_vehicle_history }
                            totalItems={ vehicle?.history?.total ?? 0 } 
                            columns={ event_history_columns(themes) }
                            themes={ themes }
                            isSortable={ false }
                            onFetchData={ options => fetchVehicleHistory(
                                options,
                                vehicle.mainbox_id
                            )}
                        />
                        :
                        <H3>No history available.</H3>
                    }
                </Page>
                { vehicle.telematicsData &&
                    <Page title={ 'Telemetry Records' }>
                        <H3>Text records sent by the mainbox</H3>
                        {
                            prepared_vehicle_records && prepared_vehicle_records.length > 0 ?
                            <TableWidget
                                data={ prepared_vehicle_records }
                                totalItems={ vehicle?.records?.total ?? 0 } 
                                columns={ vehicle_records_columns(themes) }
                                themes={ themes }
                                isSortable={ false }
                                onFetchData={ options => fetchVehicleTextRecords(
                                    options,
                                    vehicle.mainbox_id
                                )}
                            />
                            :
                            <H3>No records available.</H3>
                        }
                    </Page>
                }
                <ModalRemoteUpgrade
                    openModal={ upgradeModal }
                    onClose={ () => setOpenUpgradeModal(false) }
                    singleProduct={ upgradeProduct }
                    singleType={ upgradeType }
                />
            </View>
        )
    } else if(showLoading) {
        return (
            <Page><H3 children={"Loading..."} /></Page>
        )
    } else {
        return <Page></Page>
    }
};

const styles = {
    content: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    padding: {
        // marginBottom: '40px',
        marginRight: 80,
        '& div': {
            // marginBottom: 40
        },
        width: 350
    },
  };

const mapStateToProps = ({
    vehicle,
    firmware_update_progress,
    debug_status,
    firmwares,
    device_twin,
    themes,
}) => ({
    vehicle,
    firmware_update_progress,
    debug_status,
    firmwares,
    device_twin,
    themes,
})

const mapDispatchToProps = dispatch => ({
  fetchVehicle: id => dispatch(fetchVehicle(id)),
  fetchVehicleConfig: id => dispatch(fetchVehicleConfig(id)),
  fetchVehicleHistory: (options, id) => dispatch(fetchVehicleHistory(options, id)),
  fetchVehicleTextRecords: (options, id) => dispatch(fetchVehicleTextRecords(options, id)),
  fetchUpgradePermission: mainboxId => dispatch(fetchUpgradePermission(mainboxId)),
  allowUserFirmwareUpgrade: (untilDate, mainboxId, forbid) => dispatch(allowUserFirmwareUpgrade(untilDate, mainboxId, forbid)),
  fetchDisconnectedHistory: telematicsId => dispatch(fetchDisconnectedHistory(telematicsId)),
  fetchIsUpgrading: telematicsId => dispatch(fetchIsUpgrading(telematicsId)),
  fetchDebugStatus: telematicsId => dispatch(fetchDebugStatus(telematicsId)),
  enableDebug: telematicsId => dispatch(enableDebugStatus(telematicsId)),
  disableDebug: telematicsId => dispatch(disableDebugStatus(telematicsId)),
  fetchFirmwares: () => dispatch(fetchFirmwares()),
  fetchDeviceTwin: telematicsId => dispatch(fetchDeviceTwin(telematicsId)),
  applyTemplateToBox: (vehicleId, appliedTemplate) => dispatch( applyTemplateToBox(vehicleId, appliedTemplate)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Vehicle);
