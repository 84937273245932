import React, { useEffect } from "react";
import Page from "@modul-connect/shared/components/atoms/page";
import View from "@modul-connect/shared/components/atoms/view";
import { connect } from 'react-redux'
import DataRegisteredPerWeek from "@modul-connect/shared/components/organisms/data-registered-per-week";
import DataRegisteredTotal from "@modul-connect/shared/components/organisms/data-registered-total";
import moment from "moment"
import { fetchBoxesRegistered } from "../../state/actions/vehicles"
import { fetchUsersRegistered } from "../../state/actions/users"

const Dashboard = ({boxes_registered, users_registered, fetchUsersRegistered, fetchBoxesRegistered, themes, role}) => {
  useEffect(() => {
    const fetchData =  () => {
      fetchBoxesRegistered()
      fetchUsersRegistered()
    }
    fetchData()
  }, [])

  const prepareDataRegistered = (data_registered) => {
    if(!data_registered) {
      return []
    }

    if(typeof(data_registered) !== 'object') {
      return []
    }

  

    let data = []
    let weeksWithData = data_registered.map((week) => {
      return {
        year: week.time.year,
        week: week.time.week
      }
    })

    let now = moment(new Date())
    let firstPost_time = data_registered[0].time
    let timestamp = moment().year(firstPost_time.year).week(firstPost_time.week).day("Monday").hour(0).minute(0).second(0)
    let aggregated = 0

    while(timestamp <= now) {
      let current_year = timestamp.isoWeekYear()
      let current_week = timestamp.isoWeek()

      let week_index = weeksWithData.findIndex(x => x.year == current_year && x.week == current_week)
      if(week_index !== -1) {
        aggregated = aggregated + data_registered[week_index].count
        data.push({
          name: current_week,
          data: {current_week, current_year},
          count: data_registered[week_index].count,
          aggregated: aggregated
        })
      } else {
        data.push({
          name: current_week,
          data: {current_week, current_year},
          count: 0,
          aggregated: aggregated
        })
      }

      timestamp.add(1, 'week')
    }

    return data.slice(-55) // only include the last year
  }


  const boxes_registered_week = boxes_registered?.length ? prepareDataRegistered(boxes_registered) : []
  const users_registered_week = users_registered?.length ? prepareDataRegistered(users_registered) : []

  return (
    <Page>
      {(themes.device === 'desktop') && (
        <View extend={ styles.dashboard }>
          <View extend={ styles.column }>
            {
              boxes_registered?.length ? 
              <DataRegisteredPerWeek
                dataRegistered_week={ boxes_registered_week }
                title = {'Vehicles registered per week'}
              /> :null
            }
            { 
              role == 1 && users_registered?.length ?
              <DataRegisteredPerWeek
                dataRegistered_week={ users_registered_week }
                title = {'Users registered per week'}
              /> : null
            }
            { 
              users_registered?.length == 0 && boxes_registered?.length == 0  ?
              "Just an empty parking space - use the app to configure your first vehicle 🥳" : null
            }
          </View>
          <View extend={ styles.column }>
            {
              boxes_registered?.length ?
              <DataRegisteredTotal
                dataRegistered_week={ boxes_registered_week || [] }
                title = {'Total vehicles registered'}
              /> : null
            }
            {
              role == 1 && users_registered?.length ?
              <DataRegisteredTotal
                dataRegistered_week={ users_registered_week }
                title = {'Total users registered'}
              /> : null
            }
          </View>
        </View>
      )}
      {(themes.device === 'mobile' || themes.device === 'tablet') && (
        <View extend={ styles.dashboard }>
          { 
            boxes_registered?.length ?
            <DataRegisteredPerWeek
              dataRegistered_week={ boxes_registered_week }
              title = {'Vehicles registered per week'}
            /> : null
          } 
          {
            boxes_registered?.length ?
            <DataRegisteredTotal
              dataRegistered_week={ boxes_registered_week }
              title = {'Total vehicles registered'}
            /> : null
          }
          {
            role == 1 && users_registered?.length ?
            <DataRegisteredPerWeek
              dataRegistered_week={ users_registered_week }
              title = {'Users registered per week'}
            /> : null
          }
          {
            role == 1 && users_registered?.length ?
            <DataRegisteredTotal
              dataRegistered_week={ users_registered_week }
              title = {'Total users registered'}
            /> : null
          }
        </View>
      )}
    </Page>
  )
}

const styles = {
  dashboard: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    // "> *:not(:last-child)": {
    //   marginRight: '16px',
    // },
  },
  column: ({ theme: { layout, colors } }) => ({
    width: 0,
    flex: 1,
    padding: `0 ${1 * layout.grid}px 0`,
  })
};

const mapStateToProps = ({
  boxes_registered,
  users_registered,
  themes,
  role
}) => ({
  boxes_registered,
  users_registered,
  themes,
  role
})

const mapDispatchToProps = dispatch => ({
  fetchBoxesRegistered: () => dispatch(fetchBoxesRegistered()),
  fetchUsersRegistered: () => dispatch(fetchUsersRegistered())
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);