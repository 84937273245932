export const users = (state = [], action) => {
  switch (action.type) {
    case 'USERS_FETCHED':
      return {
        data: action.data,
        total: action.total
      }
    default:
      return state;
  }
}

export const users_registered = (state = [], action) => {
  switch (action.type) {
    case 'USERS_REGISTERED_FETCHED':
      return action.data
    default:
      return state;
  }
}