export const fetchFirmwares = () => (
    {
      type: 'FETCH_FIRMWARES'
    }
  )
  
export const fetchFirmwareBlob = (url, name) => (
  {
    type: 'FETCH_FIRMWARE_BLOB',
    url: url,
    name: name
  }
)

export const uploadFirmware = data => (
  {
    type: 'UPLOAD_FIRMWARE',
    data: data
  }
)

export const designateFirmwareAsStable = (type, version) => (
  {
    type: 'UPGRADE_FIRMWARE',
    firmwareType: type,
    version
  }
)

export const deleteFirmware = (type, version) => (
  {
    type: 'DELETE_FIRMWARE',
    firmwareType: type,
    version
  }
)

export const remoteUpgrade = (telematicsId, type, version) => (
    {
        type: 'REMOTE_UPGRADE',
        telematicsId,
        firmwareType: type,
        version
    }
)

export const fetchIsUpgrading = telematicsId => (
    {
        type: 'FETCH_REMOTE_UPGRADE_STATUS',
        telematicsId
    }
)

export const fetchDebugStatus = telematicsId => (
  {
    type: 'FETCH_DEBUG_STATUS',
    telematicsId
  }
)

export const enableDebugStatus = telematicsId => (
  {
    type: 'ENABLE_DEBUG_STATUS',
    telematicsId
  }
)

export const disableDebugStatus = telematicsId => (
  {
    type: 'DISABLE_DEBUG_STATUS',
    telematicsId
  }
)

export const fetchDeviceTwin = telematicsId => (
  {
    type: 'FETCH_DEVICE_TWIN',
    telematicsId
  }
)