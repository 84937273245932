import { call, put, takeEvery, delay } from 'redux-saga/effects'
import { get, post } from '@modul-connect/shared/utils/ajax'

function* fetchWorkshops({ rowsPerPage, page, sort, searchStr }) {
  const workshops = yield call(get, '/workshop')

  if(workshops && workshops.status === 'ok') {
      const data = workshops.data;
      const filtered = data.filter(u => u.name.toLowerCase().includes(searchStr.toLowerCase()));
      const sortFunction = (a, b) => {
        switch(sort) {
          case 'numberOfUsersdesc':
            return b.numberOfUsers - a.numberOfUsers
          case 'numberOfUsers':
          case 'numberOfUsersasc':
            return a.numberOfUsers - b.numberOfUsers
          case 'numberOfVehiclesdesc':
            return b.numberOfVehicles - a.numberOfVehicles
          case 'numberOfVehicles':
          case 'numberOfVehiclesasc':
            return a.numberOfVehicles - b.numberOfVehicles
          case 'namedesc':
            return b.name.localeCompare(a.name)
          case 'name':
          case 'nameasc':
          default:
            return a.name.localeCompare(b.name)
        }
      }
      const sorted = filtered.sort(sortFunction)
      const cut = sorted.length > rowsPerPage ? sorted.slice((page-1)*rowsPerPage, page*rowsPerPage) : sorted;
      yield put({ type: 'WORKSHOPS_FETCHED', data: cut, total: data.length })
  }
  else {
    yield put({ type: 'FETCHING_WORKSHOPS_FAILED' })
  }
}

function* fetchWorkshop({ id, rowsPerPage, page, sort, searchStr }) {
  let url = `/workshop/${id}`
  url += '?count=' + rowsPerPage
  url += '&page=' + page
  url += '&sortby=' + sort

  const response = yield call(get, url)

  switch(response?.status) {
    case 'ok':
      if(response.data) {
        yield put({ type: 'WORKSHOP_FETCHED',
          data: {
            ...response.data,
            workshopId: id
          }
        })
      }
      return
    case 'not_found':
    case 'no_content':
        yield put({ type: 'WORKSHOP_FETCHED',
          data: {
            ...response.data,
            workshopId: id
          }
        })
        console.warn('workshop not found')
        return
    default:
      yield put({ type: 'FETCHING_WORKSHOP_FAILED', data: {workshopId: id }})
      return
  }
}

function* addWorkshop({ name, parentId }) {
  const command = {
    name,
    parentId,
    isWorkshop: parentId?.length ? false : true
  }

  const result = yield call(post, '/cmd/customer/addorganisation', { body: JSON.stringify(command) })

  if (result && result.status && result.status === 'ok') {
    yield delay(2000) // server needs a bit more time

    yield put({ type: 'WORKSHOP_ADDED', data: { ...result.data, parentId } })
  }
  else {
    yield put({ type: 'ADDING_WORKSHOP_FAILED' })
  }
}

function* removeWorkshop({ id }) {
  const result = yield call(post, '/cmd/customer/removeorganisation', { body: JSON.stringify({ id: `organisation/${id}` }) })

   if (result && result.status && result.status === 'ok') {
    yield delay(2000) // server needs a bit more time

    yield put({ type: 'WORKSHOP_REMOVED', data: { id } })
   }
  else
    yield put({ type: 'REMOVING_WORKSHOP_FAILED', data: { id } })
}

function* renameWorkshop({ id, newName }) {
  const result = yield call(post, '/cmd/customer/renameorganisation', { body: JSON.stringify({ id: `organisation/${id}`, newName }) })

  if (result && result.status && result.status === 'ok') {
    yield delay(2000) // server needs a bit more time

    yield put({ type: 'WORKSHOP_RENAMED', data: { id, newName } })
  }
  else
    yield put({ type: 'RENAMING_WORKSHOP_FAILED', data: { id, newName } })
}

export function* workhsopSagas() {
  yield takeEvery('FETCH_WORKSHOPS', fetchWorkshops)
  yield takeEvery('FETCH_WORKSHOP', fetchWorkshop)
  yield takeEvery('ADD_WORKSHOP', addWorkshop)
  yield takeEvery('REMOVE_WORKSHOP', removeWorkshop)
  yield takeEvery('RENAME_WORKSHOP', renameWorkshop)
}