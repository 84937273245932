export default `
  @import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;400&display=swap');

  .mapboxgl-ctrl-attrib-button {
    display: none;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: 'Futura', sans-serif;
  }

  input {
    font-family: 'Futura', sans-serif;
    font-size: 14px;
  }

  .DayPickerInput > input {
    background-color: rgb(246,248,246);
    border-radius: 4px;
    border-width: 0;
    padding: 4px;
    height: 35px;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }

  a {
    text-decoration: none;
  }

  * {
    box-sizing: border-box;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  .mc-table-head {
    // color: #3A8643 !important;
    font-weight: bold !important;
    font-size: 12px !important;
    cursor: pointer !important;
    border-bottom: none !important;
  }

  .css-1ex1afd-MuiTableCell-root {
    font-family: 'Futura', sans-serif !important;
  }

  .css-1ygcj2i-MuiTableCell-root {
    font-family: 'Futura', sans-serif !important;
  }

  .css-16epbcf-MuiTypography-root {
    font-family: 'Futura', sans-serif !important;
  }

  .css-4ssu7w-MuiButtonBase-root-MuiListItem-root-MuiMenuItem-root {
    font-family: 'Futura', sans-serif !important;
  }

  .css-pdct74-MuiTablePagination-selectLabel {
    font-family: 'Futura', sans-serif !important;
  }

  .css-levciy-MuiTablePagination-displayedRows {
    font-family: 'Futura', sans-serif !important;
  }
 
 .MuiTablePagination-input {
    font-family: 'Futura', sans-serif !important;
  }

  .MuiTableRow-root {
    cursor: pointer !important;
  }

  .MuiTableSortLabel-icon {
    color: rgb(2, 134, 58) !important;
  }

  .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label {
    background-color: rgb(222 238 225) !important;
  }

  .MuiTableRow-hover:hover {
    background-color: rgb(246,248,246) !important;
  }

  .mc-table-divider:hover {
    // background-color: #fff;
    cursor: default;
  }

  ::placeholder {
    color: #ccc;
  }

  .mapboxgl-popup {
    min-width: 200px;
    min-height: 200px; 
  }
`;
