import React from 'react';
import View from "../view";
import theme from "../../../theme";

const Button = ({
    children,
    extend,
    cancel,
    disabled,
    warning,
    noBg,
    noPadding,
    device,
    issue,
    small,
    smaller,
    color,
    ...props
  }) => (
  <View as="button" {...props} disabled={disabled} extend={[
      styles.button,
      extend,
      noBg && styles.noBg,
      cancel && noBg && styles.cancel_noBg,
      issue && noBg && styles.issue_noBg,
      warning && styles.warning,
      noPadding && styles.noPadding,
      small && styles.small,
      smaller && styles.smaller,
      color && { color: color },
      disabled && noBg && styles.disabled_noBg,
      device == 'tablet' && styles.tablet
  ]}>
    {
      children
    }
  </View>
);

const styles = {
  button: ({ theme: { colors, layout } }) => ({
    backgroundColor: colors.primary,
    fontFamily: layout.fontFamily,
    textTransform: 'uppercase',
    fontSize: '16px',
    textAlign: 'center',
    color: colors.white,
    border: "none",
    padding: `${1 * layout.grid }px ${2 * layout.grid }px`,
    cursor: 'pointer !important',
    transition: "color 200ms ease-in-out",
    "&:hover": {
      filter: 'brightness(110%)'
    },
    borderRadius: layout.borderRadius
  }),
  warning: ({ theme: { colors, layout } }) => ({
    backgroundColor: colors.orange,
  }),
  noBg: ({ theme: { colors, layout } }) => ({
    backgroundColor: 'inherit',
    color: theme.colors.primary,
    '&:hover': {
      filter: 'brightness(100%)',
    }
  }),
  cancel_noBg: ({ theme: { colors, layout } }) => ({
    backgroundColor: 'inherit',
    color: theme.colors.grey,
  }),
  disabled_noBg: ({ theme: { colors, layout } }) => ({
    backgroundColor: 'inherit',
    color: theme.colors.disabledGrey,
  }),
  issue_noBg: ({ theme: { colors, layout } }) => ({
    backgroundColor: 'inherit',
    color: theme.colors.error,
  }),
  small: {
    fontSize: 12
  },
  smaller: {
    fontSize: 10
  },
  noPadding: {
    padding: 0
  },
  tablet: {
    fontSize: '14px'
  }
}

export default Button;
