import React from "react";
import View from "../../atoms/view";

const SideBar = ({ children, extend, themes, setActive = true }) => {
  const [state, setState] = React.useState(setActive);

  return (
    <View extend={!state ? styles.sidebar_inactive : [styles.sidebar_active,
        themes.device==="desktop" ? styles.sidebar_desktop :
        (themes.device==="tablet" ? styles.sidebar_tablet :
        (themes.device==="mobile" && styles.sidebar_mobile)), extend]
    }>
      {children}
    </View>
  );
};

const styles = {
  sidebar_active: ({ theme: { colors, layout } }) => ({
    backgroundColor: colors.white,
    position: 'absolute',
    left: 0,
    bottom: 0,
    top: 20,
    zIndex: 5,
    // overflowY: 'scroll',
    //overflow: 'hidden',
    height: '100vh',
    // boxShadow: '2px 2px 5px 3px rgba(0,0,0,.05)',ht
    borderRight: '1px solid rgb(240, 240, 240)',
    paddingTop: layout.navBarHeight + 8,
    paddingLeft: 8,
    paddingRight: 8,
  }),
  sidebar_inactive: {
    display: "none"
  },
  sidebar_desktop: ({ theme: { layout } }) => ({
    // top: layout.navBarHeight
  }),
  sidebar_tablet: ({ theme: { layout } }) => ({
    // top: layout.navBarHeight_tablet
  }),
  sidebar_mobile: ({ theme: { layout } }) => ({
    // top: layout.navBarHeight_mobile,
    "& .DatePickerContainer": {
      marginLeft: 'auto !important',
      marginRight: 'auto !important',
      width: '100%',
    }
  })
};

export default SideBar;
