export const firmwares_report = (state = null, action) => {
  switch (action.type) {
    case 'FIRMWARES_REPORT_FETCHED':
      return action.data
    default:
      return state
  }
}

export const firmwares = (state = [], action) => {
    let all = undefined
    let betas = undefined
    let latest = undefined
    switch (action.type) {
      case 'FIRMWARE_TOKEN_FETCHED':
        const token = action.token
        return {
            ...state,
            token: token
        }
      case 'AVAILABLE_FIRMWARES_FETCHED':
        const firmwares = action.firmwares
        return {
            ...state,
            latest: firmwares.latestFirmwares,
            betas: firmwares.betas,
            all: firmwares.firmwares,
            result: null
          }
      case 'FIRMWARE_ALREADY_UPLOADED':
      case 'FIRMWARE_UPLOADED':
        return {
            ...state,
            result: true
          }
      case 'FIRMWARE_UPGRADED':
        const data = action.data;
        all = state.all
        all.push(data)
        latest = state.latest
        if (latest.some(x => x.uuid === data.uuid)) {
          const latestFirmware = latest.find(x => x.uuid === data.uuid)
          if (CheckIfLatest(data.version, latestFirmware.version)) {
            latest = latest.filter(x => x.uuid !== data.uuid)
            latest.push(data)
          }
        } else {
          latest.push(data)
        }
        return {
          ...state,
          latest,
          betas: [],
          all
        }
      case 'FIRMWARE_DELETED':
        const oldType = action.oldType
        const oldVersion = action.oldVersion
        all = state.all.filter(x => x.url !== oldType + '?version=' + oldVersion)
        betas = state.betas.filter(x => x.url !== oldType + '?version=' + oldVersion)
        latest = state.latest
        if (latest.some(x => x.url === oldType && x.version === oldVersion)) {
          latest = latest.filter(x => x.url !== oldType)
          const latestFirmware = all.filter(x => x.url.includes(oldType)).sort((a,b) => CheckIfLatest(a.version, b.version) ? -1 : 1)[0]
          if (latestFirmware) {
            latest.push(latestFirmware)
          }
        }
        return {
          ...state,
          latest,
          betas,
          all
        }
      default:
        return state
    }
}

export const firmware_update_progress = (state = null, action) => {
  switch (action.type) {
    case 'REMOTE_UPGRADE_STATUS_RECEIVED':
      return {
        fw_upgrade_in_progress: action.data.fw_upgrade_in_progress,
        fw_transfer_progress: action.data.fw_transfer_progress
      }
    case 'FAILED_GETTING_REMOTE_UPGRADE_STATUS':
      return null
    default:
      return state
  }
}

export const debug_status = (state = null, action) => {
  switch (action.type) {
    case 'DEBUG_STATUS_RECEIVED':
      return action.data.is_in_debug
    case 'FAILED_GETTING_DEBUG_STATUS':
    case 'FAILED_REQUESTING_DEBUG_STATUS':
      return null
    default:
      return state
  }
}

function CheckIfLatest(version1, version2) {
  const version1Array = version1.split('.')
  const version2Array = version2.split('.')
  for (let i = 0; i < version1Array.length; i++) {
    if (version1Array[i] < version2Array[i]) {
      return false
    }
  }
  return true;
}

export const firmware_upgrade_request = (state = 'not sent', action) => {
  switch (action.type) {
    case 'SENDING_REMOTE_UPGRADE_REQUEST':
      return 'sending'
    case 'REMOTE_UPGRADE_REQUEST_SENT':
      return 'success'
    case 'FAILED_REMOTE_UPGRADE':
      return 'failed'
    default:
      return state
  }
}

export const device_twin = (state = null, action) => {
  switch (action.type) {
    case 'FETCHED_DEVICE_TWIN':
      return action.data
    case 'FAILED_FETCHING_DEVICE_TWIN':
      return null
    default:
      return state
  }
}