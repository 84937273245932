import React from "react";
import { connect } from 'react-redux'
import View from "@modul-connect/shared/components/atoms/view";
import Button from "@modul-connect/shared/components/atoms/button";
import { authContext } from '@modul-connect/shared/adalConfig/adalConfig';
import background_l from '@modul-connect/shared/img/login_bg_1_l.jpg';
import background_m from '@modul-connect/shared/img/login_bg_1_m.jpg';
import logo from '@modul-connect/shared/img/logo.png';
import { H1, P } from '@modul-connect/shared/components/atoms/text';
import { useAuth0 } from '@auth0/auth0-react';

/*
------------------------------------------------------------
------------------------------------------------------------
// This page is not used anymore
------------------------------------------------------------
------------------------------------------------------------
*/
const Login = ({themes}) => {
  const {
    loginWithRedirect
  } = useAuth0();
  return themes.device !== 'mobile' ?
    (
        <View extend={styles.root}>
          <View extend={styles.left}>
            <View extend={styles.logo}>
              <img src={logo} height="40" alt="Logo" />
            </View>
          </View>
          <View extend={styles.right}>
            <View extend={ styles.loginContainer}>
              <H1 noPadding text={ 'Welcome to Modul-Connect' } />
              <P large children={ 'You need to sign in to continue'} />
              <Button onClick={ () => loginWithRedirect()} extend={ styles.button}>SIGN IN</Button>
            </View>
          </View>
      </View>
      ) :
      (
        <View extend={styles.root_mobile}>
          {/* <View extend={styles.top}> */}
            <View extend={ styles.container_mobile}>
              <View extend={styles.logo_small}>
                <img src={logo} height="25" alt="Logo" />
              </View>
              <H1 noPadding text={ 'Welcome to Modul-Connect' } />
              <P large children={ 'You need to sign in to continue'} />
              <Button onClick={ () => loginWithRedirect()} extend={ styles.button}>SIGN IN</Button>
            </View>
          {/* </View> */}
      </View>
      )
};

const styles = {
  root: ({ theme: { colors, layout } }) => ({
    background: colors.white,
    height: '100vh',
    display: "flex",
    flexDirection: "row",
    flex: 1,
    justifyContent: "space-between"
  }),
  left: {
    width: '70%',
    height: '100vh',
    backgroundColor: 'black',
    backgroundImage: `url(${background_l})`,
    '@media only screen and (max-height: 1200px)': {
      backgroundImage: `url(${background_m})`
    },
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    backgroundSize: 'cover'
  },
  right: {
    width: '30%',
    minWidth: '400px',
    height: '100vh',
    display: 'flex',
    flex: 1,
    alignItems: 'center'
  },
  root_mobile: ({ theme: { colors, layout } }) => ({
    background: colors.white,
    height: '100vh',
    display: 'flex',
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: 'center',
    backgroundImage: `url(${background_l})`,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    backgroundSize: 'cover'
  }),
  container_mobile: ({ theme: { colors, layout } }) => ({
    padding: `${8 * layout.grid}px ${4 * layout.grid}px`,
    background: colors.white,
    marginTop: '80px',
    width: '100%'
  }),
  logo: {
    position: 'absolute',
    left: 30,
    bottom: 30
  },
  logo_small: {
    marginBottom: '30px'
  },
  loginContainer: ({ theme: { colors, layout } }) => ({
    position: 'relative',
    top: '-30%',
    padding: `${layout.grid}px ${4 * layout.grid}px`
  }),
  button: ({ theme: { colors, layout } }) => ({
    marginTop: `${4 * layout.grid}px`
  })
}

const mapStateToProps = ({
  themes,
}) => ({
  themes
})

const mapDispatchToProps = dispatch => ({
  // fetchVehicles: (options) => dispatch(fetchVehicles(options))
})

export default connect(mapStateToProps, mapDispatchToProps)(Login);
