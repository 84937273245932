import { takeEvery, put, call, delay, take, fork, takeLatest, select } from 'redux-saga/effects'
import { post, patch, get } from "@modul-connect/shared/utils/ajax"
import { regionNoCountry } from '../../utils/global'
import { customerServerUrl } from '@modul-connect/shared/config'



function* addUser({ firstName, lastName, email, organisation, privilege }) {
  const command = {
    email: email,
    user_metadata: {
      info: {
        tags: [
          {
            tags: organisation,
            privilege
          }
        ]
      }
    },
    firstName,
    lastName,
  }

  const result = yield call(post, '/user/createuser', { body: JSON.stringify(command) }, 'query')

  if (result && result.status) {
    switch(result.status) {
      case 'ok':
        yield delay(2000) // server needs a bit more time

        yield put({ type: 'USER_ADDED', data: { firstName: firstName, lastName: lastName, email: email }, organisation })
        return
      case 'no_content':
        yield delay(2000) // server needs a bit more time

        yield put({ type: 'USER_ADDED', data: { firstName: firstName, lastName: lastName, email: email } })
        return
      case 'bad_request':
        yield put({ type: 'INCORRECT_EMAIL'})
        return
      case 'conflict':
        yield put({ type: 'USER_ALREADY_EXISTS'})
        return
      case 'not_modified': 
        yield put({ type: 'USER_ALREADY_EXISTS'})
        return
      default:
        yield put({ type: 'ADDING_USER_FAILED'})
    }
  }
  else {
    yield put({ type: 'ADDING_USER_FAILED'})
  }
}

function* moveUser({ email, organisation, privilege }) {
  const command = {
    email,
    privileges: [
      {
        tags: organisation,
        privilege
      }
    ]
  }

  const result = yield call(post, '/user/moveuser', { body: JSON.stringify(command) }, 'query')
  if (result && result.status) {
    switch (result.status) {
      case 'ok':
        yield put({ type: 'USER_MOVED', data: { email, organisation, privilege } })
        return
      default:
        yield put({ type: 'MOVING_USER_FAILED', email })
    }
  }
}


function* updateUserPrivilege({ email, organisation, privilege }) {
  const command = {
    email,
    privileges: [
      {
        tags: organisation,
        privilege
      }
    ]
  }

  const result = yield call(post, '/user/update-privileges', { body: JSON.stringify(command) }, 'query')
  if (result && result.status) {
    switch (result.status) {
      case 'ok':
        yield put({ type: 'USER_PRIVILEGE_UPDATED', data: { email, organisation, privilege } })
        return
      default:
        yield put({ type: 'USER_PRIVILEGE_UPDATE_FAILED', email })
    }
  }
}

function* addCustomer({ region, customerName, customerId }) {

  const command = {
    'name': customerName,
    'id': 'organisation/' + customerId
  }

  let result = yield call(post, '/cmd/customer/addorganisation', { body: JSON.stringify(command) })

  if (result && result.status && result.status === 'ok') {
    if (region || region === regionNoCountry.value) {
      const addOrgCmd = {
        "id": "organisation/" + customerId,
        "organisationRegion": region
      }
      let regionResult =  yield call(post, '/cmd/customer/addorganisationregion', { body: JSON.stringify(addOrgCmd) })
      if (regionResult?.status !== 'ok') {
        yield put({ type: 'ADDING_CUSTOMER_TO_REGION_FAILED', data: { name: customerName, id: customerId, region: region } })
      }
    }

    yield put({ type: 'CUSTOMER_ADDED', data: { name: customerName, id: customerId } })
  }
  else {
    yield put({ type: 'ADDING_CUSTOMER_FAILED', data: { name: customerName, id: customerId } })
  }
}

function* removeCustomer({ customerId }) {

  const command = {
    'id': 'organisation/' + customerId
  }

  let result = yield call(post, '/cmd/customer/removeorganisation', { body: JSON.stringify(command) })

  if (result && result.status && result.status === 'ok') {
    yield put({ type: 'CUSTOMER_REMOVED', data: { id: customerId } })
  }
  else {
    yield put({ type: 'REMOVING_CUSTOMER_FAILED', data: { id: customerId } })
  }
}

function* editCustomer({region, customerName, customerId}) {

  if (region || region === regionNoCountry.value) {
    const regionUpdateCmd = {
      "id": "organisation/" + customerId,
      "organisationRegion": region
   }
   let regionResult =  yield call(post, '/cmd/customer/addorganisationregion', { body: JSON.stringify(regionUpdateCmd) })
   if (regionResult?.status !== 'ok') yield put({ type: 'ADDING_CUSTOMER_TO_REGION_FAILED', data: { name: customerName, id: customerId, region: region } })
  }

  const nameUpdateCmd = {
    'newName': customerName,
    'id': 'organisation/' + customerId
  }
  let result = yield call(post, '/cmd/customer/renameorganisation', { body: JSON.stringify(nameUpdateCmd) })

  if (result && result.status && result.status == 'ok') {
    yield put({ type: 'CUSTOMER_EDITED', data: { newName: customerName, id: customerId, countryCode: region } })
  }
  else {
    yield put({ type: 'EDITING_CUSTOMER_FAILED', data: { newName: customerName, id: customerId } })
  }
}

export function* removeUser({ email, tag }) {
  const command = {
    'email': email,
    'organisations': [tag]
  }

  const response = yield call(post, '/user/removeuser', {
    body: JSON.stringify(command),
  }, 'query')

  if (response && response.status && (response.status === 'ok' || response.status === 'no_content')) {
    yield delay(2000) // server needs a bit more time

    yield put({type: 'USER_REMOVED', data: response.status, email: email, organisation: tag})
  }
  else {
    yield put({type: 'REMOVING_USER_FAILED'})
  }
}

// function* disabledButton() {
//   console.error('sendInvite')
// }

function* sendInvite({ email }) {
  const response = yield call(patch, `/user/resend-invite/${email}`, 'query')}

function* addCompanyDetails({ customerId, companyName, vatNumber, organisationNumber }) {
  const command = {
    companyName,
    vatNumber,
    organisationNumber,
    id: `organisation/${customerId}`
  }

  const response = yield call(post, '/cmd/customer/addcompanydetails', {
    body: JSON.stringify(command)
  })

  if (response?.status && response.status === 'ok')
    yield put({type: 'COMPANY_DETAILS_ADDED', data: command})
  else {
    yield put({type: 'ADDING_COMPANY_DETAILS_FAILED', data: command})
  }
}

function* addCustomerAddress({ customerId, address }) {
  const command = {
    id: `organisation/${customerId}`,
    ...address
  }

  const response = yield call(post, '/cmd/customer/assignaddress', {
    body: JSON.stringify(command)
  })

  if (response?.status && response.status === 'ok')
    yield put({type: 'CUSTOMER_ADDRESS_ADDED', data: command})
  else 
    yield put({type: 'ADDING_CUSTOMER_ADDRESS_FAILED', data: command})
}

function* addBillingAddress({ customerId, address }) {
  const command = {
    id: `organisation/${customerId}`,
    ...address
  }

  const response = yield call(post, '/cmd/customer/assignbillingaddress', {
    body: JSON.stringify(command)
  })

  if (response?.status && response.status === 'ok')
    yield put({type: 'BILLING_ADDRESS_ADDED', data: command})
  else
    yield put({type: 'ADDING_BILLING_ADDRESS_FAILED', data: command})
}

function* modifyingServices(action) {
  const previousServices = action
  const command = {
    services: action.services,
    id: `organisation/${action.customerId}`
  }

  const response = yield call(post, '/cmd/customer/modifyservice', { body: JSON.stringify(command)})
  
  if( response?.status && response.status === 'ok') 
    yield put({ type: 'MODIFYING_SERVICES_SUCCESSFUL', data: command })
  else
    yield put({ type: 'MODIFYING_SERVICES_FAILED', data: command, previousState: previousServices})
}

function* fetchUserRoles(action) {
  const response = yield call(get, '/roles', customerServerUrl())
  
  if( response?.status && response.status === 'ok') 
    yield put({ type: 'USER_ROLES_FETCHED', data: response?.data })
  else
    yield put({ type: 'FETCHING_USER_ROLES_FAILED'})
}

 
export function* customerSagas() {
  yield takeEvery('ADD_USER', addUser)
  yield takeEvery('MOVE_USER', moveUser)
  yield takeEvery('UPDATE_USER_PRIVILEGE', updateUserPrivilege)
  yield takeEvery('REMOVE_USER', removeUser)
  yield takeEvery('SEND_INVITE', sendInvite)
  yield takeEvery('ADD_CUSTOMER', addCustomer)
  yield takeEvery('REMOVE_CUSTOMER', removeCustomer)
  yield takeEvery('EDIT_CUSTOMER', editCustomer)
  yield takeEvery('ADD_COMPANY_DETAILS', addCompanyDetails)
  yield takeEvery('ADD_CUSTOMER_ADDRESS', addCustomerAddress)
  yield takeEvery('ADD_BILLING_ADDRESS', addBillingAddress)
  yield takeEvery('MODIFY_SERVICES', modifyingServices)
  yield takeEvery('FETCH_USER_ROLES', fetchUserRoles)
}