import React, { useState } from "react";
import { connect } from 'react-redux'
import Page from "@modul-connect/shared/components/atoms/page";
import { fetchVehicles } from "../../../state/actions/vehicles"
import moment from "moment"
import vehicle_columns from "./vehicle_columns"
import View from '@modul-connect/shared/components/atoms/view';
import SearchWidget from '@modul-connect/shared/components/molecules/searchWidget';
import TableWidget from '@modul-connect/shared/components/molecules/tableWidget';
import { Switch } from "@mui/material";
import { P } from "@modul-connect/shared/components/atoms/text";
import { ThemeProvider } from '@mui/material/styles';
import theme from '@modul-connect/shared/theme';
import { useNavigate } from 'react-router-dom';
import Vehicle from './vehicle/vehicle'

export const StatusIndicator = ({connected}) => {
  return (
    <View style={{ height: 10, width: 10, borderRadius: 5, backgroundColor: connected ? theme.colors.green : theme.colors.error}} />
  )
}
const Vehicles = ({vehicles, fetchVehicles, themes, loading, id}) => {
  let filterTimer = null;

  const [filter, setFilter] = useState("");
  const [selectedVehicleId, setSelectedVehicleId] = useState(null)
  const [requireTelematicsUnit, setRequireTelematicsUnit] = useState(false);

  const navigate = useNavigate()

  const vehicles_prepared = vehicles.data ? vehicles.data.map(item => {
    return {
      ...item,
      id: item ? item.mainbox_id : null,
      vin: item.vehicle ? item.vehicle.vin : null,
      licenseNumber: item.vehicle ? item.vehicle?.licenseNumber : null,
      serialId: item ? item.mainbox_id.split('_').pop() : null,
      telematicsUnit: item && item.telematics_id ? 'yes' : 'no',
      year: item.vehicle ? item.vehicle.year : null,
      brand_model: item.vehicle && item.vehicle.brandName ? item.vehicle.brandName + " " + item.vehicle.modelName : null,
      order_number: item.vehicle ? item.vehicle.orderNumber : null ,
      template: item.appliedTemplate ? item.appliedTemplate : null ,
      created_date: moment(item.created).calendar(),
      connected: item.connected === null ?  null : <StatusIndicator connected={item?.connected }/> 
    }
  }) : []

  const handleSearchChange = (event) => {
    setFilterAfterDelay(event.target.value)
  }

  const setFilterAfterDelay = (newFilter) => {
    if (filterTimer) {
      clearTimeout(filterTimer)
    }
    
    filterTimer = setTimeout(() => {
      setFilter(newFilter)
    }, 1000)
  }

  const handleClick = (event, id) => {
    navigate('/reports/general_vehicles/' + id);
    setSelectedVehicleId(id)
  }

  React.useEffect(() => {
    // If the user is jumping to the "vehicle" page directly via link
    if (id) {
      setSelectedVehicleId(id)
    }

    return () => {
      setSelectedVehicleId(null)
    }
  }, [id])

  if (selectedVehicleId) {
    return (
      selectedVehicleId ?
      <Vehicle vehicleId={id || selectedVehicleId} setSelectedVehicleId={setSelectedVehicleId} displayVehiclesWithTemplates={(template, mainboxId) => console.log(template, mainboxId)}/>
      : null )
  }
  else return (
    <View>
      <SearchWidget 
        title={ 'Vehicles' } 
        onChange={ handleSearchChange } 
        placeholder={ 'Search...' } 
        initialValue={ filter }
        >
        <View extend={ styles.switchWrapper }>
          <P strong>
            <ThemeProvider theme={ theme.mui }>
              Show only vehicles with telematics unit
              <Switch
                  checked={ requireTelematicsUnit }
                  onChange={() => {
                      setRequireTelematicsUnit(!requireTelematicsUnit)
                  }}
                  color={ "primary" }
                  name="checkedB"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            </ThemeProvider>
          </P>
        </View>
      </SearchWidget>
    
      <Page>
        <TableWidget
          loadingStatus={loading.fetchVehicles}
          data={ vehicles_prepared }
          totalItems={ vehicles && vehicles.total ? vehicles.total : 0 }
          columns={ vehicle_columns }
          onFetchData={ (options) => fetchVehicles({
            rowsPerPage: options.rowsPerPage,
            currentpage: options.currentpage,
            sort: options.sort,
            searchStr: options.searchStr,
            tag: options.tag,
            requireTelematics: requireTelematicsUnit
          }) }
          fetchAgainOnChange={ requireTelematicsUnit }
          onClickRow={ handleClick }
          searchStr={ filter }
          sidescroll
          themes={ themes }
        />
      </Page>
    </View>
  );
};

export const styles = {
  switchWrapper:{
    "& p": {
        padding: 8,
        marginLeft: 10,
        marginTop: 30,
        marginBottom: 15,
        display: 'flex',
        alignSelf: 'flex-end',
        justifyContent: 'space-between',
        '> .MuiSwitch-root': {
            marginTop: -10,
        },
    }
  }
};

const mapStateToProps = ({
  vehicles,
  themes,
  loading
}) => ({
  vehicles,
  themes,
  loading
})

const mapDispatchToProps = dispatch => ({
  fetchVehicles: (options) => dispatch(fetchVehicles(options))
})

export default connect(mapStateToProps, mapDispatchToProps)(Vehicles);
