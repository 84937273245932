export const fetchUsers = options => (
  {
    type: 'FETCH_USERS',
    rowsPerPage: options.rowsPerPage || 10,
    page: options.currentpage || 1,
    sort: options.sort,
    searchStr: options.searchStr
  }
)

export const fetchCustomerUsers = options => (
  {
    type: 'FETCH_CUSTOMER_USERS',
    rowsPerPage: options.rowsPerPage || 10,
    page: options.currentpage || 1,
    sort: options.sort,
    searchStr: options.searchStr,
    tag: options.tag || ''
  }
)

export const fetchWorkshopUsers = options => (
  {
    type: 'FETCH_WORKSHOP_USERS',
    rowsPerPage: options.rowsPerPage || 10,
    page: options.currentpage || 1,
    sort: options.sort,
    searchStr: options.searchStr,
    tag: options.tag || ''
  }
)

export const fetchUsersRegistered = () => (
  {
    type: 'FETCH_USERS_REGISTERED'
  }
)