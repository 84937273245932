import { dateStringToDate, dateTimeToString } from '@modul-connect/shared/utils/dateTimeUtils.ts'
import { DateTime } from 'luxon'

export const getEventDescription = (event) => {
    switch (event.eventName) {
        case "BoxRegisterdEvent":
            return {
                name: "Box Registered",
                timestamp: getEventTimestamp(event),
                info: '-'
            }
        case "BoxResetedEvent":
            return {
                name: "Box Reset",
                timestamp: getEventTimestamp(event),
                info: '-'
            }
        case "ConfigUpdatedEvent":
            return {
                name: "Configuration Updated",
                timestamp: getEventTimestamp(event),
                info: 'Accessory: ' + (event.parameters['Accessory'] ? event.parameters['Accessory'] : 'none')
            }
        case "SinkRemovedEvent":
            return {
                name: "Sink Removed",
                timestamp: getEventTimestamp(event),
                info: 'Output: ' + event.parameters.Output
            }
        case "VehicleChangedEvent":
            return {
                name: "Vehicle Changed",
                timestamp: getEventTimestamp(event),
                info: [
                    'VIN: ' + event.parameters['Vin'],
                    'BrandName: ' + event.parameters['BrandName'],
                    'ModelName: ' + event.parameters['ModelName'],
                    'Year: ' + event.parameters['Year']].join(', ')
            }
        case "UserFirmwareUpgradeAllowedEvent":
            return {
                name: "Firmware Upgrade Allowed",
                timestamp: getEventTimestamp(event),
                info: 'Until: ' + event.parameters['AllowedUntil']
            }
        case "FirmwareCreatedEvent": 
            return {
                name: "Firmware Created",
                timestamp: getEventTimestamp(event),
                info: [
                    'MCU: ' + (event.parameters['mcu'] ? event.parameters['mcu'] : 'none'),
                    'TCU: ' + (event.parameters['tcu'] ? event.parameters['tcu'] : 'none'),
                    'BLE: ' + (event.parameters['ble'] ? event.parameters['ble'] : 'none'),
                    'BOOT: ' + (event.parameters['boot'] ? event.parameters['boot'] : 'none')
                ].join(', ')
            }
        case "FirmwareUpdatedEvent": 
            return {
                name: "Firmware Updated",
                timestamp: getEventTimestamp(event),
                info: [
                    'MCU: ' + (event.parameters['mcu'] ? event.parameters['mcu'] : 'none'),
                    'TCU: ' + (event.parameters['tcu'] ? event.parameters['tcu'] : 'none'),
                    'BLE: ' + (event.parameters['ble'] ? event.parameters['ble'] : 'none'),
                    'BOOT: ' + (event.parameters['boot'] ? event.parameters['boot'] : 'none')
                ].join(', ')
            }
        case "ManufactureDataAddedEvent":
            return {
                name: "Manufacture Data Added",
                timestamp: getEventTimestamp(event),
                info: 
                    'Type: ' + event.parameters.Type + ', ' +
                    'BoxId: ' + event.parameters.BoxId + ', ' +
                    'SerialNumber: ' + event.parameters.SerialNumber + ', ' +
                    'AssemblyNumber: ' + event.parameters.AssemblyNumber + ', ' +
                    'ManufacturerDate: ' + dateStringToDate(event.parameters.ManufacturerDate).format('MMMM Do YYYY, h:mm:ss a') + ', ' +
                    'Revision: ' + event.parameters.Revision
            }
        case "HideEvent": 
            return {
                name: "Box Hidden",
                timestamp: getEventTimestamp(event),
                info: '-'
            }
        case "TelematicsDataAddedEvent":
            return {
                name: "Telematics Data Added",
                timestamp: getEventTimestamp(event),
                info: [
                    'BoxId: ' + event.parameters.BoxId,
                    'TelematicsId: ' + event.parameters.TelematicsId,
                    'AssemblyNumber: ' + event.parameters.AssemblyNumber,
                    'SerialNumber: ' + event.parameters.SerialNumber,
                    'ManufacturerDate: ' + event.parameters.ManufacturerDate,
                    'Revision: ' + event.parameters.Revision,
                ].join(', ')
            }
        case "TelematicsUnitHostUpdatedEvent":
            return {
                name: "Telematics Unit Host Updated",
                timestamp: getEventTimestamp(event),
                info: 
                    'TelematicsId: ' + event.parameters.TelematicsId + ', ' +
                    'New boxId: ' + event.parameters.NewBoxId
            }
        case "VehicleConnectedToCustomerEvent":
            return {
                name: "Connected To Customer",
                timestamp: getEventTimestamp(event),
                info: 'Organisations: ' + (event.parameters.Organisations ? event.parameters.Organisations : 'none')
            }
        case "VehicleRemovedFromCustomerEvent":
            return {
                name: "Removed From Customer",
                timestamp: getEventTimestamp(event),
                info: 'Organisations: ' + (event.parameters.Organisations ? event.parameters.Organisations : 'none')
            }
        default:
            return {
                name: '-',
                timestamp: '-',
                info: '-'
            }
    }
    return null
}

export const getEventTimestamp = (event) => {
    return dateTimeToString(DateTime.fromISO(event.timestamp))
}