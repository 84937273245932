export const fetchVehicleConfig = (id) => (
  {
    type: 'FETCH_VEHICLE_CONFIG',
    id
  }
)

export const fetchVehicleHistory = (options, id) => (
  {
    type: 'FETCH_VEHICLE_HISTORY',
    rowsPerPage: options.rowsPerPage || 10,
    page: options.currentpage || 1,
    id
  }
)

export const fetchVehicleTextRecords = (options, id) => (
  {
    type: 'FETCH_VEHICLE_TEXT_RECORDS',
    desc: options.desc ?? false,
    rowsPerPage: options.rowsPerPage || 10,
    page: options.currentpage || 1,
    id
  }
)

export const fetchVehicle = (id) => (
  {
    type: 'FETCH_VEHICLE',
    id
  }
)

export const fetchFirmwareReport = (options) => ({
  type: 'FETCH_FIRMWARES_REPORT',
  tag: options.tag || ''
})

export const fetchVehicles = (options) => (
  {
    type: 'FETCH_VEHICLES',
    rowsPerPage: options.rowsPerPage || 10,
    page: options.currentpage || 1,
    sort: options.sort,
    searchStr: options.searchStr,
    tag: options.tag || '',
    requireTelematics: options.requireTelematics || false,
    notTag: options.notTag || '',
    workshop: options.workshop || '',
    country: options.country || ''
  }
)

export const fetchVehiclesByFirmware = (options) => (
  {
    type: 'FETCH_VEHICLES_BY_FIRMWARE',
    rowsPerPage: options.rowsPerPage || 10,
    page: options.currentpage || 1,
    sort: options.sort,
    searchStr: options.searchStr,
    tag: options.tag || ''
  }
)

export const fetchCustomerVehicles = (options) => (
  {
    type: 'FETCH_CUSTOMER_VEHICLES',
    rowsPerPage: options.rowsPerPage || 10,
    page: options.currentpage || 1,
    sort: options.sort,
    searchStr: options.searchStr || '',
    tag: options.tag || ''
  }
)

export const fetchTemplateVehicles = (options) => (
  {
    ...options,
    type: 'FETCH_TEMPLATE_VEHICLES',
    rowsPerPage: options.rowsPerPage || 10,
    page: options.currentpage || 1,
    sort: options.sort,
    searchStr: options.searchStr || null,
    tag: options.tag || null
  }
)

export const fetchBoxesRegistered = () => (
  {
    type: 'FETCH_BOXES_REGISTERED'
  }
)

export const allowUserFirmwareUpgrade = (untilDate, mainboxId, forbid) => (
  {
    type: 'ALLOW_USER_FIRMWARE_UPGRADE',
    untilDate,
    mainboxId,
    forbid
  }
)

export const fetchUpgradePermission = (mainboxId) => (
  {
    type: 'FETCH_UPGRADE_PERMISSION',
    mainboxId
  }
)

export const fetchDisconnectedBoxes = () => (
  {
    type: 'FETCH_DISCONNECTED_BOXES',
  }
)

export const fetchDisconnectedHistory = telematicsId => (
  {
    type: 'FETCH_DISCONNECTED_STATUS_HISTORY',
    telematicsId
  }
)