export enum ServiceType {
  DrivingLogs = 'Driving Logs',
  DrivingLogsWithStartWeight = 'Driving Logs including start weight',
  BatteryWarnings = 'Battery Warnings',
  WeightReports = 'Weight Reports',
  TollPassages = 'Toll Passages',
  ClimateReports = 'Climate Reports',
  mapAccess =  'Map Access',
  sideBarAcces = 'Sidebar Access',
  AssetTrackers = 'Asset Tracking',
  LpsService = 'Lps Reports',
  WeightReportsNoGeo = "Weight Reports without Geo location",
  mapAccessNoGeo = "Map Access without Geo location",
  MaintenanceAccess = "Maintenance Access",
  SecuritySystemReports = "Security System Reports",
  CriticalAlerts = "Critical Alerts",
  LockReports = "Lock Reports"
}