import React, { Fragment, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Helmet } from 'react-helmet';
import View from "@modul-connect/shared/components/atoms/view";
import Login from "./pages/login";
import { connect } from 'react-redux'
import NavBar from "@modul-connect/shared/components/organisms/nav-bar";
import ModalAjaxError from "@modul-connect/shared/components/organisms/modal-ajax-error";
import { dismissAjaxError } from "./state/actions/app"
import routes from './routes';
import { logged_in } from "./state/actions/user"
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { sec } from "@modul-connect/shared/auth0/auth0Helper"
import theme from "@modul-connect/shared/theme";
import { P, H3, H1, H4 } from '@modul-connect/shared/components/atoms/text';
import './index.css'
import { auth0_params } from "@modul-connect/shared/config";
import ModalError from "@modul-connect/shared/components/organisms/errorModal";
import { ThreeDots } from "react-loader-spinner";


const App = ({is_logged_in, logged_in, themes, saving, dismissAjaxError, role}) => {
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  useEffect(() => {
    if (saving.moveUser === 'failed') {
      setErrorMessage('There was an error trying to update the user.')
      setError(true)
    }
    else {
      setError(false)
      setErrorMessage("")
    }
  }, [saving.moveUser])

  const {
    isAuthenticated,
    isLoading,
    user,
    getAccessTokenSilently,
    logout
  } = useAuth0();

  const auth0 = useAuth0()


  React.useEffect(() => {
    const fetchAccessToken = () => {
    if (isLoading) return
    if (isAuthenticated) {
      sec.setLogOut(logout)
      sec.setAccessTokenSilently(getAccessTokenSilently)
      if (auth0.user) {
        if (!is_logged_in)
          logged_in() 
        return
      }
    }
  }
    fetchAccessToken()  
  }, [auth0, isLoading])


  return (
    <Router>
      <Helmet>
        <title>Modul-Connect backoffice</title>
      </Helmet>
      {is_logged_in && is_logged_in !== 'LOGGING_OUT' ? (
        <View extend={styles.root}>
          <Fragment>
            <NavBar routes={routes} authTool='auth0' username={ user && user.name } themes={ themes }></NavBar>
            <View extend={[styles.main, themes.device === 'tablet' && styles.main_tablet, themes.device === 'mobile' && styles.main_mobile]}>
              {/* <SideBar routes={routes} /> */}
              <View extend={styles.content}>
                <Routes>
                {
                  routes.map(route => (
                    <Route path={route.url} element={<route.component />} key={route.url} />
                  ))
                }
                </Routes>
              </View>
            </View> 
            <ModalAjaxError
              openModal={ saving && saving.ajaxError }
              onClose={ dismissAjaxError }
            />
            <ModalError
              openModal={error}
              onClose={() => {setError(false); setErrorMessage("")}}
              text={errorMessage}
            />
          </Fragment>
        </View>
      ) : (
        <View extend={styles.loaderContainer}>
            <ThreeDots
              color={ theme.colors.primary }
              height={30}
              width={30}
            />
            { user ? null :
              <H3>Couldn't find any backoffice account. You will be redirected to the login page...</H3>
            }
        </View> 
     )}
    </Router>
  )
}

const mapStateToProps = ({
  is_logged_in,
  themes,
  saving,
  role
}) => ({
  is_logged_in,
  themes,
  saving,
  role
})

const mapDispatchToProps = dispatch => ({
  logged_in: () => dispatch(logged_in()),
  dismissAjaxError: () => dispatch(dismissAjaxError()),
})

const styles = {
  root: ({ theme: { colors } }) => ({
    position: "relative",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    minHeight: '100vh',
    display: "flex",
    flexDirection: "column",
    backgroundColor: colors.bg,
  }),
  main: ({ theme: { colors, layout } }) => ({
    display: "flex",
    flexDirection: "row",
    alignSelf: 'center',
    width: '100%',
    marginTop: layout.navBarHeight,
    paddingBottom: layout.navBarHeight,
    backgroundColor: colors.bg,
  }),
  main_tablet: ({ theme: { colors, layout } }) => ({
    marginTop: layout.navBarHeight_tablet
  }),
  main_mobile: ({ theme: { colors, layout } }) => ({
    marginTop: layout.navBarHeight_mobile
  }),
  content: ({ theme: { colors, layout } }) => ({
    width: '100%',
    overflowY: 'hidden',
    margin: '0px 20px',
    borderRadius:  `${ 1 * layout.grid}px`
  }),
  loaderContainer: ({ theme: { colors, layout } }) => ({
    height: "70vh",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  })
};


export default connect(mapStateToProps, mapDispatchToProps) (withAuthenticationRequired(
  App,
  { onRedirecting: () =>
      <View extend={styles.loaderContainer}>
        <ThreeDots
              color={ theme.colors.primary }
              height={30}
              width={30}
        />
          <H3></H3>
     </View>
 }))
