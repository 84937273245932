import React from "react";
import View from '@modul-connect/shared/components/atoms/view';
import Page from "@modul-connect/shared/components/atoms/page";
import PaddedPieChart from "@modul-connect/shared/components/molecules/pieCharts/paddedpiechart";
import { Fragment } from "react";
import { P } from "@modul-connect/shared/components/atoms/text";
import { BubbleLoadingWidget } from "@modul-connect/shared/components/molecules/bubbleLoadingWidget/bubbleLoadingWIdget";

const toPieData = firmware => firmware ? Object.entries(firmware)
  .map(e => ({name: e[0], value: e[1]}))
  .sort((e1, e2) => {
    return e1.name < e2.name ? 1 : -1
  }) : []

const FirmwareCharts = ({
  firmwares_report,
  filter,
  onSelect,
  onDeselect,
  loadingStatus
}) => {
  return (
    <Fragment>
      <Page title={'Installed Firmware Versions'}>
        {
          loadingStatus === 'loading' ? <BubbleLoadingWidget text={'Loading ... '} />
            :
            <Fragment>
              <P style={{ paddingLeft: 16 }}>
                In the charts below you can see how many of the vehicles have each firmware version installed.
              </P>
              <View extend={styles.pies}>
                {firmwares_report &&
                  Object.entries(firmwares_report)
                    .filter(x => x[0] !== 'boot')
                    .sort((a, b) => a[0].localeCompare(b[0]))
                    .map(([key, value]) =>
                      <PaddedPieChart
                        minWidth={300}
                        padding={50}
                        key={key}
                        title={key.toUpperCase()}
                        data={toPieData(value)}
                        onSelect={(_, data) => { onSelect({ firmware: key, version: data.name }) }}
                        onDeselect={onDeselect}
                        deselectOn={filter && filter.firmware != key}
                      />
                    )
                }
              </View>
              <P style={{ paddingLeft: 16 }}>
                Tip! Click the sections to filter the table below by firmware version.
              </P>
            </Fragment>
        }
      </Page>
    </Fragment>
  );
};

const styles = {
  pies: {
    display: 'inline-flex',
    padding: 'inherit',
    height: '400px'
  }
}

export default FirmwareCharts;
