export const is_logged_in = (state = false, action) => {
    switch (action.type) {
      case 'LOGGED_IN':
        return 'LOGGED_IN';
      case 'LOGGING_OUT':
        return 'LOGGED_IN';
      case 'LOGGED_OUT':
        return 'LOGGED_IN';
      default:
        return state;
    }
}

export const role = (state = 0, action) => {
  switch(action.type) {
    case 'USER_ROLE_FETCHED':
      return action.data.role || state
    case 'LOGGED_OUT':  
      return 0
    default:
      return state
  }
}