import Switch from '@mui/material/Switch';
import { ThemeProvider } from '@mui/material/styles';
import { H3, A, P } from '@modul-connect/shared/components/atoms/text'
import View from '@modul-connect/shared/components/atoms/view'
import theme from '@modul-connect/shared/theme';
import React from 'react'
import { firmware_products, getFirmwareTypeName } from '../../../../../../utils/firmwares'
import { FirmwareDisplay } from '../../firmwares/firmwareDisplay'
import moment from "moment"
import { CircularProgress } from '@mui/material';

export const MainboxFirmwareInfo = ({
  padding_style,
  onUpgradeBtnClick,
  firmware_update_progress,
  vehicle,
  firmwares,
  allowUserFirmwareUpgrade,
  fetchIsUpgrading
}) => {

  React.useEffect(() => {
    if (!vehicle.telematicsData) return
    const intervalId = setInterval(() => {
      fetchIsUpgrading()
    }, 3000)
    return (() => clearInterval(intervalId))
  }, [fetchIsUpgrading])

  const disableUpgrade = (firmware_update_progress === true) || (firmware_update_progress === null) // if either upgrading or we don't know the state

  return (
    <View extend={ padding_style }>
      <H3>Firmwares</H3>
      { vehicle.telematicsData &&
        <A
          extend={disableUpgrade && {color: 'lightgrey'}}
          onClick={() => {
            if (disableUpgrade)
              return

            onUpgradeBtnClick(null, null)
          }}>
          {firmware_update_progress === null || firmware_update_progress.fw_upgrade_in_progress === false ? 
            'Remote upgrade' : 'Currently upgrading'}
        </A>
      }
      { vehicle.telematicsData && firmware_update_progress?.fw_upgrade_in_progress &&
        <React.Fragment>
          <CircularProgress
            value={firmware_update_progress.fw_transfer_progress * 100}
            variant={firmware_update_progress.fw_transfer_progress < 1 ? 'determinate' : 'indeterminate'}
            style={styles}
          />
          <P extend={{transform: 'translate(50px, -50px)'}}>
            {(firmware_update_progress.fw_transfer_progress * 100).toFixed(0)}%
          </P>
        </React.Fragment>
      }
            
      <View>
        {
          vehicle.firmware && vehicle.firmware.map(fw => {
            return (
              <FirmwareDisplay
                key={ fw.name }
                firmware_update_in_progress={ firmware_update_progress ? (firmware_update_progress?.fw_upgrade_in_progress ?? false) : null }
                firmware={ fw }
                firmwares={ firmwares }
                noUpgradeBtn={ !vehicle?.telematicsData || fw.name === 'boot' }
                onUpgradeClick={ () => {
                  onUpgradeBtnClick(firmware_products.mainbox, getFirmwareTypeName(fw.name) ?? 'none')
                }}/>
            )
          })
        }
      </View>
      
      <View extend={ styles.switchWrapper }>
        <P strong>
          Enable enduser to upgrade
          <ThemeProvider theme={ theme.mui }>
            <Switch
              checked={ vehicle.permission }
              onChange={() => {
                const hoursUpgradeAllowed = 12;
                var timestampNow = Math.round((new Date()).getTime() / 1000);

                let allowUntilTimestamp = timestampNow + (hoursUpgradeAllowed * 60 * 60);

                vehicle.permission ?
                  allowUserFirmwareUpgrade(timestampNow, vehicle.mainbox_id, true) : // no more upgrading allowed
                  allowUserFirmwareUpgrade(allowUntilTimestamp, vehicle.mainbox_id);
              }}
              color={ "primary" }
              name="checkedA"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </ThemeProvider>
        </P>
      </View>
      
      <P extend={styles.upgradeStatus} noPadding>
        {
          vehicle.permission ?
            'Enduser can upgrade the box until: ' + moment(vehicle.allowedUntil).calendar() :
            'Enduser cannot upgrade the mainbox.'
        }
      </P>
    </View>
  )
}

const styles = {
  switchWrapper:{
      "& p": {
          marginTop: 30,
          marginBottom: 0,
          display: 'flex',
          justifyContent: 'space-between',
          '> .MuiSwitch-root': {
              marginTop: -10,
          },
      }
  },
  upgradeStatus: {
    width: '60%'
  }
};