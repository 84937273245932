import React, { useEffect } from 'react'
import { ArrowCircleDownOutlined, ArrowCircleUpOutlined } from '@mui/icons-material';
import TableWidget from "@modul-connect/shared/components/molecules/tableWidget";
import { connect } from 'react-redux'
import { DateTime } from 'luxon';
import { dateTimeToString } from '@modul-connect/shared/utils/dateTimeUtils';
import connectionColumns from '../ConnectionHistoryColumns';
import { themes } from '../../../../../state/reducers/themes';
import { P } from '@modul-connect/shared/components/atoms/text';
import { BubbleLoadingWidget } from '@modul-connect/shared/components/molecules/bubbleLoadingWidget/bubbleLoadingWIdget';
import { fetchDisconnectedHistory } from '../../../../../state/actions/vehicles';


const StatusIndicator = ({connected}) => {
  return (
    connected === 'Disconnected' 
    ?
    <ArrowCircleDownOutlined style={{color: 'red'}} />
    : 
    <ArrowCircleUpOutlined style={{color: 'green'}} />
  )
}
const toReadableDuration = (from, to) => {

  const difference = from.diff(to)

  if (difference.as('days') > 1) return difference.as('days').toFixed(0) + " days"
  if (difference.as('hours') < 1) return difference.as('minutes').toFixed(0) + " min"

  return difference.as('hours').toFixed(0) + " hrs"
}

const HistoricalConnectionStatus = ({ disconnected_box_history, loadingConnectionStatus, vehicle, fetchDisconnectedHistory }) => {

  const now = DateTime.now()
  const isLoading = loadingConnectionStatus.fetchDisconnectedBoxesHistory === 'loading'
  const loadingFailed = loadingConnectionStatus.fetchDisconnectedBoxesHistory === 'failed'

  useEffect(() => {
    if (vehicle?.telematicsData) {
      console.log("vehicle?.telematicsData:", vehicle?.telematicsData)
      fetchDisconnectedHistory(vehicle?.telematicsData?.telematicsId)
    }
  },[])


  const connectionData = disconnected_box_history?.history?.map(data => ({
    connection: data.state === 'Disconnected' ? 'Down' : 'Up',
    from: data.state === 'Disconnected' ?  
    data.disconnectedAt ? dateTimeToString(
      DateTime.fromISO(data.disconnectedAt),
      "date-and-time"
    ) : '-' 
    :
     data.connectedAt ? dateTimeToString(
      DateTime.fromISO(data.connectedAt),
      "date-and-time"
    )  : '-',

    to: data.state === 'Disconnected' ?  
    data.connectedAt ? dateTimeToString(
      DateTime.fromISO(data.connectedAt),
      "date-and-time"
    ) : '-' 
    :
     data.disconnectedAt ? dateTimeToString(
      DateTime.fromISO(data.disconnectedAt),
      "date-and-time"
    )  : '-',

    status: <StatusIndicator connected={data.state}/> ,

    duration: data.connectedAt !== null && data.disconnectedAt !== null ?  
    data.state === 'Connected' ?
     toReadableDuration( DateTime.fromISO(data.disconnectedAt),  DateTime.fromISO(data.connectedAt))
     :
     toReadableDuration( DateTime.fromISO(data.connectedAt),  DateTime.fromISO(data.disconnectedAt))
     :
     data.state === 'Connected' ? 'Up since ' + toReadableDuration( DateTime.fromISO(now),  DateTime.fromISO(data.connectedAt))
     : 'Down since ' + toReadableDuration( DateTime.fromISO(now),  DateTime.fromISO(data.disconnectedAt))
  })).sort((a,b) => a?.disconnectedAt > b?.disconnectedAt ? -1 : 1);

  return (
    isLoading ? <BubbleLoadingWidget text={ "Loading connection history ... " }/> : loadingFailed ? <P>{'Loading failed.'}</P> :
    <TableWidget
    data={connectionData}
    columns={connectionColumns}
    themes={themes}
    totalItems={connectionData?.length}
    />

  );
};

const mapStateToProps = ({
  loadingConnectionStatus,
  disconnected_box_history,
  vehicle
}) => ({
  loadingConnectionStatus,
  disconnected_box_history,
  vehicle
});

const mapDispatchToProps = dispatch => ({
  fetchDisconnectedHistory: telematicsId => dispatch(fetchDisconnectedHistory(telematicsId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(HistoricalConnectionStatus);