const firmware_columns = [
    {
      id: 'vin',
      label: 'Vin',
      maxWidth: 170,
      hide: ['tablet', 'mobile']
    },
    {
      id: 'serialId',
      label: 'Serial ID',
      maxWidth: 170,
      minWidth: 120,
      hide: []
    },
    {
      id: 'telematicsUnit',
      label: 'Telematics',
      minWidth: 130,
      hide: ['mobile', 'tablet']
    },
    {
      id: "model",
      label: 'Model',
      minWidth: 170,
      minWidth: 120,
      align: 'left',
      format: value => value.toFixed(2),
      hide: []
    },
    {
      id: 'country',
      label: 'Country',
      minWidth: 120,
      hide: ['tablet', 'mobile']
    },
    {
      id: 'workshop',
      label: 'Workshop',
      minWidth: 170,
      align: 'left',
      format: value => value.toLocaleString(),
      hide: ['mobile']
    },
    {
      id: "order_number",
      label: 'Order Nr',
      minWidth: 120,
      align: 'left',
      format: value => value.toLocaleString(),
      hide: ['tablet', 'mobile']
    },
    {
      id: "created_date",
      label: 'Registered',
      minWidth: 130,
      align: 'left',
      format: value => value.toLocaleString(),
      hide: ['mobile']
    },
];

export default firmware_columns;