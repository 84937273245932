import { regionNoCountry } from "../../utils/global"

export const groupedCustomers = (state = {data: [], total: 0}, action) => {
  switch (action.type) {
    case 'CUSTOMERS_BY_REGION_FETCHED':
      return  {
        data: action.data,
        total: action.total
      }
    default:
      return state
  }
}


export const customers = (state = {data: [], total: 0}, action) => {
  switch (action.type) {
    case 'CUSTOMERS_FETCHED':
      return {
        data: action.data,
        total: action.total
      }
    case 'CUSTOMER_REMOVED':
      return {
        ...state,
        data: state.data.filter(x => x.id !== 'organisation/' + action.data.id),
        total: state.total - 1
      }
    case 'CUSTOMER_ADDED':
      state.data.push({ 
        id: 'organisation/' + action.data.id,
        name: action.data.name,
        numberOfUsers: 0,
        numberOfVehicles: 0,
        customerId: undefined // will be gotten on next fetch, which happens right after - TODO: should ideally be returned by the server when it confirms that customer was added
      })
      state.data.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
      return {
        ...state,
        data: state.data,
        total: state.total + 1
      }
    case 'CUSTOMER_EDITED': 
      const stateDataToUpdate = state?.data ? [...state?.data] : []
      const index = stateDataToUpdate?.findIndex(x => x.id === 'organisation/' + action.data.id)
      if (index >= 0) {
        const regionWasUpdated = action.data.countryCode || action.data.countryCode === regionNoCountry.value
        if (regionWasUpdated) stateDataToUpdate[index].region = action.data.countryCode
        stateDataToUpdate[index].name = action.data.newName
      }
      return {
        ...state,
        data: stateDataToUpdate
      }
    default:
      return state;
  }
}

export const customer = (state = { 
  users: [], users_total: 0,
  vehicles: [], vehicles_total: 0 ,
  added_users: [],
}, action) => {
  switch (action.type) {
    case 'FETCH_CUSTOMER':
      return {
        ...state,
        added_users: []
      }
    case 'CUSTOMER_FETCHED':
      return {
        ...action.data,
        users: state.users,
        users_total: state.users_total,
        vehicles: state.vehicles,
        vehicles_total: state.vehicles_total,
      }
    case 'CUSTOMER_VEHICLES_FETCHED':
      return {
        ...state,
        vehicles: action.data,
        vehicles_total: action.total
      }
    case 'VEHICLE_ADDED_TO_CUSTOMER':
      return {
        ...state,
        vehicles: [
          action.data,
          ...state.vehicles,
        ],
        vehicles_total: state.vehicles_total + 1
      }
    case 'VEHICLE_REMOVED_FROM_CUSTOMER':
      return {
        ...state,
        vehicles: state.vehicles.filter(x => x.mainbox_id !== action.data.vehicleId),
        vehicles_total: state.vehicles_total - 1
      }
    case 'CUSTOMER_USERS_FETCHED':
      return {
        ...state,
        users: action.data.users,
        users_total: action.data.number,
      }
     case 'USER_ADDED':
      if (action.organisation === state.customerId) {
        let new_added_users = state.added_users ?? []
        new_added_users.push({
          firstName: action.data.firstName,
          lastName: action.data.lastName,
          email: action.data.email,
          created: new Date().toISOString()
        })
        return {
          ...state,
          added_users: new_added_users
        }
      }
      else return state 
    case 'USER_REMOVED':
      if (action.tag === state.customerId) {
        return {
          ...state,
          users: action.data.users.filter(x => x.email !== action.email),
          users_total: state.users_total - 1
        }}
      else return state
    case 'USER_PRIVILEGE_UPDATED':
    case 'USER_MOVED':
      const userIndex = state?.users?.findIndex(u => u.email === action.data.email)
      if (userIndex < 0) return state
      const newUsers = [...state?.users]
      newUsers[userIndex] = {
        ...newUsers[userIndex],
        role: action.data.privilege
      }
      
      return {
        ...state,
        users: newUsers
      }
    case 'ADD_CUSTOMER':
      const {
        customerName, customerId
      } = action

      let customer_new = {
        customerId: customerId,
        name: customerName,
        tree: {
          children: null,
          name: customerName,
          uuid: customerId
        },
        services: []
      }

      return customer_new
    case 'REMOVE_USER':
      return {
        ...state,
        users_total: state.users_total - 1,
        users: state.users.filter(x => x.email !== action.email)
      }
    case 'COMPANY_DETAILS_ADDED':
      return {
        ...state,
        company: {
          ...state.company,
          companyName: action.data.companyName,
          vatNumber: action.data.vatNumber,
          organisationNumber: action.data.organisationNumber
        }
      }
    case 'CUSTOMER_ADDRESS_ADDED':
      return {
        ...state,
        company: {
          ...state.company,
          organisationAddress: action.data
        }
      }

    case 'BILLING_ADDRESS_ADDED':
      return {
        ...state,
        company: {
          ...state.company,
          billingAddress: action.data
        }
      }
    case 'MODIFY_SERVICES':
      let services = state.services
      action.services.forEach(element => {
        if (!state.services.includes(element)) 
          services.push(element)
        else
          services = services.filter(s => s !== element)
      });
      return{
        ...state,
        services: services
      }
    case 'MODIFYING_SERVICES_SUCCESSFUL':
      return state
    case 'MODIFYING_SERVICES_FAILED':
      return {
        ...state,
        services: action.previousState.presviousServices
      }
    default:
      return state;
  }
}

export const user = (state = {}, action) => {
  switch (action.type) {
    case 'FETCHED_USER':
      return action.data
    case 'USER_PRIVILEGE_UPDATED':
    if (state.email !== action.data.email)
      return state
    const privTag = state.user_metadata.info.tags.find(x => (x.tags === action.data.organisation) || ('organisation/' + x.tags === action.data.organisation))
        const privBackupMetaData = {...state.user_metadata}
    if (!privTag) return state
    return {
      ...state,
      user_metadata_backup: privBackupMetaData,
      user_metadata: {
        ...state.user_metadata,
        info: {
          ...state.user_metadata.info,
          tags: [
            ...state.user_metadata.info.tags.filter(x => (x.tags !== action.data.organisation) && ('organisation/' + x.tags !== action.data.organisation)),
            {tags: privTag.tags, privilege: action.data.privilege}
          ]
        }
      }
    }
    case 'MOVE_USER':
      if (state.email !== action.email)
        return state
      const tag = state.user_metadata.info.tags.find(x => (x.tags === action.organisation) || ('organisation/' + x.tags === action.organisation))
      if (!tag) return state
      const backupMetaData = {...state.user_metadata}
      return {
        ...state,
        user_metadata_backup: backupMetaData,
        user_metadata: {
          ...state.user_metadata,
          info: {
            ...state.user_metadata.info,
            tags: [
              ...state.user_metadata.info.tags.filter(x => (x.tags !== action.organisation) && ('organisation/' + x.tags !== action.organisation)),
              {tags: tag.tags, privilege: action.privilege}
            ]
          }
        }
      }
      case 'MOVING_USER_FAILED':
      if (state.email !== action.email) return state
      const backupMetaDataToPutBack = state.user_metadata_backup
      return {
        ...state,
        user_metadata_backup: null,
        user_metadata: backupMetaDataToPutBack
      }
    default:
      return state
  }
}