import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { rootSaga } from './sagas'
import { createLogger } from 'redux-logger'
import rootReducer from './reducers'
import { persistStore } from 'redux-persist'

const logger = createLogger({
    collapsed: true
})

const sagaMiddleware = createSagaMiddleware()

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const createProdStore = initialState => (
    createStore(
        rootReducer,
        initialState,
        composeEnhancers(
            applyMiddleware(
                sagaMiddleware
            )
        )
    )
)

const createDevStore = initialState => (
    createStore(
        rootReducer,
        initialState,
        composeEnhancers(
            applyMiddleware(
                logger,
                sagaMiddleware
            )
        )
    )
)

const configureStore = (initialState) => {
    const store = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ?
        createDevStore(initialState) :
        createProdStore(initialState)

    sagaMiddleware.run(rootSaga)
    let persistor = persistStore(store)

    return { store, persistor }
}

export default configureStore