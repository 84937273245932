import React, { useState } from "react";
import { Modal } from "@mui/material";
import Fade from '@mui/material/Fade';
import {parseImage, buf2hex, firmwareInfo} from '../../../../utils/file_processor';
import View from '@modul-connect/shared/components/atoms/view'
import { H1, H3, P } from '@modul-connect/shared/components/atoms/text'
import Button from '@modul-connect/shared/components/atoms/button';
import modal from '@modul-connect/shared/components/atoms/modal'
import Input from '@modul-connect/shared/components/atoms/input';

const ModalAddFirmware = ({
    openModal,
    onClose,
    setFirmwareExist,
    setFileDetails,
    fileDetails,
    firmwares,
    file,
    setFile,
    uploadFirmware
}) => {

  const [err, setErr] = useState(null)
  const [isUploading, setIsUploading] = useState(null)

  const onFileChange = event => {
      const selectedFile = event.target.files[0]

      const getData = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();

          reader.onload = (event) => {
              resolve(event.target.result);
          };

          reader.onerror = (err) => {
              reject(err);
          };

          reader.readAsArrayBuffer(file);
        });
      }

      getData(selectedFile)
      .then((data) => {
        const image = parseImage(buf2hex(data))
        const firmware = firmwareInfo(image.uuid)

        if (!firmware){
          setErr({text: 'This file is not an acceptable firmware file'})
          setFileDetails(null)
        }
        setFile(selectedFile)
        setErr(null)
        setFileDetails({
          fileName: selectedFile.name,
          version: image.version,
          uuid: image.uuid,
          type: firmware.type,
          app: firmware.app
        })
      })
      .catch(() => {
        setErr({text: 'Failed to read the file'})
        setFileDetails(null)
      })
    };

    const onFileUpload = () => {
      const checkExistingFirmware = (firmware) => {
        return firmwares && firmwares.all && firmwares.all.find(x => x.product === firmware.app && x.type === firmware.type && x.version === firmware.version)
      }

      const existingFirmware = checkExistingFirmware(fileDetails)

      if (existingFirmware) {
        setFirmwareExist(true)
        setIsUploading(false)
        onClose()
        return
      }

      const formData = new FormData();
      formData.append(
        "bluetooth",
        file,
      );
      uploadFirmware(formData)
      setIsUploading(true)
    };

    React.useEffect(() => {
      // console.warn(firmwares)
      if (firmwares.result && firmwares.result){
        onClose()
        setIsUploading(false)
      }
      // else if (firmwares.result && firmwares.result === 'conflict'){
      //     setIsUploading(false)
      //     setErr('Conflict adding firmware')
      // }
    }, [firmwares, onClose]);

    return (
        <Modal
            open={ openModal }
            onClose={(event, reason) => {
                if (!isUploading || reason !== 'backdropClick') {
                  onClose(event, reason);
                }
              }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <Fade in={ openModal }>
                <div >
                    <View extend={ modal.container_medium }>
                        <View extend={ modal.header }>
                            <H1 text={"Add Firmware"}/>
                        </View>
                        <View extend={ modal.content }>
                          <H3 noPadding>Choose a file to upload</H3>
                          <Input
                            type="file"
                            onChange={(e) => onFileChange(e)}
                            disabled={ isUploading }
                          />
                          {(fileDetails && (
                            <View>
                              <H3>Firmware details</H3>
                              <P>{'Product: ' + fileDetails.app}</P>
                              <P>{'Type: ' + fileDetails.type}</P>
                              <P>{'UUID: ' + fileDetails.uuid}</P>
                              <P>{'Version: ' + fileDetails.version}</P>
                            </View>
                            )) || (err && (
                            <View>
                              <P highlight>{err.text}</P>
                            </View>
                          ))}
                        </View>
                        <View extend={ modal.footer }>
                            <Button noBg cancel disabled={ isUploading } children={'Cancel'}  onClick={ () => onClose() }/>
                            <Button noBg disabled={!file || !file.name || (err) || isUploading}  children={ isUploading ? 'UPLOADING' :'UPLOAD' } onClick={() => onFileUpload()}/>
                        </View>
                    </View>
                </div>
            </Fade>
        </Modal>
    )
}

export default ModalAddFirmware;