import React, { Fragment, useCallback } from 'react'
import View from '../../atoms/view'
import { Link, NavLink } from 'react-router-dom'
import { authContext } from '@modul-connect/shared/adalConfig/adalConfig'
import Image from '@modul-connect/shared/components/atoms/image'
import { Icon } from '@modul-connect/shared/components/atoms/icon'
import logo from '@modul-connect/shared/img/logo.png'
import { P } from '@modul-connect/shared/components/atoms/text'
import { useAuth0 } from '@auth0/auth0-react'
import Dropdown from '@modul-connect/shared/components/atoms/dropdown/dropdown'
import OrgFilterTreeView from '../../molecules/orgFilterTreeView/orgFilterTreeView'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { redirectUri_logout } from '../../../config'

let expanded = []
let organisations = []

const formatOrganisationName = translation =>
  translation?.length && [...translation].reverse().join(' > ')

const formatOrganisationTree = (tree, rootNames, isParentChecked,) => {
  const isChecked = (organisations.includes(tree.uuid) || isParentChecked) ?? false
  return (
    {
      uuid: tree.uuid,
      label: formatOrganisationName(rootNames?.[tree.uuid]) ?? tree.name,
      children: tree.children?.map(child => formatOrganisationTree(child, undefined, isChecked)),
      checked: isChecked,
      expanded: expanded.includes(tree.uuid) ?? false
    }
  )
}

const NavBar = ({ themes, routes, authTool, username, organisationTrees, organisationNames, setSelectedOrganisations, selected_organisations }) => {

  const location = useLocation()

  const routes_navbar = routes.filter(x => { return x.navbar })

  organisations = selected_organisations

  let logoutFunction
  if (authTool === 'auth0') {
    const { logout } = useAuth0()
      logoutFunction = () =>  logout({returnTo : window.location.pathname.length > 3 
      ? window.location.href.replace(window.location.pathname, '')
      : window.location.href})  
    }
    else if (authTool === 'adal') {
    logoutFunction = () => authContext.logOut()
  } else {
    throw 'No authentication tool selected for navbar'
  }

  const DropdownTree = useCallback(() => {
    return (organisationTrees?.total > 1 || (organisationTrees?.total && organisationTrees.subtrees[0].children?.length) ?
      <OrgFilterTreeView
        mobile={themes.device === 'desktop' ? false : true}
        data={organisationTrees.total
          ? organisationTrees.subtrees.map(tree => formatOrganisationTree(tree, organisationNames))
          : []
        }
        onSelect={(selectedNodes) => {
          organisations = selectedNodes
          setSelectedOrganisations(selectedNodes)
        }}
        onToggle={uuid => expanded.includes(uuid) ? expanded = expanded.filter(x => x !== uuid) : expanded.push(uuid)}
      />
      : <></>)
  },
    [organisationTrees, organisationNames, themes.device]
  )

  const navLinkPath = location.pathname.split('/')[1]
  return (
    <View as='nav' extend={styles.nav}>
      <View extend={[styles.navContainer, themes.device == 'tablet' && styles.nav_tablet, themes.device == 'mobile' && styles.nav_mobile]}>
        <View extend={styles.toolBar}>
          {themes.device === 'desktop' && (
            <View className='parent' extend={styles.toolBar}>
              <View className='links' extend={[styles.links, styles.links_desktop]}>
                {routes_navbar.map(route => {
                  let url = route.url
                  if (url.includes(':option')) {
                    if (url.startsWith('/manage')) {
                      url = url.replace(':option', 'manage_workshops')
                    }
                    if (url.startsWith('/reports')) {
                      url = url.replace(':option', 'general_vehicles')
                    }
                    if (url.includes(':id')) {
                      url = url.replace('/:id', '')
                    }
                  }

                  return route.name && (
                    <View extend={[styles.link, url.split('/')[1] === navLinkPath ? styles.link_selected : styles.link_unselected]} key={url}>
                      <NavLink to={url}>
                        {route.name}
                      </NavLink>
                    </View>
                  )
                })}
              </View>

              <Image src={logo} extend={styles.logo} mode='fit' />

              <View extend={styles.right_side}>
                <DropdownTree />

                {username &&
                  <Dropdown
                    styles={[styles.link, { color: 'white' }]}
                    title={username}
                    data={[
                      { label: 'Logout', onClick: logoutFunction }
                    ]}
                  />
                }
              </View>
            </View>
          )}

          {themes.device !== 'desktop' && (
            <Fragment>
              <View extend={styles.links}>
                {routes_navbar.map(route => route.name && (
                  <View key={route.url}>
                    <Link to={route.url}>
                      <View extend={[styles.link_button, route.url.split('/')[1] === navLinkPath ? styles.link_selected : styles.link_unselected]}>
                        <Icon icon={route.icon} />
                        <P light style={styles.link_button_mobile_text} children={route.name.toUpperCase()} />
                      </View>
                    </Link>
                  </View>
                ))}
              </View>

              {themes.device == 'tablet' &&
                <Image src={logo} extend={[styles.logo]} mode='fit' />
              }
              <View extend={styles.right_side}>
                <DropdownTree />

                <View extend={styles.dropdown}>
                  <View key={'username'}>
                    <View extend={styles.link_button} onClick={() => logoutFunction()}>
                      <Icon icon={'FiLogIn'} />
                      <P light style={styles.link_button_mobile_text} children={'LOG OUT'} />
                    </View>
                  </View>
                </View>
              </View>
            </Fragment>
          )}
        </View>
      </View>
    </View>
  )
}
const mapStateToProps = ({
  selected_organisations,
}) => ({
  selected_organisations
})

export default connect(mapStateToProps)(NavBar)


const styles = {
  nav: ({ theme: { colors, layout } }) => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 30
  }),
  nav_tablet: ({ theme: { colors, layout } }) => ({
    height: layout.navBarHeight_tablet
  }),
  nav_mobile: ({ theme: { colors, layout } }) => ({
    height: layout.navBarHeight_mobile
  }),
  navContainer: ({ theme: { layout, colors } }) => ({
    backgroundColor: colors.black,
    width: '100%',
    height: layout.navBarHeight,
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: 'auto',
    zIndex: 31,
    boxShadow: '4px -10px 7px 15px rgb(0,0,0,0.1)'
  }),
  toolBar: ({ theme: { layout } }) => ({
    width: '100%',
    height: '100%',
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '0 4px'
  }),
  right_side: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '45%'
  },
  links: ({ theme: { layout } }) => ({
    display: 'flex',
    flexDirection: 'row'
  }),
  links_desktop: {
    '& div': {
      marginRight: 10
    }
  },
  dropdown: {
    display: 'flex',
    flexDirection: 'row',
    '> div': {
      marginLeft: 10
    },
    right: 0
  },
  link_button: ({ theme: { colors } }) => ({
    width: '65px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 8px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    cursor: 'pointer'
  }),
  link_button_mobile_text: ({ theme: { colors } }) => ({
    fontSize: '10px',
    color: colors.white,
    margin: 0,
    textDecorationColor: colors.black
  }),
  logo: {
    height: '30px',
    width: '120px',
    position: 'absolute',
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  logo_small: {
    height: '24px',
    width: '80px',
  },
  link: ({ theme: { layout } }) => ({
    cursor: 'pointer',
    '& a': {
      display: 'block',
      width: '100%',
      height: '100%',
      padding: `${layout.grid}px ${1 * layout.grid}px`,
      textTransform: 'uppercase',
      fontFamily: 'futura',
      fontWeight: 300,
      fontSize: '18px'
    }
  }),
  link_unselected: ({ theme: { colors } }) => ({
    '& a': {
      color: colors.white,
      textDecoration: 'underline',
      textDecorationColor: colors.black,
      '&:hover': {
        color: colors.white,
        textDecorationColor: colors.white
      }
    },
    '& p': {
      color: colors.disabledGrey,
      textDecoration: 'underline',
      textDecorationColor: colors.black,
      '&:hover': {
        color: colors.white,
        textDecorationColor: colors.white
      }
    }
  }),
  link_selected: ({ theme: { colors } }) => ({
    '& a': {
      color: colors.white,
      textDecoration: 'underline',
      textDecorationColor: colors.white
    },
    '& p': {
      color: colors.white,
      textDecoration: 'underline',
      textDecorationColor: colors.white
    }
  }),
  icon: ({ theme: { colors, layout } }) => ({
    color: `${3 * layout.grid}px`
  })
}
