import Page from '@modul-connect/shared/components/atoms/page'
import { H3, H4 } from '@modul-connect/shared/components/atoms/text'
import View from '@modul-connect/shared/components/atoms/view'
import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import theme from '@modul-connect/shared/theme'
import TreeWidget from '@modul-connect/shared/components/molecules/treeWidget'
import { useAuth0 } from '@auth0/auth0-react';
import { fetchUser, updateUserPrivilege } from '../../../state/actions/customers'
import { ThreeDots } from 'react-loader-spinner'

const Roles = {
  standard: 'standard',
  fleetManager: 'fleet manager',
}

function translateRole(role) {
  switch(role) {
    case Roles.standard:
      return 'a standard user, can only see their own vehicle(s).'
    case Roles.fleetManager:
      return 'a fleet manager, with permission to view and edit the vehicles in their fleet.'
    default:
      return 'a user, with no user role assigned yet.'
  }
}

export const UserRoleManagement = ({
  email, organisationId, user, fetchUser, updateUserPrivilege, loading
}) => {
  const auth = useAuth0()

  const navigate = useNavigate()

  const [role, setRole] = useState(undefined)

  const [isLoading, setIsLoading ]= useState(loading?.fetchUser?.[email] === 'loading')
  const [loadingFailed, setLoadingFailed ]= useState(false)

  useEffect(() => {
    if (user.email !== email && !isLoading)
      fetchUser(email)
      setRole(user.user_metadata?.info.tags.find(x => x.tags === organisationId)?.privilege)
  }, [email, isLoading, fetchUser, setRole])


  useEffect(() => {
    setIsLoading(loading?.fetchUser?.[email] === 'loading' || loading?.fetchUser?.[email] === 'failed')
    setLoadingFailed(loading?.fetchUser?.[email] === 'failed')
  }, [ setIsLoading, setLoadingFailed, loading])

  useEffect(() => {
    const currentRole = user.user_metadata?.info.tags.find(x => x.tags === organisationId)?.privilege
    if (currentRole) {
      setRole(currentRole)
    }
  }, [user])

  const handleShange = useCallback((uuid) => {
      setRole(uuid)
  }, [setRole, email, isLoading, user])
  useEffect(() => {

    const currentRole = user.user_metadata?.info.tags.find(x => x.tags === organisationId)?.privilege
    if (currentRole && role && role !== currentRole && organisationId)
      updateUserPrivilege({ email, organisation: 'organisation/' + organisationId, privilege: role })
  }, [role])

  const isYou = auth.user?.email === email

  return (
    isLoading
    ?
    <View extend={ styles.loaderContainer }>
       <ThreeDots
              color={ theme.colors.primary }
              height={30}
              width={30}
        />
      <H3>Fetching user information ...</H3>
    </View>
    : loadingFailed ? <H4>Could not fetch user information.</H4>
    :
    <Page title={user.firstName + ' ' + user.lastName + (isYou ? ' (your account)' : '')} linkBack={ navigate }>
      {/* <H3>{ (isYou ? 'You are ' : user.firstName + ' is ') + translateRole(role) }</H3> */}
      { isYou ? 
        <H4>Making changes to your own account is prohibited. You assigned as {translateRole(role)}</H4> :
        <View style={{ 
          display: 'flex',
          flexDirection: 'column',
          gap: 10
         }}>
          <H4>This user is {translateRole(role)}</H4>
          <TreeWidget
            hideRoot
            title={ 'Role'}
            tree={{
                uuid: 1, name: 'Role', 
                children: [
                    {uuid: Roles.standard, name: 'Standard (can only see their own vehicles)'},
                    {uuid: Roles.fleetManager, name: 'Fleet Manager (can see and edit their fleet vehicles)'},
                ]
            }}
            onSelect={ (uuid, _) => {handleShange(uuid)}}
            selected={ role }
            disabled={ isYou || isLoading }
          />
        </View>
      }
    </Page>
  )
}

const styles = {
  content: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  padding_style: {
    marginRight: 80,
    width: 350
  },
  padding: {
    marginBottom: '40px'
  },
  loaderContainer: {
    height: '70vh',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  }
}

const mapStateToProps = ({
  user,
  loading
}) => ({
  user,
  loading
})

const mapDispatchToProps = dispatch => ({
  fetchUser: email => dispatch(fetchUser(email)),
  updateUserPrivilege: data => dispatch(updateUserPrivilege(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(UserRoleManagement)
