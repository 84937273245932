import React, { Fragment } from "react";
import { IconContext } from "react-icons";
import { A, H5 } from "@modul-connect/shared/components/atoms/text";
import View from "../../atoms/view";
import { Icon } from '@modul-connect/shared/components/atoms/icon';

const SideBarLink = ({ catIndex, category, selected, history, sidebarContent, onClick }) => {
  const compareSelected = array => {
    if (selected === null || array === null) {
      return false;
    }
    return selected[0] === array[0] && selected[1] === array[1];
  };
  // {minWidth: '44px'}, {minHeight: '40px'},style= {{display: 'flex'}}
  // style={{minWidth: '44px'}, {minHeight: '40px'}} 
  return (
    <Fragment key={catIndex}>
      <H5 extend={ [styles.sidebar_title, {marginBottom: 0}] }>{category.category}</H5>
      {category.titles.map((title, titleIndex) => title.hide ? null :
        <View extend={ [styles.link, compareSelected([catIndex, titleIndex]) && styles.link_selected] } key={ title.title }>
          <View style={{ width:'8px'}}> </View>
          <View extend={ {display: 'flex', alignItems: 'center', width: 22, height: 22,  justifyContent: 'center'}} >
            <Icon dark icon={ title.icon } large selected={ compareSelected([catIndex, titleIndex]) } /> 
          </View>
          <View style={{ width:'16px'}}> </View>
          <View >
          <A
            key={titleIndex}
            extend={compareSelected([catIndex, titleIndex]) ? styles.selected : styles.unselected}
            onClick={() => onClick([catIndex, titleIndex])}
            text={title.title}
          />
          </View>
        </View>
      )}
    </Fragment>
  );
};

const styles = {
  sidebar_title: ({ theme: { colors } }) => ({
    marginLeft: 8,
    marginBottom: 8,
    paddingTop: 12,
    color: colors.black,
    fontWeight: 'bold'
  }),
  link: ({ theme: { colors } }) => ({
    display: 'flex',
    maxHeight: '35px',
    flexDirection: 'row',
    alignItems:'center',
    padding: "4px 8px 4px 0px",
    marginBottom: '10px',
    letterSpacing: '0.04em' ,
    "& a": {
      color: colors.darkGrey,
      // fontWeight: 'bold'
    },
    "& div": {
      color: colors.darkGrey,
    },
    "&:hover": {
      "& a": {
        textDecoration: 'underline'
      },
      // "& img": {
      //   color:colors.white,
      //  filter: 'invert(43%) sepia(12%) saturate(1971%) hue-rotate(76deg) brightness(70%) contrast(91%);'
    //   // filter: 'invert(14%) sepia(5%) saturate(337%) hue-rotate(201deg) brightness(15%) contrast(91%);'      
    // }  
    },
    "> a": {
      marginTop: 7,
      marginBottom: 7,
      paddingLeft: 20,

    },
  }),
  link_selected: ({ theme: { layout, colors } }) => ({
    backgroundColor: colors.black,
    borderRadius: layout.borderRadius,
    borderBottom: `1px solid ${ colors.primaryLight }`,
    "& a": {
      color: colors.white,
    },
    "& img": {
      color: colors.white,
      filter: 'invert(100%) contrast(100%) brightness(200%)'
        // filter: 'invert(43%) sepia(12%) saturate(1971%) hue-rotate(76deg) contrast(91%);'
      },
    "& div": {
      color: colors.primary,
    },
  }),
};

export default SideBarLink;
