import React, { Fragment, useState } from "react";
import { connect } from 'react-redux'
import View from "@modul-connect/shared/components/atoms/view";
import { A, H3, P } from "@modul-connect/shared/components/atoms/text";
import TextField from '@modul-connect/shared/components/molecules/textField';
import {saveConfigAsTemplate} from '../../../../../../state/actions/templates'
import { ThreeDots } from "react-loader-spinner";

const CreateTemplateSection = ({
  savingTemplate,
  saveConfigAsTemplate,
  vehicleId}) => {
  const [templateName, setTemplateName] = useState()

  const createTemplate = () => {
    saveConfigAsTemplate(vehicleId, templateName)
  }

  const userHasEnteredValidTemplateName = templateName && templateName.trim()

  const isSaving = savingTemplate && savingTemplate.vehicleId === vehicleId && savingTemplate.status === 'saving'
  const savingFailed = savingTemplate && savingTemplate.vehicleId === vehicleId && savingTemplate.status === 'failed'

  return (
    <Fragment>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          alignContent: "center",
          gap: 15,
          margin: 5,
          paddingTop: 10
        }}
      >
        <TextField
          extend={{ marginBottom: 0, backgroundColor: "white" }}
          type="templateName"
          placeholder="Template Name"
          onChange={(event) => setTemplateName(event.target.value)}
        />

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            height: 40,
            alignContent: "center",
            alignItems: "center",
            gap: 10,
            flexGrow: 1,
          }}
        >
          <A onClick={userHasEnteredValidTemplateName ? createTemplate : null} >
          {isSaving ? <ThreeDots height={5} width={30} /> : null}
            <H3
              extend={{
                color: userHasEnteredValidTemplateName ? "green" : "lightgrey",
                textAlign: "left",
              }}
            >
              {isSaving ? "Saving ..." : "CREATE TEMPLATE"}
            </H3>
          </A>
        </View>
      </View>
      {
        savingFailed ? <P extend={{ color: "black" }}>Saving failed. Please contact support if the problem persists.</P> : null
      }
      <P extend={{ color: "black" }}>
        Template will be based on the current configuration, as shown below
      </P>
    </Fragment>
  );
};

const mapStateToProps = ({
  savingTemplate
}) => ({
  savingTemplate
})

const mapDispatchToProps = dispatch => ({
  saveConfigAsTemplate: (vehicleId, templateName) => dispatch(saveConfigAsTemplate(vehicleId, templateName)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateTemplateSection);