import React, { useState, useEffect } from "react";
import { connect } from 'react-redux'
import Page from "@modul-connect/shared/components/atoms/page";
import View from "@modul-connect/shared/components/atoms/view";
import { fetchFirmwareBlob, fetchFirmwares, uploadFirmware, deleteFirmware, designateFirmwareAsStable } from "../../../state/actions/firmwares"
import _ from 'lodash';
import { useLoadingTimer } from '@modul-connect/shared/hooks';
import ModalAlert from "../customers/modals/modalAlert";
import ModalAddFirmware from "./modals/modalAddFirmware";
import modal from '@modul-connect/shared/components/atoms/modal'
import TableWidget from "@modul-connect/shared/components/molecules/tableWidget/tableWidget";
import ModalRemoveItem from "@modul-connect/shared/components/molecules/modalRemoveItem/modal-remove-item";
import { BubbleLoadingWidget } from "@modul-connect/shared/components/molecules/bubbleLoadingWidget/bubbleLoadingWIdget";

const Firmwares = ({ fetchFirmwares, fetchFirmwareBlob, firmwares, uploadFirmware, deleteFirmware, onUpgradeFirmware, role, themes }) => {

  const [file, setFile] = useState(null);
  const [fileDetails, setFileDetails] = useState(null);
  const [firmwareExist, setFirmwareExist] = useState(false)
  const [addFirmware, setAddFirmware] = useState(false)
  const [removeFirmware, setRemoveFirmware] = useState(false)
  const [upgradeFirmware, setUpgradeFirmware] = useState(false)
  const [selectedFirmwares, setSelectedFirmwares] = useState([])
  const [selectedId, setSelectedId] = useState(-1)

  const columns = [
    {
      id: 'version',
      label: '',
      width: themes.device !== 'mobile' && 70,
      hide: []
    }
  ]

  const [showLoading, setShowLoading] = useState(false)
  useLoadingTimer(setShowLoading)

  useEffect(() => {
    fetchFirmwares()
  }, [fetchFirmwares])

  const onFetchFirmware = (data, id) => {
    const firmware = data.find(x => x.id === id)
    if (firmware) {
      fetchFirmwareBlob(firmware.url, firmware.fileName)
    }
  }

  const onDeleteFirmware = (data, id) => {

    const firmware = data.find(x => x.id === id)
    if (firmware) {
      const split = firmware.url.split('?version=')
      deleteFirmware(split[0], split[1])
    }
  }

  const doUpgradeFirmware = (data, id) => {
    const firmware = data.find(x => x.id === id)
    if (firmware) {
      const split = firmware.url.split('?version=')
      onUpgradeFirmware(split[0], split[1])
    }
  }

  const addDividers = (firmwares) => {
    let firmwares_by_type = {}

    for (const firmware of firmwares) {
      const type = firmware.type || 'Unknown';

      if (firmwares_by_type[type]) {
        firmwares_by_type[type].push(firmware)
      }
      else {
        firmwares_by_type[type] = [firmware]
      }
    }

    let firmwares_with_dividers = []
    let i = 0;
    for (const type in firmwares_by_type) {
      firmwares_with_dividers.push({
        isDivider: true,
        name: type
      })

      for (const firmware of firmwares_by_type[type]) {
        firmware.id = i++;
        firmwares_with_dividers.push(firmware)
      }
    }
    return firmwares_with_dividers
  }

  let allFirmwares = []
  if (firmwares) {
    if (firmwares.all) {
      allFirmwares = allFirmwares.concat(firmwares.all)
    }
    if (firmwares.betas) {
      allFirmwares = allFirmwares.concat(firmwares.betas)
    }
  }

  let count = 0;
  const groupedBy = allFirmwares.length > 0 && _(allFirmwares).groupBy('product')
  const prepared_firmwares = allFirmwares.length > 0 && groupedBy.map((x, product) => {
    const sorted_firmwares = x.sort((a, b) => (a.version < b.version) ? 1 : -1)
    const data = addDividers(sorted_firmwares)

    return (
      <Page title={product} key={count++}>
        <TableWidget
          data={data}
          totalItems={x?.filter(y => !y.isDivider).length ?? 0}
          onEdit={role && role === 1 ?data.some(y => y.version && y.version.includes('beta')) ?
            (_, id) => {
              setSelectedFirmwares(data)
              setSelectedId(id)
              setUpgradeFirmware(true)
            }
            : undefined
          : null}
          editableIds={data.filter(y => y.version && y.version.includes('beta')).map(y => y.id)}
          onRemove={role && role === 1 ? (_, id) => {
            setSelectedFirmwares(data)
            setSelectedId(id)
            setRemoveFirmware(true)
          } : null}
          columns={columns}
          themes={themes}
          onClickRow={(_, id) => onFetchFirmware(data, id)}
          isSortable={false}
          pagination={false}
          rowsOptions={[data.length]}
        />
      </Page>
    )
  }).value()

  return (
    <View>
      <ModalAlert
        openModal={firmwareExist}
        onClose={() => {
          setFirmwareExist(false)
          setFile(null)
          setFileDetails(null)
        }}
        header={'File already exist'}
        content={fileDetails && 'The Firmware with version: ' + fileDetails.version + ' and uuid: ' + fileDetails.uuid + ' already exist'}
        modalStyle={modal.container_medium}
      />

      <ModalAddFirmware
        openModal={addFirmware}
        onClose={() => {
          setFile(null)
          setAddFirmware(false)
        }}
        header={'ADD FIRMWARE....'}
        setFile={setFile}
        setFileDetails={setFileDetails}
        setFirmwareExist={setFirmwareExist}
        fileDetails={fileDetails}
        firmwares={firmwares}
        file={file}
        uploadFirmware={uploadFirmware}
      />

      <ModalRemoveItem
        header={'DELETE FIRMWARE'}
        content={"You're about to remove " + selectedFirmwares.find(x => x.id === selectedId)?.url.replace('?', ' ')}
        openModal={removeFirmware}
        onClose={() => {
          setRemoveFirmware(false)
        }}
        onSubmit={() => {
          setRemoveFirmware(false)
          onDeleteFirmware(selectedFirmwares, selectedId)
        }}
      />

      <ModalRemoveItem
        header={'UPGRADE FIRMWARE'}
        content={"You're about to upgrade " + selectedFirmwares.find(x => x.id === selectedId)?.url.replace('?', ' ')}
        openModal={upgradeFirmware}
        onClose={() => {
          setUpgradeFirmware(false)
        }}
        onSubmit={() => {
          setUpgradeFirmware(false)
          doUpgradeFirmware(selectedFirmwares, selectedId)
        }}
        action='UPGRADE'
      />

      <Page
        title={'Firmwares'}
        linkTitle={role && role === 1 ? 'Add Firmware' : ''}
        onClickLink={() => {
          setAddFirmware(true)
        }
        }
      >
      </Page>

      {
        prepared_firmwares ?
          (
            <View>
              { prepared_firmwares}
            </View>
          ) :
          (
            <Page>
              {
                showLoading && <BubbleLoadingWidget text={'Loading ...'}/>
              }
            </Page>
          )
      }
    </View>
  )
}

const mapStateToProps = ({
  firmwares,
  role,
  themes
}) => ({
  firmwares,
  role,
  themes
})

const mapDispatchToProps = dispatch => ({
  fetchFirmwares: () => dispatch(fetchFirmwares()),
  fetchFirmwareBlob: (url, name) => dispatch(fetchFirmwareBlob(url, name)),
  uploadFirmware: (data) => dispatch(uploadFirmware(data)),
  deleteFirmware: (type, version) => dispatch(deleteFirmware(type, version)),
  onUpgradeFirmware: (type, version) => dispatch(designateFirmwareAsStable(type, version))
})

export default connect(mapStateToProps, mapDispatchToProps)(Firmwares)