export const columns = [
  {
    id: "id",
    label: "Box. ID",
    maxWidth: 170,
    minWidth: 100,
    hide: []
  },
  {
    id: "telematics_id",
    label: "Telematics Id",
    align: "left",
    hide: []
  },
  {
    id: "vin",
    label: "VIN",
    align: "left",
    hide: []
  },
  {
    id: "time_stamp",
    label: "Disconnected at",
    minWidth: 130,
    align: "left",
    hide: []
  }
];
