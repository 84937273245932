import React from "react"
import View from '@modul-connect/shared/components/atoms/view';
import { MdBatteryCharging30, MdCloudDownload, MdColorLens, MdFitnessCenter, MdLocalShipping, MdPets, MdSystemUpdateAlt, MdToll, MdTrackChanges, MdViewAgenda } from "react-icons/md";
import {
  MdSettings,
  MdDashboard,
  MdBusiness,
  MdBusinessCenter,
  MdSupervisorAccount,
  MdDescription,
  MdDriveEta,
  MdBuild,
  MdWarning
} from "react-icons/md";
import { FiLogIn } from "react-icons/fi";
import sfCar from '@modul-connect/shared/img/sf_car.svg';
import VWBlack from '@modul-connect/shared/img/vehicle-weighing-black.svg';
import BMBlack from '@modul-connect/shared/img/battery-monitoring-black.svg';
import DJBlack from '@modul-connect/shared/img/driving-journal-black.svg';
import TRBlack from '@modul-connect/shared/img/toll-reports-black.svg';
import Temp from '@modul-connect/shared/img/Temperature.svg'
import Hum from '@modul-connect/shared/img/Humidity.svg'
import Download from '@modul-connect/shared/img/downloadIcon.svg';
import DownloadWhite from '@modul-connect/shared/img/downloadIconWhite.svg';
import AssetTracker from '@modul-connect/shared/img/tracker_map.svg';
import Dropdown from '@modul-connect/shared/img/dropdown.svg';
import Rectangle from '@modul-connect/shared/img/rectangle.svg';
import RectangleSmall from '@modul-connect/shared/img/rectangleSmall.svg';
import Pencil from '@modul-connect/shared/img/pencil.svg';
import Calendar from '@modul-connect/shared/img/calender.svg';

const styles = {
  default: ({ theme: { colors, layout } }) => ({
    fontSize: '22px',
    color: colors.white,
    fontWeight: 'bold',
    // paddingTop: '2px',
    display: 'flex',
    alignItems: 'center'
  }),
  link: ({ theme: { colors, layout } }) => ({
    transition: "color 200ms ease-in-out",
    "&:hover": {
      color: colors.primary
    }
  }),
  small_size: {
    fontSize: '16px'
  },
  dark: ({ theme: { colors, layout } }) => ({
    color: colors.darkGrey,
    "& img": {
      filter: 'invert(14%) sepia(5%) saturate(337%) hue-rotate(201deg) brightness(15%) contrast(91%);'
    },
    "& svg": {
      filter: 'invert(14%) sepia(5%) saturate(337%) hue-rotate(201deg) brightness(15%) contrast(91%);'
    }
  }),
  selected: ({ theme: { colors, layout } }) => ({
    // color: colors.primary,
    "& img": {
      color: colors.white,
      filter: 'invert(100%) contrast(100%) brightness(200%)',
      // filter: 'invert(43%) sepia(12%) saturate(1971%) hue-rotate(76deg) contrast(91%);'
    },
    "& img.sfCar": {
      filter: 'grayscale(100%)'
    },
    "& svg": {
      color: colors.white,
      filter: 'invert(100%) contrast(100%) brightness(200%)',
    }
    // 
  }),
}
export const Icon = ({
  link,
  icon,
  small,
  selected,
  dark
}) => (
  <View extend={[styles.default, link && styles.link, small && styles.small_size, dark && styles.dark, selected && styles.selected]}>
    {
      icon == 'MdTrackChanges' && <MdTrackChanges />
    }
    {
      icon == 'MdSettings' && <MdSettings />
    }
    {
      icon == 'MdDirectionsCar' && <img src={sfCar} className='sfCar' width='22px' height='22px' color='black' style={{ paddingBottom: 2 }} alt="Vehicle" />
    }
    {
      icon == 'MdViewAgenda' && <MdViewAgenda />
    }
    {
      icon == 'MdDashboard' && <MdDashboard />
    }
    {
      icon == 'MdBusiness' && <MdBusiness color='black' />
    }
    {
      icon == 'MdBusinessCenter' && <MdBusinessCenter />
    }
    {
      icon == 'MdSupervisorAccount' && <MdSupervisorAccount color='black' />
    }
    {
      icon == 'FiLogIn' && <FiLogIn />
    }
    {
      icon == 'MdDescription' && <MdDescription />
    }
    {
      icon == 'MdDriveEta' && <MdDriveEta />
    }
    {
      icon == 'MdTemplates' && <MdSettings color='black' />
      /*
        <MdPets />
        <MdSystemUpdateAlt />
      */
    }
    {
      icon == 'MdBatteryCharging30' && <MdBatteryCharging30 />
    }
    {
      icon == 'MdFitnessCenter' && <MdFitnessCenter />
    }
    {
      icon == 'MdLocalShipping' && <MdLocalShipping />
    }
    {
      icon == 'MdWarning' && <MdWarning />
    }
    {
      icon == 'MdBuild' && <MdBuild color='black' />
    }
    {
      icon == 'MdCloud' && <MdCloudDownload color='black' />
    }
    {
      icon == 'MdToll' && <MdToll />
    }
    {
      icon == 'VWBlack' && <img src={VWBlack} />
    }
    {
      icon == 'BMBlack' && <img src={BMBlack} />
    }
    {
      icon == 'DJBlack' && <img src={DJBlack} />
    }
    {
      icon == 'TRBlack' && <img src={TRBlack} />
    }
    {  
      icon == 'Temp' && <SVGIcon svgIcon={Temp} />
    }
    {  
      icon == 'Hum' && <SVGIcon svgIcon={Hum} />
    }
    {
      icon == 'Download' && <img src={Download} />
    }
    {
      icon == 'Rectangle' && <img src={Rectangle} />
    }
    {
      icon == 'DownloadWhite' && <img src={DownloadWhite} />
    }
    {
      icon == 'Dropdown' && <img src={Dropdown} />
    }
    {
      icon == 'Pencil' && <img src={Pencil} />
    }
    {
      icon == 'RectangleSmall' && <img src={RectangleSmall} />
    }
    {
      icon == 'Calendar' && <img src={Calendar} />
    }
    {
      icon == 'AssetTracker' && <SVGIcon svgIcon={AssetTracker} />
    }
  </View>
)

const SVGIcon = ({ svgIcon }) => {
  return (
    <View style={{ 
      width: 44,
      height: 40,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <img width={23} height={23} src={svgIcon} />
    </View>
  )
}