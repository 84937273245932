const header_types = {
    '00000000': 'GBL_INVALID_TAG',
    '03A617EB': 'GBL_HEADER_TAG',
    'F40A0AF4': 'GBL_APP_INFO_TAG',
    'F50909F5': 'GBL_BTL_TAG',
    'FE0101FE': 'GBL_PROG_DATA_TAG1',
    'FD0303FD': 'GBL_PROG_DATA_TAG2',
    'F60808F6': 'GBL_METADATA_TAG',
    'F70A0AF7': 'GBL_SIGNATURE_TAG',
    'FC0404FC': 'GBL_END_TAG'
  }

const firmware_types = {
  'dfffaca03c6648148a20bb4ac05702d4': 'Mainbox-Bluetooth',
  'd33578ac66264913885c60fcd0a71dfe': 'Mainbox-Application',
  'dacf9b2de3da2393644c1c748b5cfcb0': 'Mainbox-Telematics',
  'ab64230ccd05cca5444ec086f7acab33': 'Displayswitch-Bluetooth',
  '56eb54d39fd86fa18a4c5b58182b4ec8': 'Displayswitch-Application',
  '7e4c5cca7c7f4d0db802aa49d977093f': 'Switchboard-Bluetooth',
  'e4aba37ea3929e8ffb43d0dca569a6fc': 'Backlightswitch-Bluetooth',
  '921ba1bdea17d886c1471ffdaccaaff0': 'Pir-Bluetooth',
  '93af4ff0614fac8dff4d4f8e22935030': 'ClimateSensor-Bluetooth',
  '6d6e18c9a9a72597404a08bb85a17685': 'MagneticSensor-Bluetooth',
  'b9a940cc8e4877b130480008cb966821': 'ePower-Bluetooth',
  'b9cc783bae6d58986643a3d50ed46646': 'ePower-Application',
  '59d7576d6d6db5afb84a87608a31e19e': 'evHeater-Bluetooth',
  '7589a59b775fcba33c48d2c1abe273e3': 'tracker-Bluetooth'
}

export const buf2hex = (buffer) => {
    return Array.prototype.map.call(new Uint8Array(buffer), x => ('00' + x.toString(16)).slice(-2)).join('');
  }

const toLittleEndian = (string, char) => {
  let hex_array
  let littleEndian

  if(char) {
    hex_array = string.split(char)
    littleEndian = hex_array.reverse()
    littleEndian = littleEndian.join(':')
  } else {
    hex_array = string.match(/.{1,2}/g)
    littleEndian = hex_array.reverse()
    littleEndian = littleEndian.join('')
  }

  return littleEndian
}

export const parseImage = (image) => {
  let header
  let payload_length
  let payload
  let type
  let version_raw
  let version
  let capabilities
  let uuid
  let header_string
  let payload_start_pos

  function parseVersion(raw) {
    let major = raw.substring(2,4)
    let minor = raw.substring(4,6)
    let patch = raw.substring(6,8)

    return parseInt(major, 16) + '.' + parseInt(minor, 16) + '.' + parseInt(patch, 16)
  }

  while(image.length > 0) {
    header = toLittleEndian(image.substring(0,8)).toUpperCase()
    header_string = header_types[header]
    payload_length = parseInt(toLittleEndian(image.substring(8,16)),16)
    payload_start_pos = 16 + payload_length * 2

    if(header_string === 'GBL_APP_INFO_TAG') {
      payload = image.substring(16, 16 + payload_length * 2)
      type = parseInt(toLittleEndian(payload.substring(0,8)),16)
      version_raw = toLittleEndian(payload.substring(8,16))
      version = parseVersion(version_raw)
      capabilities = parseInt(toLittleEndian(payload.substring(16,24)),16)
      uuid = payload.substring(24, 24+32)

      break
    } else {
      image = image.substring(payload_start_pos, image.lengh)
    }
  }

  return {
    header_string,
    version,
    capabilities,
    uuid
  }
}

export const firmwareInfo = (uuid) => {
  const firmware = firmware_types[uuid]
  if (!firmware) {
    return null
  }


  return {
    app : firmware.split('-')[0],
    type : firmware.split('-')[1]
  }
}