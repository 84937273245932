import React from "react";
import theme from "@modul-connect/shared/theme";
import View from "../view";

const Tag = ({
  value,
  tagColor,
  textColor
}) => {

  return (
    <View
      extend={{
        display: "inline-block",
        backgroundColor: (
          tagColor && theme.colors.tag[tagColor.toLowerCase()] ? 
          theme.colors.tag[tagColor.toLowerCase()] : theme.colors.disabledGrey
        ),
        color: textColor ?? theme.colors.white,
        textAlign: 'center',
        fontSize: 10,
        height: 18,
        textTransform: 'Uppercase',
        padding: '2px 4px',
        borderRadius: 4
      }}
    >
      {value}
    </View>
  );
};

export default Tag;
