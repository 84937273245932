import React, {Component} from 'react';
import { useFela } from "react-fela";
import theme from "@modul-connect/shared/theme";
import Page from "@modul-connect/shared/components/atoms/page";
import Input from '@modul-connect/shared/components/atoms/input';
import View from '@modul-connect/shared/components/atoms/view';
import {Span} from '@modul-connect/shared/components/atoms/text';

const TextField = ({
  extend,
  onChange,
  disabled,
  placeholder,
  onBlur,
  focus,
  type,
  name,
  error,
  errorText,
  value
}) => {

  return (
    <View extend={error && styles.error}>
      <Span extend={ [styles.span ] }>{ name } { errorText && errorText }</Span>
      <Input
        disabled={ disabled }
        extend={ [styles.input, extend && extend, ] }
        type={ type }
        innerRef={ focus ? input => input && input.focus() : null }
        placeholder={ placeholder }
        onBlur={event => { if(onBlur) { onBlur(event)}}}
        onChange={event => { if(onChange) { onChange(event)}}}
        value={ value }
      />
    </View>
  )
}

const styles = {
  input: {
    marginBottom: 15,
    border: `1px solid ${ theme.colors.lightGrey }`
  },
  span: {
    fontSize: 12,
    marginLeft: 3,
    color: theme.colors.darkGrey
  },
  error: {
    "& span": {
      color: theme.colors.error,
    },
    "& input": {
      border: `1px solid ${ theme.colors.error }`
    }
  }
}

export default TextField;