export const vehicle_records_columns = (themes) => [
  {
      id: 'timestamp',
      label: 'Time stamp',
      width: themes.device != 'mobile' && 120,
      hide: []
  },
  {
      id: 'record',
      label: 'Message',
      width: themes.device != 'mobile' && 120,
      hide: []
  }
]