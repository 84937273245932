

import React from 'react';
import View from '@modul-connect/shared/components/atoms/view'
import theme from '@modul-connect/shared/theme'
import { H3 } from '../../atoms/text';
import { ThreeDots } from 'react-loader-spinner';

export const BubbleLoadingWidget = ({ text }) => {
  return (
    <View extend={style}>
       <ThreeDots
              color={ theme.colors.primary }
              height={50}
              width={50}
        />
      <H3>{text ?? ''}</H3>
    </View>
  )
}

const style = {
  //height: '70vh',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
}