import Dashboard from './pages/dashboard'
import Manage from './pages/manage/manage'
import Reports from './pages/reports/reports'

export default [
  {
    url: '/',
    name: 'Dashboard',
    component: Dashboard,
    icon: 'MdDashboard',
    navbar: true
  },
  {
    url: '/manage/:option',
    name: 'Manage',
    component: Manage,
    icon: 'MdViewAgenda',
    navbar: true
  },
  {
    url: `/reports/:option`,
    name: 'Reports',
    component: Reports,
    icon: 'MdViewAgenda',
    navbar: true
  },
  {
    url: `/manage/:option/:id?/:subId?/:detail?`,
    name: 'Manage',
    component: Manage
  },
  {
    url: `/reports/:option/:id?/:subId?/:detail?`,
    name: 'Reports',
    component: Reports
  }
]