import React, { useState, useEffect } from "react";
import { connect } from 'react-redux'
import Page from "@modul-connect/shared/components/atoms/page";
import View from "@modul-connect/shared/components/atoms/view";
import { fetchUsers } from "../../../state/actions/users"
import moment from "moment"
import SearchWidget from '@modul-connect/shared/components/molecules/searchWidget';
import TableWidget from '@modul-connect/shared/components/molecules/tableWidget';

const columns = [
  {
    id: 'name',
    label: 'Name',
    minWidth: 100,
    hide: []
  },
  // {
  //   id: 'firstName',
  //   label: 'First Name',
  //   maxWidth: 170,
  //   hide: []
  // },
  // {
  //   id: 'lastName',
  //   label: 'Surname',
  //   minWidth: 100,
  //   hide: ['tablet', 'mobile']
  // },
 /*  {
    id: 'customer',
    label: 'Customer',
    minWidth: 100,
    hide: ['tablet', 'mobile']
  }, */
  {
    id: 'email',
    label: 'Email',
    minWidth: 100,
    hide: ['tablet', 'mobile']
  },
  {
    id: 'isPortalUser',
    label: 'Portal',
    minWidth: 100,
    hide: ['mobile'],
    isCheck: true
  },
  {
    id: 'isBackofficeUser',
    label: 'Backoffice',
    minWidth: 100,
    hide: ['mobile'],
    isCheck: true
  },
  {
    id: 'isTechnician',
    label: 'Technician',
    minWidth: 100,
    hide: ['mobile'],
    isCheck: true
  },
  {
    id: "created_date",
    label: 'Registered',
    maxWidth: 120,
    align: 'left',
    format: value => value.toLocaleString(),
    hide: ['mobile']
  },
];


const Users = ({users, fetchUsers, history, themes, loading}) => {  
  const [name, setName] = useState('');
  let filterTimer = null;

  const users_prepared = users.data ? users.data.map(item => {
    return {
      ...item,
      id: item.phoneNumber,
      name: item.firstName && item.lastName ? item.firstName + " " + item.lastName : '',
      // firstName: item.firstName ? item.firstName: null,
      // lastName: item.lastName ? item.lastName: null,
      email: item.email ? item.email : null,
      //phoneNumber: item.phoneNumber ? "+" + item.phoneNumber.substring(2): null,
      customer: item.customer ? item.customer : null,
      created_date: moment(item.created).calendar(),
      isTechnician: item.isTechnician,
      isBackofficeUser: item.isBackofficeUser,
      isPortalUser: item.isPortalUser
    }
  }) : []

  const handleNameChange = (event) => {
    setFilterAfterDelay(event.target.value)
  }

  const setFilterAfterDelay = (newFilter) => {
    if (filterTimer) {
      clearTimeout(filterTimer)
    }
    
    filterTimer = setTimeout(() => {
      setName(newFilter)
    }, 1000)
  }

  const handleClick = (event, id) => {
   // history.push('/vehicles/' + id);
  //  console.log(id)
  }

  let columns_filtered
  switch (themes.device) {
    case 'tablet':
      columns_filtered = columns.filter(x => { return x.hide.indexOf('tablet') === -1 })
      break
    case 'mobile':
      columns_filtered = columns.filter(x => { return x.hide.indexOf('mobile') === -1 })
      break
    default:
      columns_filtered = columns
  }

  return (
    <View>
      <SearchWidget 
        title={ 'Users' } 
        onChange={ handleNameChange } 
        placeholder={ 'Search for user...' } 
        initialValue={ name }/>

      <Page>
        <TableWidget
          loadingStatus={loading.fetchUsers}
          data={ users_prepared }
          totalItems={ users?.total ?? 0 }
          columns={ columns }
          onFetchData={ fetchUsers }
          onClickRow={ handleClick }
          searchStr={ name }
          themes={ themes }
        />
      </Page>
    </View>
  );

};

const mapStateToProps = ({
  users,
  themes,
  loading
}) => ({
  users,
  themes,
  loading
})

const mapDispatchToProps = dispatch => ({
  fetchUsers: (options) => dispatch(fetchUsers(options))
})

export default connect(mapStateToProps, mapDispatchToProps)(Users);
