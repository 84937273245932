export const templates = (state = {data: [], total: 0}, action) => {
  switch (action.type) {
    case 'TEMPLATES_FETCHED':
      return {
        data: action.data,
        total: action.total
      }
    default:
      return state;
  }
}

export const template = (state = { 
  users: [], users_total: 0,
  vehicles: [], vehicles_total: 0 ,
  added_users: [],
}, action) => {
  switch (action.type) {
    case 'FETCH_TEMPLATE':
      return {
        ...state,
        added_users: []
      }
    case 'TEMPLATE_FETCHED':
      return {
        ...action.data,
        users: state.users,
        users_total: state.users_total,
        vehicles: state.vehicles,
        vehicles_total: state.vehicles_total,
      }
    case 'TEMPLATE_VEHICLES_FETCHED':
      return {
        ...state,
        vehicles: action.data,
        vehicles_total: action.total
      }
    default:
      return state;
  }
}
