import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import SearchWidget from '@modul-connect/shared/components/molecules/searchWidget'
import Page from '@modul-connect/shared/components/atoms/page'
import TableWidget from '@modul-connect/shared/components/molecules/tableWidget'
import { useNavigate, useParams, Route } from 'react-router-dom'
import { fetchWorkshops, flushWorkshop } from '../../../state/actions/workshops'
import Workshop from './workshop'
import Technician from './workshop/technician'
import { BubbleLoadingWidget } from '@modul-connect/shared/components/molecules/bubbleLoadingWidget/bubbleLoadingWIdget'

const Workshops = ({ themes, workshops, fetchWorkshops, flushWorkshop, loading, id, subId, detail }) => {
  let filterTimer

  const navigate = useNavigate()
  const [selectedWorkshopId, setSelectedWorkshopId] = useState(id)
  const [selectedSubWorkshopId, setSelectedSubWorkshopId] = useState(subId)
  const [selectedUser, setSelectedUser] = useState(detail)
  const [search, setSearch] = useState('')
  

  useEffect(() => {
    if (id) setSelectedWorkshopId(id)
    else flushWorkshop()
    return () => {
      setSelectedWorkshopId(undefined)
    }
  }, [id])

  useEffect(() => {
    if (subId) setSelectedSubWorkshopId(subId)
    else flushWorkshop()
    return () => setSelectedSubWorkshopId(undefined)
  }, [subId])

  useEffect(() => {
    if (detail) setSelectedUser(detail)
    return () => setSelectedUser(undefined)
  }, [detail])

  const columns = [
    {
      id: 'name',
      label: 'Region',
      width: themes.device !== 'mobile' && 70,
      hide: []
    },
    {
      id: 'numberOfUsers',
      label: '#Technicians',
      width: themes.device !== 'mobile' && 70,
      hide: []
    },
    {
      id: 'numberOfVehicles',
      label: '#Vehicles',
      width: themes.device !== 'mobile' && 70,
      hide: []
    }
  ]

  const handleNameChange = event => {
    const filter = event.target.value
    if (filterTimer) clearTimeout(filterTimer)
    filterTimer = setTimeout(() => setSearch(filter), 1000)
  }

  const handleClick = (_, id) => 
    navigate(`/manage/manage_workshops/${selectedWorkshopId ? selectedWorkshopId + '/' : ''}${id.replace('organisation/', '')}`)
  

  return ( selectedUser
    ?
    <Technician workhshopId={ selectedSubWorkshopId } email={ selectedUser } />
    :
    ( selectedWorkshopId 
    ?
      (selectedSubWorkshopId ?
        <Workshop workshopId={ selectedWorkshopId } subWorkshopId={ selectedSubWorkshopId } />
        :
        <Workshop workshopId={ selectedWorkshopId } />
      )
    :
    <Fragment>
      <SearchWidget
        title='Regions'
        placeholder='Search for region...'
        initialValue={ search }
        onChange={ handleNameChange }
      />    
      {
        <Page>
          {
            !workshops.data.length && loading?.fetchWorkshops === 'loading' && <BubbleLoadingWidget text={ "Loading workshops ... " }/>
          }
          <TableWidget
            hide={loading.fetchWorkshops === 'loading' && !workshops.data?.length}
            loadingStatus={loading.fetchWorkshops}
            data={ workshops.data }
            onFetchData={ fetchWorkshops }
            totalItems={ workshops.total }
            columns={ columns }
            searchStr={ search }
            themes={ themes }
            onClickRow={ handleClick }
          />
        </Page>
      }
    </Fragment>)
  )
}

const mapStateToProps = ({
  themes,
  workshops,
  loading
}) => ({
  themes,
  workshops,
  loading
})

const mapDispatchToProps = dispatch => ({
  fetchWorkshops: options => dispatch(fetchWorkshops(options)),
  flushWorkshop: () => dispatch(flushWorkshop())
})

export default connect(mapStateToProps, mapDispatchToProps)(Workshops)