import { createTheme } from '@mui/material/styles';

export default {
  colors: {
    primary: "#3A8643", //"#378541",
    primaryLight: "rgb(222 238 225)",
    border: "#ddd",
    text: "#222",
    disabledText: '#9E9E9E',
    yellowText: 'rgb(237, 192, 28)',
    bg: "#f5f5f5",
    // sideBarBg: "#f5f5f5",
    black: "#000",
    white: "#fff",
    offWhite: "#FAFAFA",
    lightGrey: "#F3F3F3",//'rgb(246,248,246)',//"#efefef",
    midGrey: "rgb(138, 138,139, 0.15)",
    disabledGrey: "rgb(208, 208,208, 1)",
    grey: "rgb(138, 138,139)",
    darkGrey: "#2C2C2C",
    dividerGrey: '#22434E',
    midDarkGrey: '#4D4D4D',
    darkerGrey: '#3E3E3E',
    inactive: "rgb(49,47,48)",
    error: '#FF5151',
    yellow: "#ffff00",
    orange: "#F7B500",
    amber: "rgb(255, 234, 85)",
    green: "rgb(56,132,65)",
    lightGreen: "#CCE1CB",
    historic_routes: "#888",
    tag: {
      'business': 'rgb(58, 68, 134)',
      'warning': "#ffad2e",
      'issue': "#FF5151",
      'primary': '#3A8643',
      'libtblue': '#add8e6'
    },
    transparent: "rgb(0, 0, 0, 0.0)",
  },
  shadowStyle: { boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.08)' },
  layout: {
    borderWidth: 2,
    fontFamily: 'Futura',
    grid: 8,
    mainWidth: 1260,
    sideBarWidth: 150,
    navBarHeight: 55,
    navBarHeight_tablet: 55,
    navBarHeight_mobile: 55,
    borderRadius: 4
  },
  mui: createTheme({
    palette: {
      primary: {
        light: '#349e61',
        main: '#02863a',
        dark: '#015d28',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ff7961',
        main: '#f44336',
        dark: '#ba000d',
        contrastText: '#000',
      },
    },
  })
};
