import theme from "../../../theme"

const modal = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.colors.white,
        left: '37%',
        right: '37%',
        top: '16%',
        '@media only screen and (max-width: 1025px)': {
            left: '7%',
            right: '7%',
        },
        position: 'absolute',
        borderRadius: theme.layout.borderRadius,
        boxShadow: '0 0 15px 2px rgba(0,0,0,.1)',
        "> div": {
            padding: `${ theme.layout.grid }px ${ 3 * theme.layout.grid }px`,
        }
    },
    container_medium: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.colors.white,
        left: '20%',
        right: '20%',
        top: '10%',
        bottom: '20%',
        position: 'absolute',
        borderRadius: theme.layout.borderRadius,
        boxShadow: '0 0 15px 2px rgba(0,0,0,.1)',
        "> div": {
            padding: `${ theme.layout.grid }px ${ 3 * theme.layout.grid }px`,
        }
    },
    container_wide: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.colors.white,
        left: '10%',
        right: '10%',
        top: '10%',
        bottom: '10%',
        position: 'absolute',
        borderRadius: theme.layout.borderRadius,
        boxShadow: '0 0 15px 2px rgba(0,0,0,.1)',
        "> div": {
            padding: `${ theme.layout.grid }px ${ 3 * theme.layout.grid }px`,
        }
    },
    header: {
        height: 60,
        marginTop: 12,
        borderBottom: `1px solid ${ theme.colors.lightGrey }`,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        '> h1': {
            color: theme.colors.grey
        }
    },
    title: {
    },
    content: {
        flex: 1,
        marginTop: `${ 2 * theme.layout.grid }px`,
        marginBottom: `${ 3 * theme.layout.grid }px`,
        overflowY: 'auto'
    },
    footer: {
        height: 60,
        borderTop: `1px solid ${ theme.colors.lightGrey }`,
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'row',
    }
}


export default modal;