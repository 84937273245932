const connectionColumns = [{
  id: "status",
  label: 'Status',
  minWidth: 100,
  align: 'left',
  hide: []
},
{
  id: 'from',
  label: 'From',
  maxWidth: 170,
  minWidth: 100,
  hide: []
},
{
  id: 'to',
  label: 'To',
  minWidth: 130,
  hide: []
},
{
  id: 'duration',
  label: 'Duration',
  minWidth: 130,
  hide: []
},
]

export default connectionColumns;