import { put, fork, take, select } from 'redux-saga/effects';
import { eventChannel } from "redux-saga"

let viewWidth_types = [
    {
        name: 'desktop',
        minWidth: 1025
    },
    {
        name: 'tablet',
        minWidth: 801
    },
    {
        name: 'mobile',
        minWidth: 0
    }
]

export function* themesHandler() {
    yield fork(responsiveViewHandler, window.innerWidth)
    yield fork(responsiveViewListener)
}

export function* responsiveViewHandler(viewWidth) {
    const themes = yield select(state => state.themes)
    for(var i = 0; i < viewWidth_types.length; i++) {
        if(viewWidth_types[i].minWidth <= viewWidth) {
            if(!(themes && themes.device && themes.device === viewWidth_types[i].name)) {
                yield put({ type: 'VIEW_DIMENSION_UPDATED', device: viewWidth_types[i].name })
            }
            return
        }
    }
}

export function* responsiveViewListener() {
    const channel = eventChannel(listener => {
        const handleWindowResize = (windowSize) => {
            listener(windowSize);
        }

        window.addEventListener("resize", handleWindowResize)
        return () => window.removeEventListener("resize", handleWindowResize);
    });

    let updatedWindow
    while (true) {
        const windowInfo = yield take(channel);

        updatedWindow = windowInfo && windowInfo.srcElement ?
        {
            width: windowInfo.srcElement.innerWidth,
            height: windowInfo.srcElement.innerHeight,
        } : null

        if(updatedWindow) {
            yield fork(responsiveViewHandler, updatedWindow.width)
        }

    }
}

